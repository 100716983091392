import * as React from "react";
import { forwardRef } from "react";
const SvgBaconeggs = (props, ref) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", "data-name": "Layer 1", xmlns: "http://www.w3.org/2000/svg", width: 298.07, height: 298.07, viewBox: "0 0 298.07 298.07", ref, ...props }, /* @__PURE__ */ React.createElement("rect", { width: 298.07, height: 298.07, style: {
  fill: "none",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M118.31,58.11c79.4-31.39,64.15,18.38,121.06,41.68,1.18.39,2.56.8,3.81,1.19,3.78,1.16,8.01,2.74,11.58,4.75,11.12,5.77,18.29,17.51,13.45,30.27-6.55,16.6-24.32,26.37-42.06,31.29-58.85,15.1-173.36-25.04-173.36-61.67s43.85-38.94,65.51-47.51Z", style: {
  fill: "#fff",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "2.19px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M78.33,81.25c2.98-28.88,88.58-63.94,106.68,9.98-5.01,29.37-109.65,18.9-106.68-9.98Z", style: {
  fill: "#f9cd5f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "2.19px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M21.18,205.67s23.93-14.52,69.67,26.31c0,0,7.17-35.39,45.07-39.93,37.9-4.54,63.16-40.83,71.81-62.61,14.63-33.12,34.57-35.39,54.52-30.85,0,0-36.9-22.69-67.15-20.87,0,0-13.3,9.07-35.24,42.65-21.94,33.57-53.8,27.71-60.51,26.31-18.04-3.76-44.6-6.81-78.18,58.98Z", style: {
  fill: "#cd2735",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "2.19px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M29.76,203.34s9.32.2,16.34,1.61c0,0,13.9-44.93,51.87-41.74,38.42,3.23,61.44-21.77,73.07-38.84,17.73-31.15,34.94-42.85,41.89-45.34,0,0,6.47.47-7.79-1.09,0,0-13.53,2.67-35.3,38.94-20.89,34.8-47.3,38.73-61.63,37.92-18.23-1.03-44.87-17.25-78.45,48.54Z", style: {
  fill: "#fff",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".87px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M49.39,205.99s-2.46.2,4.56,1.61c0,0,11.08-39.06,49.03-35.39,34.89,3.38,61.71-25.96,79.34-53.97,18.95-28.43,30.58-32.97,36.62-37.93,0,0-3.05-.08-4.55-.05,0,0-17.29,10.84-27.71,25.21-13.04,17.98-25.93,47.49-62.2,59.03-24.14,7.68-50.97-15.58-75.08,41.49Z", style: {
  fill: "#fff",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".87px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", { id: "Plus" }, /* @__PURE__ */ React.createElement("circle", { cx: 76.41, cy: 200.31, r: 55.23, style: {
  fill: "#fff",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "2.19px"
} })), /* @__PURE__ */ React.createElement("path", { d: "M51.31,166.51h31.66c5.68,0,9.94.7,12.79,2.09,2.85,1.39,5.21,3.38,7.07,5.97,1.86,2.59,2.79,5.74,2.79,9.45,0,7.53-4.08,12.55-12.25,15.07,4.71,1.06,8.25,3.17,10.6,6.33,2.36,3.15,3.53,6.59,3.53,10.31,0,5.08-1.93,9.47-5.77,13.18-3.85,3.7-10.11,5.55-18.77,5.55h-31.66v-67.95ZM67.19,178.51v14.88h12.05c4.41,0,7.19-.87,8.34-2.61,1.15-1.74,1.72-3.41,1.72-5,0-1.73-.47-3.21-1.42-4.46s-1.98-2.02-3.11-2.34c-1.13-.31-3.35-.47-6.67-.47h-10.9ZM67.19,205.49v16.38h12.22c4.17,0,7.12-.73,8.84-2.19,1.72-1.46,2.58-3.5,2.58-6.12,0-2.19-.83-4.08-2.48-5.68-1.66-1.59-4.41-2.39-8.25-2.39h-12.92Z", style: {
  fill: "#000",
  strokeWidth: 0
} }))));
const ForwardRef = forwardRef(SvgBaconeggs);
export default ForwardRef;
