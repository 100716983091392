import * as React from "react";
import { forwardRef } from "react";
const SvgSa = (props, ref) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", "data-name": "Layer 1", xmlns: "http://www.w3.org/2000/svg", width: 298.07, height: 298.07, viewBox: "0 0 298.07 298.07", ref, ...props }, /* @__PURE__ */ React.createElement("rect", { width: 298.07, height: 298.07, style: {
  fill: "none",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M83.6,234.03s20.72-32.45,37.51-42.21c16.79-9.77,65.44-21.31,124.33-.09l.4-1.66s-63.4-23.25-118.77-6.35c-3.18.97,42.6-42.34,42.6-42.34,0,0,65.43-2.75,93.77-19.05l-2.32-.55s-44.3,19.16-88.12,15.17c0,0,38.9-44.71,35.12-105.99l-2.02,1.26s14.36,66.9-84.13,149.83c0,0-17.54-81.85-36.32-117.54l-2.21,1.37s28.12,65.27,32.69,116.5c0,0-.35,4.39-6.87,13.71l-31.69,31.77,6.03,6.16Z", style: {
  fill: "#7c6e5b",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.02px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M168.45,176.03c-.57.55-7.49-18.16-2.3-23.28,7.22-7.12,8.99.44,9.48,3,.49,2.55.14,13.32-7.18,20.28Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.48px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M168.45,176.03s3.43-18.91,2.93-26.25", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.48px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M186.36,176.6c-.62.5-5.95-18.73-.35-23.39,7.79-6.5,8.93,1.19,9.2,3.78s-.97,13.28-8.85,19.61Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.48px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M186.36,176.6s5-18.56,5.11-25.92", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.48px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M204.24,177.47c-.64.33-1.91-17.66,3.94-20.81,8.13-4.38,7.75,2.64,7.52,4.98-.23,2.34-3.28,11.61-11.46,15.84Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.34px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M204.24,177.47s7.83-15.58,9.27-22.09", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.34px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M220.47,181.55c-.68.22.93-17.2,7.19-19.35,8.71-3,7.21,3.67,6.62,5.88-.6,2.21-5.08,10.64-13.81,13.47Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.32px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M220.47,181.55s10.19-13.76,12.64-19.78", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.32px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M234.25,187.46c-.72.03,5.53-16.31,12.14-16.7,9.19-.54,5.96,5.48,4.79,7.45-1.17,1.97-7.76,8.87-16.92,9.25Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.32px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M234.25,187.46s13.52-10.51,17.5-15.64", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.32px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M168.43,176.71c-.2-.77-15.95,11.47-14.25,18.56,2.37,9.86,7.87,4.37,9.64,2.46,1.76-1.91,7.15-11.24,4.61-21.02Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.48px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M168.43,176.71s-7.07,17.87-11.38,23.84", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.48px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M186.68,177.26c-.26-.75-14.94,12.76-12.65,19.68,3.19,9.63,8.21,3.7,9.81,1.65s6.19-11.79,2.84-21.33Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.48px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M186.68,177.26s-5.56,18.4-9.34,24.71", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.48px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M203.78,177.82c-.37-.62-10.95,14-7.65,19.75,4.59,8.02,7.97,1.85,9.02-.26s3.35-11.59-1.37-19.5Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.34px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M203.78,177.82s-1.58,17.37-3.79,23.65", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.34px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M220.95,181.18c-.46-.55-8.29,15.1-4.11,20.23,5.81,7.14,8.06.69,8.72-1.5.66-2.19,1.3-11.71-4.61-18.73Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.32px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M220.95,181.18s1.39,17.07.29,23.48", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.32px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M234.96,187.05c-.59-.4-3.91,16.77,1.49,20.59,7.52,5.31,7.95-1.51,8-3.79s-1.9-11.63-9.49-16.79Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.32px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M234.96,187.05s5.93,16.07,6.6,22.53", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.32px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M243.95,188.36s13.24-4.9,21.99,6.46c0,0,3.85,4.56-.82,4.41-4.67-.15-17.66,2.15-22.42-7.96,0,0-2.33-2.08,1.25-2.91Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.02px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M242.01,189.57c16.59.45,25.4,8.47,25.4,8.47", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.02px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M220.76,133.72s11.65-6.82,8.54-20.29c0,0-1.05-5.66-3.77-2.1-2.72,3.56-12.29,11.79-7.68,21.53,0,0,.13,3.01,2.91.86Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".98px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M218.7,134.43c9.71-12.6,9.11-24.06,9.11-24.06", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".98px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M237.5,129.05s9.78-7.52,5.42-19.37c0,0-1.6-5.01-3.65-1.47-2.06,3.54-9.79,12.1-4.5,20.39,0,0,.46,2.71,2.73.44Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".89px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M235.71,129.92c7.37-12.53,5.51-22.85,5.51-22.85", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".89px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M253.22,123.62s7.85-8.02,2.4-18.13c0,0-2.08-4.31-3.48-.86-1.4,3.45-7.27,12.15-1.44,18.94,0,0,.76,2.39,2.52.05Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".81px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M251.71,124.64c5.03-12.23,2.03-21.28,2.03-21.28", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".81px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M262.66,122.8s10.51-3.91,9.9-15.38c0,0-.04-4.78-2.78-2.26-2.74,2.53-11.75,7.89-9.38,16.52,0,0-.33,2.48,2.26,1.12Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".81px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M260.87,123.08c9.76-8.92,10.91-18.39,10.91-18.39", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".81px"
} }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M192.78,101.44c-.79.02,7.65,18.1,14.93,18.01,10.14-.13,6.01-6.71,4.54-8.85-1.47-2.14-9.37-9.47-19.47-9.16Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.48px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M192.78,101.44s15.86,10.86,20.73,16.38", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.48px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M198.35,85.5c-.74.29,13.22,14.54,20.06,12.04,9.53-3.48,3.45-8.32,1.35-9.85s-11.98-5.82-21.41-2.18Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.48px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M198.35,85.5s18.56,4.99,24.99,8.58", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.48px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M202.61,68.02c-.61.39,14.33,10.51,19.98,7.04,7.87-4.84,1.61-8.03-.53-9.01-2.14-.98-11.69-2.99-19.45,1.97Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.34px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M202.61,68.02s17.41,1.04,23.76,3.06", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.34px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M207.33,50.8c-.54.48,15.35,7.82,20.34,3.49,6.96-6.03.44-8.08-1.77-8.67-2.21-.59-11.75-.94-18.58,5.19Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.32px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M207.54,50.67s17.02-1.91,23.46-1.01", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.32px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M208.33,36.41c-.39.61,16.88,3.4,20.53-2.12,5.08-7.68-1.75-7.9-4.04-7.88s-11.57,2.26-16.49,10Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.32px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M208.33,36.41s15.88-6.42,22.32-7.29", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.32px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M192.71,101.34c.56.56-17.93,8.04-23.2,3.02-7.34-7,.17-9,2.7-9.57,2.54-.57,13.31-.55,20.49,6.55Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.48px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M192.71,101.34s-19.01-2.85-26.33-2.12", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.48px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M198.22,85.54c.38.7-19.49,2.48-23.07-3.86-4.99-8.83,2.78-8.57,5.37-8.37,2.59.2,12.89,3.35,17.7,12.23Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.48px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M198.22,85.54s-17.36-8.25-24.58-9.69", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.48px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M202.9,67.85c.35.63-17.6,2.45-20.93-3.29-4.63-8,2.4-7.83,4.75-7.67s11.71,2.92,16.18,10.97Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.34px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M202.9,67.85s-15.82-7.35-22.36-8.58", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.34px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M206.13,51.65c.24.68-17.22-.41-19.56-6.59-3.26-8.61,3.45-7.32,5.67-6.79,2.23.53,10.79,4.75,13.89,13.38Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.32px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M206.13,51.65s-14.07-9.76-20.16-12.03", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.32px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M208.09,36.59c.05.72-16.47-5.03-17.06-11.62-.82-9.17,5.29-6.12,7.29-5.01s9.11,7.48,9.77,16.63Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.32px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M208.09,36.59s-10.92-13.19-16.18-17.02", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.32px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M211.46,28.15s10.88-8.99,5.45-22.26c0,0-2.01-5.62-4.23-1.5-2.21,4.12-10.72,14.2-4.36,23.39,0,0,.63,3.06,3.14.38Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.02px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M209.45,29.22c7.93-14.58,5.41-26.23,5.41-26.23", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.02px"
} })))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M53.26,144.07s28-1.32,47.9-41.88c0,0-1.76-17.79-16.39-4.29,0,0-15.09,34.76-31.5,46.18Z", style: {
  fill: "#cd2735",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".87px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M49.51,173.19s37.64-6.49,57.99-66.22c0,0-5.26-24.45-22.86-3.25,0,0-14.79,50.85-35.13,69.46Z", style: {
  fill: "#cd2735",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.21px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M170.37,146.49s-30.03-2.52-50.13-48.07c0,0,2.46-19.58,17.75-4.12,0,0,15.12,38.96,32.38,52.19Z", style: {
  fill: "#cd2735",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".95px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M164.01,180.33s-38.01-11.39-51.53-75.52c0,0,8.48-24.56,23.98-.49,0,0,8.9,54.29,27.56,76.01Z", style: {
  fill: "#dd2c42",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.25px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M76.35,186.43s43.9-8.4,47.1-82.97c0,0-13.52-21.37-22.46,4.14,0,0-5.65,54.09-24.64,78.83Z", style: {
  fill: "#dd2c42",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.35px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M87.97,100.41s-16.8-20.4-18-33.59c-1.2-13.2,14.7-43.49,15-51.59,0,0,6.9,23.7,14.7,31.49,7.8,7.8,4.8,34.49,2.7,42.59-2.1,8.1-6,11.1-6,11.1h-8.4Z", style: {
  fill: "#cd2735",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.19px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M84.92,15.83s3.43,62.78,7.05,84.58", style: {
  fill: "#000",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.15px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M133.65,98.23s15.15-18.39,16.23-30.3c1.08-11.9-13.25-39.22-13.52-46.53,0,0-6.22,21.37-13.25,28.4-7.03,7.03-4.33,31.11-2.43,38.41s5.41,10.01,5.41,10.01h7.57Z", style: {
  fill: "#cd2735",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.07px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M136.4,21.95s-3.09,56.61-6.36,76.28", style: {
  fill: "#000",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.04px"
} })), /* @__PURE__ */ React.createElement("path", { d: "M104.71,99.32s-20.39-4.34-19.81-21.04c.58-16.69,18.46-49.16,22.6-68.14,0,0,15.41,35.55,18.97,40.47,4.47,6.17,10.28,28.54,5.05,36.54-5.23,8-12.03,12.16-12.03,12.16h-14.79Z", style: {
  fill: "#dd2c42",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.22px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M107.53,13.19s7.63,81.59,3.71,86.24", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.2px"
} })), /* @__PURE__ */ React.createElement("circle", { cx: 134.23, cy: 98.23, r: 11.76, style: {
  fill: "#3a3334",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".78px"
} }), /* @__PURE__ */ React.createElement("circle", { cx: 88.03, cy: 96.69, r: 11.68, style: {
  fill: "#3a3334",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".78px"
} }), /* @__PURE__ */ React.createElement("circle", { cx: 111.46, cy: 102.69, r: 16.42, style: {
  fill: "#3a3334",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.09px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M109.55,90.73s8.67-3.4,13.67,5.21c5,8.62-6.58,1.91-7.85.11s-11.61-3.25-5.82-5.32Z", style: {
  fill: "#93898b",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.15px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M132.95,89.92s5.46-2.14,8.61,3.28c3.15,5.43-4.14,1.2-4.94.07s-7.31-2.05-3.67-3.35Z", style: {
  fill: "#93898b",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".72px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M86.02,88.77s5.46-2.14,8.61,3.28-4.14,1.2-4.94.07-7.31-2.05-3.67-3.35Z", style: {
  fill: "#93898b",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".72px"
} }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M6.71,237.14c13.33-7.42,149.98-137.81,287.82,7.52l-43.73,1.47-2.53,46.54s-85.43-102.28-202.38,0l9.36-49.11-48.54-6.41Z", style: {
  fill: "#f9cd5f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "3.04px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M140.17,202.1c-1.83-3.95-4.26-8.42-10.19-7.63-3.74.49-5.84,3.46-5.36,7.07.47,3.54,3.13,5.35,9.18,7.44,7.35,2.5,12.17,5.27,13.01,11.65,1.01,7.6-4.58,13.98-13.79,15.2-4.12.54-7.94-.06-11.09-.57-.58-1.43-2.56-7.99-3.42-10.96l2.44-.91c1.83,3.89,6.36,10.43,12.68,9.6,3.93-.52,5.99-3.28,5.45-7.41-.49-3.74-3.1-5.62-8.9-7.81-7.18-2.72-11.99-5.36-12.87-12.07-.9-6.77,3.68-12.81,13.35-14.09,4.45-.59,7.93-.06,9.37-.06.57,2.35,1.47,5.64,2.52,9.63l-2.37.9Z", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M171.14,234.13l.19-2.32c4-.23,4.06-.92,3.41-3.7-.47-1.94-1.24-4.84-1.97-7.43l-12.24-1.03c-.86,1.89-1.93,4.2-2.72,6.09-1.65,3.84-.19,4.47,4.74,5.33l-.2,2.32-15.5-1.3.2-2.32c4-.17,5.05-.65,7.62-5.75l15.78-31.36,3.06-.31c3.1,11.26,6.43,22.79,9.63,33.62,1.83,6.03,2.36,6.52,6.4,7.36l-.19,2.32-18.2-1.53ZM168.14,203.66c-2.2,4.37-4.31,8.42-6.26,12.88l9.98.84-3.72-13.71Z", style: {
  fill: "#000",
  strokeWidth: 0
} }))));
const ForwardRef = forwardRef(SvgSa);
export default ForwardRef;
