import * as React from "react";
import { forwardRef } from "react";
const SvgVicCircle = (props, ref) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", "data-name": "Layer 1", xmlns: "http://www.w3.org/2000/svg", width: 298.07, height: 298.07, viewBox: "0 0 298.07 298.07", ref, ...props }, /* @__PURE__ */ React.createElement("rect", { width: 298.07, height: 298.07, style: {
  fill: "none",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("circle", { cx: 149.03, cy: 124.51, r: 120.18, style: {
  fill: "#ccc7e0",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "6.63px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M64.18,234.47s10.14-31.55,46.26-80.76c0,0,19.02-72.16,28.54-116.07,0,0-2.05-5.22,4.05-15.3,0,0,4.66,5.12-.83,15.72,0,0-16.44,55.63-27.11,111.45,0,0,5.43,11.41,24.98-4.56,0,0,8.84-9,18.32-25.08,10.59-17.97,25.44-42.06,33.33-61.01,0,0-.92-3.69,3.67-7.06,4.58-3.38,3.37-2.18,3.37-2.18,0,0,.64,5.4-4.02,9.91,0,0-38.96,76.95-52.66,88.3-11.19,9.27-16.42,9.15-16.42,9.15,0,0,40.92,21.6,107.23-27.3,0,0,4.59-5.28,10.37-5.73,0,0-1.17,4.49-7.96,7.46,0,0-68.88,51.98-115.42,27.28,0,0-4.03-1.18-5.38-.56s-4,4.1-4,4.1c0,0-22.34,26.55-40.66,82.42", style: {
  fill: "#7c6e5b",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.15px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M185.94,75.18c.51.31,13.26,4.12,21.3,2.66,0,0-6.44-5.56-21.2-4.55,0,0-2.07.69-.1,1.9Z", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".91px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M187.06,72.14c.59.13,13.92-.49,20.91-4.51,0,0-8.07-3.1-21.51,2.69,0,0-1.68,1.33.6,1.82Z", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".91px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M172.84,168.75s2.8-.93.8-4.76c-2-3.83-17.61-27.5-17.61-27.5,0,0-1.51-2.21-1.13-3.36.78-2.37,4.77-4.44,6.88-.76,0,0,15.86,23.72,18.4,26.08,1.3,1.21,3.9,2.94,6.4.95", style: {
  fill: "#ce849d",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".96px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M170.55,168.02s2.1,2.38,5.89-2.52c0,0-3.04-.38-3-4.82,0,0,4.7-.68,5.46,3.02,0,0-1.67-4.68.9-6.54,0,0,2.52,1.55,2.47,4.97,0,0,4.05.02,5.52-5.61,0,0,1.76,4.08-4.09,7.91,0,0,.02.28-1.12,1.95s-.64.35-.64.35c0,0-2.76,1.68-3.49.75,0,0-6,4.35-7.9.54Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".96px"
} })), /* @__PURE__ */ React.createElement("path", { d: "M89.41,183.34c1,.35,3.43-15.8-28.85-2.95,0,0,9.68-3.78,28.85,2.95Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.23px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M156.78,175.53s2.81-.17,1.9-4.2c-.91-4.03-9.54-29.71-9.54-29.71,0,0-.85-2.41-.22-3.38,1.3-2,5.49-2.94,6.55.98,0,0,8.86,25.79,10.62,28.59.91,1.44,2.89,3.67,5.68,2.44", style: {
  fill: "#ce849d",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".91px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M154.85,174.29s1.36,2.71,6.06-.89c0,0-2.71-1.1-1.59-5.19,0,0,4.51.52,4.3,4.13,0,0-.4-4.73,2.43-5.82,0,0,1.95,2.05,1.07,5.19,0,0,3.74,1.01,6.47-3.83,0,0,.63,4.2-5.71,6.31,0,0-.05.27-1.51,1.52s-.67.17-.67.17c0,0-2.96.87-3.41-.16,0,0-6.6,2.55-7.43-1.43Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".91px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M140.38,158.74s2.6-1.31.3-4.8c-2.29-3.49-19.61-24.6-19.61-24.6,0,0-1.66-1.96-1.41-3.15.52-2.45,4.15-5.06,6.54-1.73,0,0,17.56,21.11,20.23,23.08,1.37,1.01,4.04,2.35,6.24.02", style: {
  fill: "#ce849d",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".94px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M138.11,158.34s2.25,2.04,5.41-3.32c0,0-2.95.05-3.35-4.33,0,0,4.45-1.34,5.54,2.2,0,0-2.06-4.37.23-6.57,0,0,2.57,1.17,2.86,4.54,0,0,3.89-.56,4.76-6.31,0,0,2.09,3.77-3.16,8.37,0,0,.05.28-.89,2.08s-.58.44-.58.44c0,0-2.49,2.04-3.28,1.24,0,0-5.34,5.13-7.54,1.65Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".94px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M186.15,159.44s2.54-1.73-.69-5.05c-3.22-3.32-26.47-22.95-26.47-22.95,0,0-2.22-1.82-2.2-3.1.04-2.63,3.43-5.85,6.72-2.71,0,0,23.51,19.6,26.82,21.28,1.7.86,4.88,1.89,6.77-.84", style: {
  fill: "#ce849d",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.01px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M183.61,159.34s2.87,1.82,5.16-4.21c0,0-3.19.46-4.55-4.05,0,0,4.54-2.02,6.47,1.53,0,0-3.16-4.28-1.15-6.89,0,0,3.04.87,4.06,4.35,0,0,4.1-1.12,3.82-7.24,0,0,3.07,3.65-1.65,9.17,0,0,.11.28-.52,2.29s-.53.54-.53.54c0,0-2.27,2.48-3.3,1.75,0,0-4.7,6.1-7.82,2.77Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.01px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M153.06,110.15s2.5-1.33-.08-4.67-21.65-23.52-21.65-23.52c0,0-1.83-1.88-1.67-3.04.33-2.39,3.77-5,6.44-1.81,0,0,19.32,20.17,22.16,22.03,1.46.95,4.24,2.2,6.27-.1", style: {
  fill: "#ce849d",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".93px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M150.75,109.8s2.42,1.94,5.18-3.34c0,0-2.96.11-3.71-4.14,0,0,4.37-1.39,5.74,2.03,0,0-2.42-4.21-.29-6.39,0,0,2.68,1.09,3.23,4.36,0,0,3.87-.62,4.28-6.23,0,0,2.4,3.63-2.51,8.21,0,0,.07.27-.73,2.04s-.54.44-.54.44c0,0-2.34,2.04-3.2,1.27,0,0-4.96,5.1-7.44,1.76Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".93px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M190.82,142.88s3.25-.96,1.12-5.43-19.08-32.25-19.08-32.25c0,0-1.64-2.6-1.15-3.91,1-2.7,5.67-4.92,7.94-.61,0,0,17.24,27.84,20.05,30.64,1.45,1.44,4.36,3.53,7.31,1.32", style: {
  fill: "#ce849d",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.1px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M188.22,141.94s2.31,2.81,6.87-2.67c0,0-3.47-.55-3.25-5.65,0,0,5.43-.61,6.14,3.68,0,0-1.72-5.43,1.31-7.48,0,0,2.83,1.88,2.63,5.8,0,0,4.65.17,6.58-6.23,0,0,1.86,4.76-5.03,8.93,0,0,.01.33-1.37,2.19s-.75.38-.75.38c0,0-3.25,1.82-4.05.73,0,0-7.07,4.77-9.1.32Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.1px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M204.9,124.43s2.52-1.65-.59-4.97-25.57-22.93-25.57-22.93c0,0-2.15-1.82-2.1-3.07.09-2.58,3.47-5.69,6.65-2.55,0,0,22.73,19.6,25.95,21.3,1.65.87,4.75,1.93,6.65-.72", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".99px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M202.4,124.29s2.79,1.83,5.14-4.05c0,0-3.14.41-4.4-4.04,0,0,4.49-1.91,6.32,1.61,0,0-3.02-4.24-1-6.78,0,0,2.97.9,3.91,4.33,0,0,4.04-1.04,3.87-7.05,0,0,2.95,3.63-1.78,8.98,0,0,.1.28-.55,2.24-.65,1.96-.53.52-.53.52,0,0-2.27,2.4-3.27,1.66,0,0-4.71,5.91-7.72,2.59Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".99px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M216.27,101.06s1.54-2.15-2.18-3.99c-3.72-1.84-29.17-11.47-29.17-11.47,0,0-2.41-.88-2.8-1.94-.8-2.19.96-5.82,4.69-4.16,0,0,25.66,9.54,28.93,9.99,1.68.23,4.63.18,5.32-2.62", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".89px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M214.14,101.7s2.95.69,2.92-4.96c0,0-2.49,1.29-5.05-2.06,0,0,3.1-2.96,5.83-.57,0,0-3.97-2.64-3.14-5.38,0,0,2.78-.15,4.74,2.44,0,0,3.03-2.1.84-7.09,0,0,3.7,2.15,1.57,8.07,0,0,.18.2.3,2.04s-.27.6-.27.6c0,0-1.08,2.7-2.16,2.39,0,0-1.93,6.39-5.57,4.52Z", style: {
  fill: "#ce849d",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".89px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M204.25,184.7s2.27-.65.82-3.78c-1.46-3.13-13.08-22.58-13.08-22.58,0,0-1.12-1.82-.78-2.73.71-1.87,3.98-3.4,5.54-.38,0,0,11.82,19.49,13.76,21.46,1,1.01,3.02,2.48,5.08.97", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".77px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M202.44,184.04s1.59,1.97,4.81-1.82c0,0-2.41-.41-2.22-3.96,0,0,3.79-.39,4.25,2.6,0,0-1.16-3.8.96-5.2,0,0,1.96,1.33,1.79,4.06,0,0,3.24.15,4.63-4.3,0,0,1.26,3.32-3.56,6.19,0,0,0,.23-.97,1.52s-.52.26-.52.26c0,0-2.27,1.25-2.82.49,0,0-4.96,3.28-6.34.17Z", style: {
  fill: "#ce849d",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".77px"
} })), /* @__PURE__ */ React.createElement("path", { d: "M196.93,154.16c.2.66,8.53,13.49,16.99,18.12,0,0-1.2-9.72-15.33-19.74,0,0-2.43-.91-1.66,1.62Z", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.09px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M200.46,152.28c.43.55,12.94,9.86,22.57,11.44,0,0-4.7-8.74-21.6-13.51,0,0-2.61-.06-.96,2.07Z", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.09px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M214.23,145.09c.28.28,8.02,4.66,13.54,4.88,0,0-3.31-4.59-13.16-6.11,0,0-1.47.15-.38,1.23Z", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".62px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M155.6,138.3s1.68-2.15-2.06-4.18-29.46-12.94-29.46-12.94c0,0-2.44-.99-2.79-2.1-.73-2.28,1.25-5.94,5-4.09,0,0,25.94,10.82,29.28,11.41,1.71.3,4.75.36,5.58-2.48", style: {
  fill: "#ce849d",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".91px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M153.38,138.87s3,.82,3.22-4.98c0,0-2.61,1.23-5.1-2.31,0,0,3.32-2.92,6.02-.36,0,0-3.96-2.87-2.99-5.65,0,0,2.87-.04,4.76,2.7,0,0,3.21-2.04,1.18-7.25,0,0,3.7,2.35,1.25,8.35,0,0,.18.21.22,2.11s-.3.6-.3.6c0,0-1.23,2.73-2.33,2.37,0,0-2.27,6.49-5.93,4.42Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".91px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M166.1,91s1.51-2.11-2.14-3.92c-3.65-1.8-28.63-11.26-28.63-11.26,0,0-2.37-.86-2.75-1.9-.79-2.15.95-5.72,4.6-4.08,0,0,25.19,9.36,28.39,9.81,1.65.23,4.54.17,5.22-2.58", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".87px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M164.01,91.63s2.9.68,2.87-4.87c0,0-2.44,1.27-4.96-2.02,0,0,3.05-2.91,5.72-.56,0,0-3.9-2.59-3.09-5.28,0,0,2.73-.14,4.65,2.4,0,0,2.97-2.06.83-6.96,0,0,3.63,2.11,1.54,7.92,0,0,.18.2.29,2.01s-.26.58-.26.58c0,0-1.06,2.65-2.12,2.34,0,0-1.9,6.27-5.47,4.44Z", style: {
  fill: "#ce849d",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".87px"
} })), /* @__PURE__ */ React.createElement("path", { d: "M131.5,70.73c-.29.5-9.55,9.55-17.36,11.86,0,0,2.82-7.76,16.3-13.48,0,0,2.17-.32,1.06,1.62Z", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".91px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M135.24,65.6c.76-.22,18.33-.73,27.99,3.87,0,0-10.2,4.86-28.55-1.43,0,0-2.37-1.58.56-2.44Z", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.2px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M138.69,51.72c.54-.14,12.8-.13,19.44,3.27,0,0-7.23,3.18-19.89-1.58,0,0-1.62-1.15.45-1.69Z", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".84px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M118.85,134.55c.33.25,5.62,7.49,5.99,12.87,0,0-5.45-2.89-7.42-12.4,0,0,.14-1.45,1.43-.46Z", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".66px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M100.05,175.01c-1.01-.3,7.48-14.48,24.69,14.51,0,0-5.2-8.63-24.69-14.51Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.23px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M117.6,126.84c.74.45,6.04-11.97-22.86-7.29,0,0,8.65-1.35,22.86,7.29Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.02px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M102.33,166.96c.8.3,11.07-6.08-26.74-5.78,0,0,11.3-.06,26.74,5.78Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".86px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M110.5,147.01c.8.3,11.07-6.08-26.74-5.78,0,0,11.3-.06,26.74,5.78Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".86px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M124.02,99.27c.75.16-1.36-11.78-19.97.59,0,0,5.59-3.66,19.97-.59Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".88px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M154.3,165.22c-.49-.73,14.41-3.71,7.54,21.63,0,0,1.99-7.59-7.54-21.63Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.05px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M118.8,157.92c-.72-.83,18.75-2.79,12.03,24.06,0,0,1.93-8.05-12.03-24.06Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.26px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M132.44,63.12c.51.19,7.03-3.86-16.98-3.67,0,0,7.17-.04,16.98,3.67Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".54px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M136.39,44.72c.17.49,7.67,2.62-8.01-14.63,0,0,4.67,5.17,8.01,14.63Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".54px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M136.45,50.8c.38.28,2.61-6.98-11.45-4.8,0,0,4.21-.62,11.45,4.8Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".55px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M185.56,69.38c-.01.66,11.11-1.04-4.31-17.65,0,0,4.58,4.99,4.31,17.65Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".81px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M228.73,133.28c-.21.51,9.4.89,1.75-14.4,0,0,2.25,4.58-1.75,14.4Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".67px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M225.22,135.61c-.55-.05-5.54,5.61,17.35-1.1,0,0-6.85,1.99-17.35,1.1Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".54px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M6.71,237.29c13.33-7.42,149.98-137.81,287.82,7.52l-43.73,1.47-2.53,46.54s-85.43-102.28-202.38,0l9.36-49.11-48.54-6.41Z", style: {
  fill: "#f9cd5f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "3.04px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M130.64,195.03c-3.96,1.53-5.05,2.42-5.81,8.93-.63,5.27-2.87,22.32-4.31,35.17l-3.4.92c-3.03-4.35-16.78-23.38-21.14-29.24-3.17-4.12-4.24-4.89-8.35-4.18l-.62-2.29,18.85-5.08.55,2.3c-4.34,1.83-4.23,2.27-2.54,4.6,3.32,4.67,11.38,16.15,14.95,21.03,1.33-9.84,2.4-19.14,2.73-23.54.4-4.68-.29-5.49-5.63-4.65l-.62-2.29,14.71-3.97.62,2.29Z", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M141.25,234.48l-.02-2.37c4.86-.5,5.43-.89,5.38-6.26l-.25-24.38c-.05-5.38-.7-5.88-5.5-6.22l-.02-2.37,19.77-.2.02,2.37c-4.8.5-5.43.95-5.38,6.33l.25,24.38c.05,5.31.63,5.69,5.5,6.15l.02,2.37-19.77.2Z", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M205.35,232.23c-1.63,2.89-4.41,7.22-5.94,9.15-1.71-.02-7.11-.04-11.83-1.2-16.72-4.11-20.97-16.56-18.54-26.44,3.17-12.86,15.12-19.15,30.04-15.48,5.41,1.33,9.87,3.81,11.38,4.38-.41,3-1.1,7.7-1.34,10.54l-2.59-.18c-.11-5.17-1.41-7.93-4.38-10.04-1.34-.99-3.51-1.79-5.19-2.2-9.01-2.22-15.4,4.19-17.8,13.94-2.8,11.37,1.15,20.52,9.66,22.61,5.22,1.28,9.37-.59,14.41-6.6l2.13,1.51Z", style: {
  fill: "#000",
  strokeWidth: 0
} }))));
const ForwardRef = forwardRef(SvgVicCircle);
export default ForwardRef;
