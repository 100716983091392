import * as React from "react";
import { forwardRef } from "react";
const SvgGreyQuestion = (props, ref) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", "data-name": "Layer 1", xmlns: "http://www.w3.org/2000/svg", width: 298.07, height: 298.07, viewBox: "0 0 298.07 298.07", ref, ...props }, /* @__PURE__ */ React.createElement("rect", { width: 298.07, height: 298.07, style: {
  fill: "none",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", { id: "Plus" }, /* @__PURE__ */ React.createElement("circle", { cx: 147.03, cy: 146.08, r: 57.7, style: {
  fill: "#636363",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "2.14px"
} })), /* @__PURE__ */ React.createElement("path", { d: "M137.99,165.54v-2.29c0-7.31.57-11.91,1.72-13.82,1.14-1.91,4.16-4.67,9.06-8.28,2.57-1.89,4.35-3.64,5.36-5.27,1-1.63,1.51-3.64,1.51-6.05,0-2.97-.94-5.31-2.83-7.01-1.89-1.71-4.18-2.56-6.86-2.56-7.1,0-11.1,5.02-11.98,15.05l-16.5-2.35c1.24-9.07,4.57-15.94,9.96-20.59,5.4-4.66,11.99-6.98,19.78-6.98s14.61,2.29,19.99,6.86c5.38,4.58,8.07,10.38,8.07,17.4,0,8.71-4.48,16-13.43,21.86-3.89,2.57-6.25,4.58-7.07,6.02-.82,1.45-1.23,4.12-1.23,8.01h-15.53ZM153.67,187.24h-16.04v-16.34h16.04v16.34Z", style: {
  fill: "#fff",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.5px"
} })));
const ForwardRef = forwardRef(SvgGreyQuestion);
export default ForwardRef;
