import * as React from "react";
import { forwardRef } from "react";
const SvgNt = (props, ref) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", "data-name": "Layer 1", xmlns: "http://www.w3.org/2000/svg", width: 298.96, height: 298.07, viewBox: "0 0 298.96 298.07", ref, ...props }, /* @__PURE__ */ React.createElement("rect", { width: 298.07, height: 298.07, style: {
  fill: "none",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M145.37,118.58s49.47-86.63,126.85-3.54c0,0-55.86,70.22-126.85,3.54Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".93px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M148.55,115.45s37.71-30.45,57.97-33.6c-8.43,5.74-18.79,6.93-29.7,19.1-.84.94,45.92-4.68,85.91,10.34,0,0-42.57-16.73-88.03-3.44,0,0,40.2,20.7,44.86,26.97,0,0-41.81-29.44-71-19.36Z", style: {
  fill: "#b2b272",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".93px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M188.35,159.51s-68.49-103.1-157.8-9.09c0,0,75.08,84.18,157.8,9.09Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.12px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M184.14,155.74s-49.31-37.03-74.72-41.51c10.94,7.04,23.89,8.82,38.44,23.46,1.12,1.13-57.39-7.26-105.82,8.72,0,0,51.5-17.87,109.02-.58,0,0-48.23,22.6-53.5,29.74,0,0,49.52-32.75,86.58-19.83Z", style: {
  fill: "#b2b272",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.12px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M145.45,91.81S175.72,10.83,79.42,3.74c0,0-16.05,76.33,66.03,88.07Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".8px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M145.79,87.94s-.31-42.26-9.15-57.74c.77,8.87,5.57,16.55,3.46,30.61-.16,1.09-21.66-33.74-53.09-52.68,0,0,33.93,19.76,49.7,57.89,0,0-35.26-15.98-41.89-15.71,0,0,41.88,12.29,50.98,37.63Z", style: {
  fill: "#b2b272",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".8px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M57.32,65.36s-1.23,41.85,46.7,46.44c0,0,8.59,2.56,9.77-8.6,1.17-11.17-9.5-38.1-26.66-49.34-17.16-11.24-33.63-6.61-29.81,11.51Z", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.22px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M60.84,50.77s7.43,36.98,31.74,49.35c24.31,12.37,16.56,11.42,20.93,8.96,4.38-2.47.51-25.31-13.6-42.63-14.11-17.32-41.38-24.15-39.07-15.68Z", style: {
  fill: "#ce849d",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.27px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M54.62,135.11s26.92,32.06,65.76,3.6c0,0,8.12-3.8,1.57-12.92-6.55-9.12-32.44-22.13-52.73-19.11-20.29,3.02-29.51,17.44-14.61,28.42Z", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.22px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M47.54,121.88s30.15,22.67,56.53,15.73c26.38-6.94,19.96-2.48,21.59-7.24,1.63-4.76-16.46-19.23-38.51-22.78-22.05-3.54-46.96,9.5-39.6,14.29Z", style: {
  fill: "#ce849d",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.27px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M154.66,109.71l-22.46-54.86s13.39-19.44,48.38-13.82c34.99,5.62,45.36,44.06,47.09,54.86,1.73,10.8-59.62,19.01-59.62,19.01l-13.39-5.18Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.14px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M156.31,121.33s15.18-15.53,20.59-32.22c5.41-16.69-1.96-40.76-30.68-36.87s-50.46,28.72-48.91,53.95c1.55,25.23,38.85,22.51,40.58,22.12,1.73-.39,18.42-6.99,18.42-6.99Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.02px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M185.33,126.56l30.67,28.51s13.39,3.89,19.87-28.51c6.48-32.4-21.37-52.7-26.02-53.14s-21.75-4.75-22.49,11.23c-.74,15.98,6.17,38.02-2.04,41.9Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.14px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M142.47,115.84s-9.25-16.26,1.59-20.72c10.84-4.46,18.81-1.91,18.81,7.97l3.19,1.33s3.19-13.77,15.94-9.62c12.75,4.14,18.22,15.62,10.86,21.04-7.36,5.42-11.82,3.83-11.82,3.83,0,0,1.01,1.68,1.94,1.3,2.52-1.04,7.31-2.47,13.19-1.62,8.78,1.28,6.23,14.99,2.4,17.22-3.83,2.23-9.88,5.74-17.54,3.19,0,0,8.29,3.83,6.06,6.38-2.23,2.55-9.88,8.29-14.03,4.78-4.14-3.51-30.61-35.07-30.61-35.07Z", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.26px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M152.87,131.6s-4.85-8.84.41-13.78l1.09,3.06s1.57-6.28,3.99-7.74c0,0,1.48,3.26,1.29,3.65s.19-.38.19-.38c0,0,3.08-6.22,4.07-6.36,0,0,.26,3.08.15,3.89,0,0,2.73-4.32,4.55-5.17,0,0-.48,4.08-.67,4.75s3.66-3.08,5.75-3.21c0,0-2.21,4.95-3.72,5.84,0,0,2.91-.28,5.27.74,0,0-2.34,2.99-6.66,3.22,0,0,3.45-.21,5.93,2.01,0,0-3.34,1.26-6.28,1.54,0,0,2.85.4,4.75,2.6,0,0-2.01,1.52-6.02,1.51,0,0,2.29.46,3.53,2.43,0,0-2.46.76-6.88.7,0,0,1.32-.51,3.63,2.72,0,0-9.03,5.24-14.38-2.01Z", style: {
  fill: "#f9f9e1",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.08px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M143.16,124.75s-25.07-30.09-20.52-45.64c4.55-15.55-10.69-1.91-11.88,3.5-1.19,5.41-18.68,63.47,19.94,84.19,38.62,20.72,30.14-23.59,30.14-23.59l-17.68-18.46Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.06px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M139.84,109.2s18.88,30.91,26.56,32.82c10.27,2.56,13.12.42,20.15-10.39,6.67-10.26,2.67-18.46,13.88-19.49,11.21-1.03,30.95,14.53,26.69,30.35-4.27,15.81-21.34,42.41-57.79,22.99-50.29-26.8-44.82-63.21-44.82-63.21,0,0,5.6-12.95,15.34,6.92Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.38px"
} }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M7.71,237.64c13.33-7.42,149.98-137.81,287.82,7.52l-43.73,1.47-2.53,46.54s-85.43-102.28-202.38,0l9.36-49.11-48.54-6.41Z", style: {
  fill: "#f9cd5f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "3.04px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M151.11,193.03c-3.79,1.05-4.88,2.05-4.67,4.93.16,2.5.27,4.68,1.06,10.19l3.57,25.22-3.55.5-30.91-27.94,2.03,14.32c.78,5.51,1.26,7.97,1.71,9.78.65,2.75,2.19,4.02,6.91,3.61l.33,2.34-15.71,2.23-.33-2.34c3.57-.83,5.02-2,4.97-5.04-.07-2.32-.27-4.62-1.05-10.13l-2.13-15.02c-.3-2.09-.62-3.47-2.06-4.94-1.37-1.42-2.91-1.4-5.61-1.27l-.33-2.34,12.36-1.75,28.12,24.65-1.62-11.41c-.78-5.51-1.14-7.6-1.66-9.91-.58-2.7-1.78-3.89-7.08-3.46l-.33-2.34,15.65-2.22.33,2.34Z", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M198.8,209.14c-.15-3.02-1.09-5.67-1.56-6.67-.89-1.53-1.3-2.13-5.01-2.82l-4.03-.75-5.51,29.57c-.98,5.28-.43,5.78,5.09,7.26l-.43,2.33-20.89-3.89.43-2.33c5.43.56,6.07.28,7.05-5l5.51-29.57-3.84-.71c-3.52-.66-4.64-.28-6.11,1.01-1.1,1.03-2.41,3.13-3.69,5.5l-2.39-.45c1.3-4.51,2.65-9.34,3.34-12.33l1.89.35c.67,1.62,1.41,1.89,3.29,2.24l25.17,4.69c1.7.32,2.65.1,3.94-.89l1.82.34c-.43,2.65-1.21,8.24-1.66,12.39l-2.43-.26Z", style: {
  fill: "#000",
  strokeWidth: 0
} }))));
const ForwardRef = forwardRef(SvgNt);
export default ForwardRef;
