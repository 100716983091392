import * as React from "react";
import { forwardRef } from "react";
const SvgSubmitTipoff = (props, ref) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", "data-name": "Layer 1", xmlns: "http://www.w3.org/2000/svg", width: 298.07, height: 298.07, viewBox: "0 0 298.07 298.07", ref, ...props }, /* @__PURE__ */ React.createElement("rect", { width: 298.07, height: 298.07, style: {
  fill: "none",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("circle", { cx: 219.4, cy: 78.03, r: 71.96, style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("circle", { cx: 219.4, cy: 78.03, r: 59.21, style: {
  fill: "#aaa",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".69px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M13.57,247.79l79.42-79.42s8.5-10.26,17.88,0,19.34,20.81,19.34,20.81c0,0,10.16,6.63-2.34,16.62-12.85,10.26-82.06,80.68-82.06,80.68,0,0-8.6,7.22-15.83,0-9.46-9.44-23.45-24.03-23.45-24.03,0,0-3.91-6.85,7.03-14.65Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "2.32px"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "109.05 166.56 167.72 108.83 185.74 130.37 129.88 189.4 109.05 166.56", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M219.55,29.7c4.86,0,11.87.86,12.01,6.29,0,1.72-.28,4.29-.57,6.29-1.43,9.15-5.58,31.6-8.01,49.62h-6.86c-2-16.59-6.15-39.32-7.86-49.19-.43-1.72-1-5-1-6.72,0-5,7.15-6.29,12.3-6.29ZM219.69,126.36c-6.72,0-11.58-4.86-11.58-12.01s4.86-11.87,11.58-11.87,11.72,4.86,11.72,11.87-4.86,12.01-11.72,12.01Z", style: {
  fill: "#fff",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "2.77px"
} })));
const ForwardRef = forwardRef(SvgSubmitTipoff);
export default ForwardRef;
