import * as React from "react";
import { forwardRef } from "react";
const SvgGreenTick = (props, ref) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", "data-name": "Layer 1", xmlns: "http://www.w3.org/2000/svg", width: 298.07, height: 298.07, viewBox: "0 0 298.07 298.07", ref, ...props }, /* @__PURE__ */ React.createElement("rect", { width: 298.07, height: 298.07, style: {
  fill: "none",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("circle", { cx: 144.79, cy: 151.36, r: 57.7, style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "2.14px"
} }), /* @__PURE__ */ React.createElement("polygon", { points: "145.03 175.33 188.56 131.8 175.25 118.96 131.96 162.26 110.28 140.59 97.21 153.66 118.88 175.33 131.96 188.41 145.03 175.33", style: {
  fill: "#fff",
  stroke: "#231f20",
  strokeMiterlimit: 10,
  strokeWidth: "2.14px"
} })));
const ForwardRef = forwardRef(SvgGreenTick);
export default ForwardRef;
