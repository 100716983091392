import * as React from "react";
import { forwardRef } from "react";
const SvgCake = (props, ref) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", "data-name": "Layer 1", xmlns: "http://www.w3.org/2000/svg", width: 298.07, height: 298.07, viewBox: "0 0 298.07 298.07", ref, ...props }, /* @__PURE__ */ React.createElement("rect", { width: 298.07, height: 298.07, style: {
  fill: "none",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("g", { id: "Cupcake" }, /* @__PURE__ */ React.createElement("path", { d: "M158.77,53.14c5.6,6.71,17.32,18.7,36.07,24.74,6.51,2.1,12.54,3.05,17.66,3.43-4.22,3.32-19.62,14.58-40.99,13.19-10.4-.68-18.47-4.1-23.39-6.72", style: {
  fill: "#ede8e4",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "2.16px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M238.65,139.48s-2.15-27.64-17.82-35.23c0,0-36.87,24.97-73.75,35.23h91.57Z", style: {
  fill: "#f9cd5f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "2.16px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M69.17,138.33s-5.33-22.07,21.33-27.25c26.66-5.18,70.52-1.29,98.87-12.08,28.35-10.78,27.38-15.31,27.38-15.31,0,0,10.66,7.76,9.94,19.63-.73,11.86-13.09,16.39-13.09,16.39l-49.92,18.62h-94.51Z", style: {
  fill: "#fff",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.51px"
} }), /* @__PURE__ */ React.createElement("g", { id: "Cupcake_col", "data-name": "Cupcake col" }, /* @__PURE__ */ React.createElement("path", { d: "M200.8,232.27l17.41-92.79,20.06,1.02c-11.55,36.42-22.65,88.48-30.14,90.97", style: {
  fill: "#6230b0",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("polyline", { points: "100.97 232.27 82.46 138.62 106.04 138.34 114.41 232.27", style: {
  fill: "#6230b0",
  strokeWidth: 0
} })), /* @__PURE__ */ React.createElement("ellipse", { cx: 151.7, cy: 66.57, rx: 21.66, ry: 21.2, style: {
  fill: "#cc2936",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "2.16px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M95.79,110s-5.32-10.21,0-19.81c5.32-9.6,24.99-18.78,34.93-20.01,0,0,1.39,4.08,14.58,9.19,13.19,5.1,64.55,1.02,64.55,1.02,0,0,6.94-1.23,6.48,3.68s-37.25,33.89-120.53,25.93Z", style: {
  fill: "#fff",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.51px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M238.7,138.49c0-5.8-4.5-10.5-10.06-10.5s-9.73,4.37-10.04,9.87h-.02s-10.45,93.86-10.45,93.86c7.5-2.49,19.14-56.76,30.69-93.18", style: {
  fill: "#6230b0",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M197.57,138.82c-.28-6.38-5.54-11.48-12-11.48s-11.72,5.09-12,11.48h-.22l-2.09,93.69h16.75l9.7-93.69h-.14Z", style: {
  fill: "#6230b0",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M150.7,138.85h-.56s.53,0,.53,0c0-6.63-6.16-12.61-12.74-12.61s-10.92,5.84-11.02,12.38h-.16s3.74,93.65,3.74,93.65h20.33l-.13-93.42Z", style: {
  fill: "#6230b0",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M82.33,138.88c0-6.63,5.26-12.01,11.75-12.01s11.75,5.38,11.75,12.01", style: {
  fill: "#6230b0",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M82.96,138.68h-.13c-.14-6.54-4.42-11.79-9.68-11.79s-9.69,5.42-9.69,12.12h.21c1.27,5.05,20.4,85.63,28.69,91.6l9.11,1.73-18.51-93.65Z", style: {
  fill: "#a197c6",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M126.88,137.79h-.29c-.39-5.96-4.82-10.67-10.24-10.67s-9.85,4.7-10.24,10.67h-.29l8.58,94.48,16.09.48-3.62-94.96Z", style: {
  fill: "#a197c6",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M161.97,127.28c-6.22,0-11.26,5.15-11.26,11.51l.13,93.74h20.44l2.3-93.7c0-6.36-5.38-11.54-11.6-11.54Z", style: {
  fill: "#a197c6",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M218.75,138.85c0-6.36-4.74-11.51-10.58-11.51s-10.58,5.15-10.58,11.51h.1l-9.67,93.42h12.78l17.73-93.42h.22Z", style: {
  fill: "#a197c6",
  strokeWidth: 0
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { points: "81.38 138.66 101.46 232.33 83.54 138.66 81.38 138.66", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("polygon", { points: "104.9 138.62 114.41 232.27 107.06 138.62 104.9 138.62", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("polygon", { points: "125.69 138.62 130.5 232.75 127.82 138.54 125.69 138.62", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("polygon", { points: "149.75 138.45 150.9 232.5 151.91 138.47 149.75 138.45", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("polygon", { points: "172.25 138.62 171.27 232.5 174.41 138.62 172.25 138.62", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("polygon", { points: "196.63 138.5 188.02 232.27 198.78 138.58 196.63 138.5", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("polygon", { points: "217.81 138.46 200.8 232.27 219.95 138.58 217.81 138.46", style: {
  fill: "#000",
  strokeWidth: 0
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M173.36,138.58c0-6.56,5.45-11.87,12.18-11.87s12.18,5.31,12.18,11.87", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "2.16px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M126.75,138.37c0-6.44,5.39-11.65,12.04-11.65s12.04,5.22,12.04,11.65", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "2.16px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M82.46,138.62c0-6.57,5.26-11.9,11.75-11.9s11.75,5.33,11.75,11.9", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "2.16px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M218.88,138.58c0-5.9,4.47-10.69,9.98-10.69s9.98,4.79,9.98,10.69v.03s-25.23,86.26-25.23,86.26c0,0-1.6,5.5-6.71,7.66h-109.1s-4.78-.34-8.27-4.94c-3.35-4.4-25.3-80.65-26.45-88.39l.02-.53c0-6.63,4.34-12.01,9.69-12.01s9.69,5.38,9.69,12.01", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "2.16px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M105.96,138.62c0-6.44,4.66-11.65,10.4-11.65s10.4,5.22,10.4,11.65", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "2.16px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M150.83,138.58c0-6.3,5.04-11.41,11.26-11.41s11.26,5.11,11.26,11.41", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "2.16px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M197.71,138.58c0-6.3,4.74-11.41,10.58-11.41s10.58,5.11,10.58,11.41", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "2.16px"
} }))));
const ForwardRef = forwardRef(SvgCake);
export default ForwardRef;
