import * as React from "react";
import { forwardRef } from "react";
const SvgNswCircle = (props, ref) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", "data-name": "Layer 1", xmlns: "http://www.w3.org/2000/svg", width: 298.07, height: 298.22, viewBox: "0 0 298.07 298.22", ref, ...props }, /* @__PURE__ */ React.createElement("rect", { width: 298.07, height: 298.07, style: {
  fill: "none",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("circle", { cx: 151.45, cy: 124.39, r: 120.18, style: {
  fill: "#ccc7e0",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "6.63px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M147.87,157.15s-2.62-7.42-33.07-5.63c-30.46,1.79-47.6-4.09-47.6-4.09,0,0-5.02-1.41-8.57-.45-3.55.96,0,4.32,0,4.32,0,0,17.71,17.08,43.84,9.98,26.13-7.1,40.42-8.16,45.41-4.13Z", style: {
  fill: "#a9af6e",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.01px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M149.71,193.88s23.55-3.48,45.05-16.85c21.51-13.37,35.97-16.33,35.97-16.33,0,0,5.12-.7.24,4.57s-6.51,14.84-13.89,19.95c-7.38,5.11-46.01,16.1-65.26,9.59", style: {
  fill: "#a9af6e",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.16px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M148.43,164.7s-8.3-9.09-25.25,0c-16.96,9.09-20.15,9.47-26.68,10.75-6.53,1.28-19.45,7.81-3.84,9.98s21.4-8.8,26.42-12.8,16.03-11.26,29.36-7.93Z", style: {
  fill: "#a9af6e",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.01px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M151.79,155.24s35.43-9.41,55.39-14.58c0,0,12.79-3.63,23.42-8.25,9.89-4.3,19.39-13.54,19.39-13.54,0,0,8.06-13.92-4.8-11.04-12.86,2.88-38.2,20.36-44.15,24.58s-49.25,22.83-49.25,22.83Z", style: {
  fill: "#a9af6e",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.01px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M142.18,129.33s-.5,19.79,3.97,33.2c4.86,14.59,5.36,39.93,1.72,41.46l12.64,2.05s.05-15.72,0-22.14c-.04-4.63-1.96-15.26-4.88-25.21-.47-1.6-4.63-13.93-1.64-25.07,3.19-11.91-11.81-4.3-11.81-4.3Z", style: {
  fill: "#efd7a0",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.01px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M149.12,136.37s-37.92-24-44.31-31.11c-6.24-6.94-15.48-17.47,5.31-41.01,20.79-23.55,28.47-28.41,31.29-30.46,2.82-2.05,7.75-5.12,19.1,1.02,11.35,6.14,28.83,21.5,34.68,26.62,5.85,5.12,18.14,24.57,5.85,35.57s-25.94,40.31-51.92,39.36Z", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.01px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M147.87,136.63s1.12.38,7.09-3.65c5.97-4.03,15.18-6.72,20.94-5.76,5.76.96,22.88-5.37,26.32-7.87,0,0-18.64,17.66-25.55,19.58-6.91,1.92-28.8-2.3-28.8-2.3Z", style: {
  fill: "#b9002f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.01px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M146.15,132.39s-.19-10.75-4.61-15.8c-4.41-5.05-17.66-12.28-18.36-16.7,0,0,2.62,23.02,6.65,26.42s15.23,6.28,16.32,6.08Z", style: {
  fill: "#b9002f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.01px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M78.43,129.89s13.5,10.49,21.5,11.84c8,1.34,18.49-4.27,20.79-6.26,2.3-1.99,19.45-6.6,27.7,1.27,0,0-14.97-10.69-28.6-10.43-13.63.26-22.38,4.83-24.51,4.86s-15.04-.06-16.89-1.28Z", style: {
  fill: "#b9002f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.01px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M171.48,120.56c-.64-.03,4.22-10.75,13.44-16,9.21-5.25,13.95-3.58,20.09-7.04,6.14-3.46,13.95-20.22,13.95-21.5,0,0-2.41,46.73-47.48,44.53Z", style: {
  fill: "#b9002f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.01px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M126.44,121.58c.39.12-19.96-8.19-27.26-15.48s-6.65-17.79-28.28-20.6c0,0,11.64,22.65,25.72,28.54s22.91,5.37,29.82,7.55Z", style: {
  fill: "#b9002f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.01px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M152.42,126.83s33.6-13.56,22.62-38.01c0,0,.41,9.73-4.2,13.44-4.61,3.71-17.77,17.16-18.43,24.57Z", style: {
  fill: "#b9002f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.01px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M150.37,128.11s0-14.72-4.22-25.59c-4.22-10.88,4.22-20.09,4.22-20.09,0,0,7.51,8.19,4.59,19.96-2.93,11.77-4.84,22.91-4.59,25.72Z", style: {
  fill: "#b9002f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.01px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M123.18,115.5c.08.66-11.01-9.12-12.54-14.3s.29-11.71,2.78-16.12c0,0-2.59,7.01.48,11.04s8.77,15.16,9.28,19.39Z", style: {
  fill: "#b9002f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.01px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M188.52,98.33s30.58-19.44,15.35-41.48c0,0,2.17,9.49-1.69,13.98s-14.36,20.1-13.66,27.51Z", style: {
  fill: "#b9002f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.01px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M107.07,100.31s-30.58-19.44-15.35-41.48c0,0-2.17,9.49,1.69,13.98,3.86,4.49,14.36,20.1,13.66,27.51Z", style: {
  fill: "#b9002f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.01px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M160.93,102.43s3.03-12.01,1.83-21.76c-1.21-9.75,7.59-15.53,7.59-15.53,0,0,4.45,8.23-.37,17.24-4.81,9.01-8.67,17.7-9.04,20.05Z", style: {
  fill: "#b9002f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".85px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M138.25,106.8s-3.03-12.01-1.83-21.76c1.21-9.75-7.59-15.53-7.59-15.53,0,0-4.45,8.23.37,17.24,4.81,9.01,8.67,17.7,9.04,20.05Z", style: {
  fill: "#b9002f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".85px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M180.12,87.97s3.03-12.01,1.83-21.76c-1.21-9.75,7.59-15.53,7.59-15.53,0,0,4.45,8.23-.37,17.24-4.81,9.01-8.67,17.7-9.04,20.05Z", style: {
  fill: "#b9002f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".85px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M119.07,89.65s-3.03-12.01-1.83-21.76c1.21-9.75-7.59-15.53-7.59-15.53,0,0-4.45,8.23.37,17.24,4.81,9.01,8.67,17.7,9.04,20.05Z", style: {
  fill: "#b9002f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".85px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M176.97,63.53s9.61-21.67-6.68-28.15c0,0,4.62,4.38,4.15,8.22s-.53,16.15,2.53,19.93Z", style: {
  fill: "#b9002f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".66px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M124,64.76s-9.61-21.67,6.68-28.15c0,0-4.62,4.38-4.15,8.22.47,3.84.53,16.15-2.53,19.93Z", style: {
  fill: "#b9002f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".66px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M17.67,243.59c12.22-6.81,137.52-126.36,263.9,6.9l-40.1,1.34-2.32,42.67s-78.33-93.78-185.56,0l8.58-45.03-44.51-5.88Z", style: {
  fill: "#f9cd5f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "2.79px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M122.32,205.98c-3.28,1.51-4.12,2.57-3.51,5.14.51,2.24.93,4.2,2.44,9.07l6.9,22.31-3.14.97-32.05-20.8,3.92,12.67c1.51,4.88,2.3,7.03,2.97,8.6.99,2.4,2.56,3.32,6.78,2.26l.64,2.07-13.9,4.3-.64-2.07c3.11-1.27,4.25-2.54,3.77-5.28-.4-2.09-.91-4.14-2.42-9.02l-4.11-13.29c-.57-1.85-1.07-3.05-2.58-4.18-1.44-1.09-2.84-.84-5.27-.34l-.64-2.07,10.93-3.38,29.05,18.23-3.12-10.09c-1.51-4.88-2.14-6.71-2.95-8.73-.91-2.36-2.18-3.26-6.92-2.1l-.64-2.07,13.85-4.29.64,2.07Z", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M155.5,212.12c-1.26-3.79-3-8.12-8.48-8.02-3.46.06-5.68,2.54-5.62,5.88.05,3.28,2.29,5.21,7.58,7.74,6.43,3.05,10.53,6.08,10.63,11.98.12,7.03-5.64,12.25-14.16,12.4-3.81.06-7.23-.89-10.04-1.68-.38-1.36-1.5-7.54-1.96-10.34l2.31-.58c1.25,3.73,4.7,10.17,10.54,10.08,3.63-.06,5.8-2.36,5.74-6.18-.06-3.46-2.24-5.45-7.29-8.04-6.25-3.23-10.35-6.15-10.46-12.34-.1-6.26,4.7-11.28,13.64-11.43,4.11-.07,7.23.77,8.54.93.27,2.2.74,5.29,1.28,9.04l-2.26.57Z", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M224.1,221.11c-4.19-.75-5.18-.14-8.18,3.97-2.8,3.86-11.13,15.84-18.21,26.17l-3.14-.98c-.35-8.96-.97-18.24-1.55-27.64-5.54,7.56-10.89,14.87-16.05,22.17l-3.25-1.01c.21-8.6.23-21.31.18-32.2-.08-4.69-.62-5.91-3.94-7.31l.64-2.07,16.92,5.26-.64,2.07c-4.32-.91-4.7-.11-4.82,3.05-.15,7.02-.23,14.18-.28,20.87,5.31-7.01,10.63-14.64,15.91-22.52l2.97.92c.42,9.53,1.02,18.87,1.55,28.01,3.96-5.41,9.55-13.93,11.33-16.88,1.81-3.06,1.57-4.25-3.13-6.14l.64-2.07,13.67,4.25-.64,2.07Z", style: {
  fill: "#000",
  strokeWidth: 0
} }))));
const ForwardRef = forwardRef(SvgNswCircle);
export default ForwardRef;
