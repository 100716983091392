import * as React from "react";
import { forwardRef } from "react";
const SvgVic = (props, ref) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", "data-name": "Layer 1", xmlns: "http://www.w3.org/2000/svg", width: 298.07, height: 298.07, viewBox: "0 0 298.07 298.07", ref, ...props }, /* @__PURE__ */ React.createElement("rect", { width: 298.07, height: 298.07, style: {
  fill: "none",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M54.69,236.26s10.88-33.87,49.66-86.7c0,0,20.42-77.47,30.65-124.61,0,0-2.2-5.61,4.35-16.43,0,0,5.01,5.5-.89,16.87,0,0-17.65,59.73-29.1,119.66,0,0,5.83,12.25,26.82-4.9,0,0,9.49-9.67,19.66-26.93,11.37-19.3,27.31-45.15,35.78-65.5,0,0-.98-3.96,3.94-7.58,4.92-3.63,3.62-2.35,3.62-2.35,0,0,.69,5.79-4.31,10.64,0,0-41.83,82.61-56.54,94.79-12.02,9.95-17.63,9.83-17.63,9.83,0,0,43.94,23.19,115.12-29.31,0,0,4.93-5.67,11.13-6.15,0,0-1.25,4.82-8.55,8.01,0,0-73.94,55.81-123.91,29.28,0,0-4.33-1.27-5.78-.6s-4.29,4.4-4.29,4.4c0,0-23.99,28.5-43.65,88.48", style: {
  fill: "#7c6e5b",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.24px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M185.41,65.24c.55.34,14.24,4.43,22.87,2.85,0,0-6.91-5.97-22.76-4.89,0,0-2.22.74-.11,2.04Z", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".98px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M186.62,61.98c.63.14,14.95-.52,22.45-4.84,0,0-8.66-3.33-23.09,2.89,0,0-1.81,1.43.64,1.95Z", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".98px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M171.35,165.7s3-.99.86-5.11c-2.14-4.11-18.91-29.53-18.91-29.53,0,0-1.62-2.37-1.21-3.61.84-2.55,5.12-4.77,7.39-.81,0,0,17.03,25.46,19.76,28,1.4,1.3,4.19,3.16,6.87,1.01", style: {
  fill: "#ce849d",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.03px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M168.89,164.91s2.25,2.55,6.32-2.71c0,0-3.26-.41-3.22-5.18,0,0,5.05-.73,5.86,3.25,0,0-1.79-5.02.97-7.02,0,0,2.71,1.67,2.65,5.33,0,0,4.35.02,5.93-6.02,0,0,1.89,4.38-4.39,8.49,0,0,.02.3-1.2,2.09s-.68.38-.68.38c0,0-2.97,1.8-3.75.81,0,0-6.44,4.67-8.48.58Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.03px"
} })), /* @__PURE__ */ React.createElement("path", { d: "M81.78,181.36c1.07.38,3.68-16.96-30.97-3.17,0,0,10.39-4.06,30.97,3.17Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.32px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M154.11,172.98s3.02-.18,2.04-4.51c-.97-4.32-10.25-31.9-10.25-31.9,0,0-.91-2.59-.23-3.63,1.4-2.15,5.9-3.15,7.03,1.05,0,0,9.51,27.69,11.41,30.69.97,1.54,3.1,3.94,6.1,2.62", style: {
  fill: "#ce849d",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".98px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M152.03,171.65s1.46,2.91,6.5-.95c0,0-2.91-1.18-1.71-5.57,0,0,4.84.56,4.62,4.43,0,0-.43-5.08,2.61-6.25,0,0,2.09,2.2,1.15,5.58,0,0,4.01,1.08,6.95-4.11,0,0,.68,4.51-6.13,6.77,0,0-.05.29-1.62,1.64s-.72.18-.72.18c0,0-3.18.94-3.66-.17,0,0-7.09,2.74-7.98-1.54Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".98px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M136.5,154.96s2.79-1.41.33-5.15-21.05-26.41-21.05-26.41c0,0-1.78-2.11-1.51-3.39.56-2.63,4.46-5.43,7.02-1.85,0,0,18.85,22.67,21.71,24.78,1.47,1.08,4.34,2.52,6.7.02", style: {
  fill: "#ce849d",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.01px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M134.07,154.52s2.41,2.19,5.81-3.56c0,0-3.17.06-3.6-4.64,0,0,4.78-1.44,5.94,2.37,0,0-2.21-4.69.24-7.05,0,0,2.76,1.26,3.07,4.88,0,0,4.18-.6,5.11-6.77,0,0,2.25,4.05-3.39,8.99,0,0,.05.3-.95,2.23-1,1.93-.62.47-.62.47,0,0-2.67,2.19-3.52,1.33,0,0-5.73,5.51-8.09,1.77Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.01px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M185.64,155.71s2.72-1.85-.74-5.42c-3.46-3.57-28.42-24.64-28.42-24.64,0,0-2.38-1.95-2.36-3.32.05-2.82,3.68-6.28,7.22-2.9,0,0,25.24,21.04,28.79,22.85,1.82.93,5.23,2.03,7.26-.9", style: {
  fill: "#ce849d",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.09px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M182.91,155.6s3.08,1.95,5.54-4.52c0,0-3.43.5-4.89-4.35,0,0,4.87-2.16,6.95,1.65,0,0-3.39-4.59-1.23-7.4,0,0,3.26.93,4.36,4.67,0,0,4.4-1.2,4.1-7.78,0,0,3.3,3.92-1.77,9.85,0,0,.12.3-.56,2.46s-.57.57-.57.57c0,0-2.44,2.66-3.54,1.87,0,0-5.04,6.54-8.4,2.97Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.09px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M150.12,102.79s2.69-1.42-.08-5.02-23.25-25.25-23.25-25.25c0,0-1.96-2.01-1.79-3.26.35-2.57,4.05-5.37,6.91-1.94,0,0,20.74,21.66,23.79,23.65,1.56,1.02,4.56,2.36,6.73-.11", style: {
  fill: "#ce849d",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M147.63,102.42s2.6,2.08,5.56-3.58c0,0-3.18.12-3.99-4.44,0,0,4.69-1.5,6.16,2.18,0,0-2.59-4.52-.31-6.86,0,0,2.88,1.17,3.47,4.68,0,0,4.15-.67,4.6-6.69,0,0,2.58,3.89-2.69,8.81,0,0,.07.29-.78,2.19s-.58.47-.58.47c0,0-2.51,2.19-3.44,1.36,0,0-5.32,5.48-7.99,1.89Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: 1
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M190.66,137.92s3.49-1.03,1.2-5.83-20.49-34.62-20.49-34.62c0,0-1.76-2.79-1.24-4.19,1.07-2.89,6.09-5.29,8.53-.66,0,0,18.5,29.88,21.53,32.9,1.55,1.55,4.68,3.79,7.85,1.42", style: {
  fill: "#ce849d",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.18px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M187.87,136.92s2.48,3.01,7.38-2.87c0,0-3.73-.6-3.49-6.07,0,0,5.83-.66,6.59,3.95,0,0-1.85-5.83,1.41-8.03,0,0,3.04,2.02,2.82,6.23,0,0,5,.18,7.06-6.69,0,0,1.99,5.11-5.4,9.59,0,0,.01.35-1.47,2.35s-.8.41-.8.41c0,0-3.48,1.96-4.34.79,0,0-7.59,5.12-9.77.35Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.18px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M205.77,118.12s2.7-1.78-.63-5.33-27.45-24.61-27.45-24.61c0,0-2.3-1.95-2.26-3.3.1-2.77,3.72-6.1,7.13-2.74,0,0,24.4,21.04,27.86,22.86,1.77.94,5.1,2.07,7.14-.77", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.06px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M203.09,117.97s2.99,1.96,5.52-4.35c0,0-3.37.44-4.72-4.34,0,0,4.82-2.05,6.79,1.72,0,0-3.25-4.56-1.08-7.27,0,0,3.18.96,4.2,4.65,0,0,4.34-1.11,4.16-7.56,0,0,3.17,3.89-1.91,9.64,0,0,.11.3-.59,2.4-.7,2.1-.57.56-.57.56,0,0-2.44,2.57-3.51,1.78,0,0-5.06,6.34-8.29,2.78Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.06px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M217.98,93.03s1.66-2.31-2.34-4.28c-4-1.97-31.31-12.32-31.31-12.32,0,0-2.59-.94-3.01-2.08-.86-2.35,1.03-6.25,5.03-4.46,0,0,27.55,10.24,31.05,10.72,1.8.25,4.97.19,5.71-2.82", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".96px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M215.69,93.71s3.17.74,3.13-5.33c0,0-2.67,1.39-5.42-2.21,0,0,3.33-3.18,6.26-.62,0,0-4.26-2.84-3.37-5.78,0,0,2.99-.16,5.09,2.62,0,0,3.25-2.25.91-7.61,0,0,3.97,2.3,1.68,8.67,0,0,.19.22.32,2.2s-.29.64-.29.64c0,0-1.16,2.9-2.32,2.56,0,0-2.07,6.86-5.98,4.86Z", style: {
  fill: "#ce849d",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".96px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M205.07,182.83s2.44-.7.88-4.06c-1.56-3.36-14.04-24.24-14.04-24.24,0,0-1.21-1.95-.83-2.93.77-2.01,4.27-3.65,5.94-.41,0,0,12.69,20.93,14.78,23.04,1.07,1.09,3.24,2.67,5.46,1.04", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".82px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M203.14,182.11s1.71,2.11,5.16-1.96c0,0-2.59-.44-2.39-4.25,0,0,4.07-.42,4.57,2.79,0,0-1.25-4.07,1.03-5.58,0,0,2.1,1.42,1.93,4.35,0,0,3.48.16,4.97-4.62,0,0,1.35,3.57-3.83,6.65,0,0,0,.24-1.04,1.63s-.56.28-.56.28c0,0-2.44,1.34-3.03.52,0,0-5.32,3.52-6.81.18Z", style: {
  fill: "#ce849d",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".82px"
} })), /* @__PURE__ */ React.createElement("path", { d: "M197.22,150.04c.21.7,9.16,14.48,18.24,19.46,0,0-1.29-10.44-16.45-21.19,0,0-2.61-.98-1.79,1.74Z", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.17px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M201,148.02c.46.59,13.9,10.59,24.23,12.28,0,0-5.04-9.38-23.19-14.5,0,0-2.81-.06-1.03,2.22Z", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.17px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M215.79,140.3c.3.3,8.61,5,14.54,5.24,0,0-3.55-4.93-14.13-6.56,0,0-1.58.16-.41,1.32Z", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".66px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M152.84,133.01s1.81-2.31-2.21-4.49-31.63-13.89-31.63-13.89c0,0-2.62-1.07-3-2.26-.78-2.45,1.34-6.38,5.37-4.39,0,0,27.85,11.61,31.44,12.25,1.84.33,5.1.39,5.99-2.67", style: {
  fill: "#ce849d",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".98px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M150.45,133.62s3.22.89,3.46-5.35c0,0-2.8,1.32-5.47-2.48,0,0,3.57-3.14,6.46-.38,0,0-4.25-3.08-3.21-6.07,0,0,3.08-.04,5.11,2.9,0,0,3.44-2.19,1.27-7.78,0,0,3.98,2.52,1.34,8.97,0,0,.19.23.23,2.27.04,2.04-.32.65-.32.65,0,0-1.32,2.93-2.5,2.54,0,0-2.44,6.96-6.36,4.75Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".98px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M164.12,82.23s1.63-2.27-2.3-4.2c-3.92-1.93-30.74-12.09-30.74-12.09,0,0-2.54-.92-2.95-2.04-.84-2.31,1.02-6.14,4.94-4.38,0,0,27.04,10.05,30.48,10.53,1.77.24,4.88.18,5.6-2.77", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".94px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M161.87,82.9s3.11.73,3.08-5.23c0,0-2.62,1.36-5.32-2.17,0,0,3.27-3.12,6.14-.6,0,0-4.18-2.78-3.31-5.67,0,0,2.93-.16,5,2.57,0,0,3.19-2.21.89-7.47,0,0,3.9,2.26,1.65,8.51,0,0,.19.21.32,2.15s-.28.63-.28.63c0,0-1.14,2.84-2.28,2.52,0,0-2.04,6.73-5.87,4.77Z", style: {
  fill: "#ce849d",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".94px"
} })), /* @__PURE__ */ React.createElement("path", { d: "M126.97,60.47c-.31.54-10.25,10.25-18.64,12.73,0,0,3.03-8.33,17.5-14.47,0,0,2.33-.34,1.14,1.74Z", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".98px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M130.99,54.96c.82-.24,19.68-.78,30.05,4.15,0,0-10.95,5.22-30.65-1.53,0,0-2.55-1.69.6-2.62Z", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.29px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M134.69,40.06c.57-.15,13.75-.14,20.87,3.51,0,0-7.76,3.42-21.35-1.69,0,0-1.74-1.23.48-1.82Z", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".9px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M113.39,128.99c.36.27,6.03,8.04,6.43,13.81,0,0-5.85-3.1-7.97-13.31,0,0,.15-1.56,1.54-.5Z", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".71px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M93.21,172.42c-1.09-.33,8.03-15.55,26.51,15.57,0,0-5.59-9.27-26.51-15.57Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.32px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M112.05,120.7c.79.48,6.49-12.85-24.54-7.82,0,0,9.29-1.45,24.54,7.82Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.09px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M95.65,163.78c.86.33,11.88-6.53-28.71-6.2,0,0,12.13-.07,28.71,6.2Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".92px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M104.42,142.36c.86.33,11.88-6.53-28.71-6.2,0,0,12.13-.07,28.71,6.2Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".92px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M118.94,91.11c.8.17-1.46-12.65-21.44.63,0,0,6-3.93,21.44-.63Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".94px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M151.45,161.91c-.53-.78,15.47-3.98,8.1,23.22,0,0,2.14-8.15-8.1-23.22Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.13px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M113.33,154.07c-.78-.89,20.13-3,12.91,25.83,0,0,2.07-8.64-12.91-25.83Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.35px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M127.97,52.3c.55.21,7.54-4.14-18.23-3.94,0,0,7.7-.04,18.23,3.94Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".59px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M132.22,32.54c.19.53,8.24,2.81-8.6-15.71,0,0,5.02,5.55,8.6,15.71Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".59px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M132.28,39.07c.4.3,2.81-7.49-12.3-5.15,0,0,4.52-.67,12.3,5.15Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".59px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M185,59.02c-.01.71,11.93-1.11-4.63-18.94,0,0,4.92,5.36,4.63,18.94Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".87px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M231.36,127.62c-.22.55,10.09.95,1.88-15.46,0,0,2.42,4.92-1.88,15.46Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".72px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M227.59,130.13c-.59-.05-5.95,6.02,18.63-1.18,0,0-7.35,2.13-18.63,1.18Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".59px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M5.78,233.5c13.33-7.42,149.98-137.81,287.82,7.52l-43.73,1.47-2.53,46.54s-85.43-102.28-202.38,0l9.36-49.11-48.54-6.41Z", style: {
  fill: "#f9cd5f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "3.04px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M129.71,191.25c-3.96,1.53-5.05,2.42-5.81,8.93-.63,5.27-2.87,22.32-4.31,35.17l-3.4.92c-3.03-4.35-16.78-23.38-21.14-29.24-3.17-4.12-4.24-4.89-8.35-4.18l-.62-2.29,18.85-5.08.55,2.3c-4.34,1.83-4.23,2.27-2.54,4.6,3.32,4.67,11.38,16.15,14.95,21.03,1.33-9.84,2.4-19.14,2.73-23.54.4-4.68-.29-5.49-5.63-4.65l-.62-2.29,14.71-3.97.62,2.29Z", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M140.32,230.69l-.02-2.37c4.86-.5,5.43-.89,5.38-6.26l-.25-24.38c-.05-5.38-.7-5.88-5.5-6.22l-.02-2.37,19.77-.2.02,2.37c-4.8.5-5.43.95-5.38,6.33l.25,24.38c.05,5.31.63,5.69,5.5,6.15l.02,2.37-19.77.2Z", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M204.42,228.44c-1.63,2.89-4.41,7.22-5.94,9.15-1.71-.02-7.11-.04-11.83-1.2-16.72-4.11-20.97-16.56-18.54-26.44,3.17-12.86,15.12-19.15,30.04-15.48,5.41,1.33,9.87,3.81,11.38,4.38-.41,3-1.1,7.7-1.34,10.54l-2.59-.18c-.11-5.17-1.41-7.93-4.38-10.04-1.34-.99-3.51-1.79-5.19-2.2-9.01-2.22-15.4,4.19-17.8,13.94-2.8,11.37,1.15,20.52,9.66,22.61,5.22,1.28,9.37-.59,14.41-6.6l2.13,1.51Z", style: {
  fill: "#000",
  strokeWidth: 0
} }))));
const ForwardRef = forwardRef(SvgVic);
export default ForwardRef;
