import * as React from "react";
import { forwardRef } from "react";
const SvgRedCross = (props, ref) => /* @__PURE__ */ React.createElement("svg", { width: "100%", height: "100%", viewBox: "0 0 299 299", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlSpace: "preserve", "xmlns:serif": "http://www.serif.com/", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeMiterlimit: 10
}, ref, ...props }, /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 298.07, height: 298.07, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("g", { id: "Plus" }, /* @__PURE__ */ React.createElement("circle", { cx: 149.35, cy: 150.25, r: 95.55, style: {
  fill: "#cd2735",
  stroke: "#000",
  strokeWidth: "3.54px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M209.296,187.642l-37.392,-37.392l37.392,-37.392l-22.554,-22.554l-37.392,37.392l-37.392,-37.392l-22.554,22.554l37.392,37.392l-37.392,37.392l22.554,22.554l37.392,-37.392l37.392,37.392l22.554,-22.554Z", style: {
  fill: "#fff",
  fillRule: "nonzero",
  stroke: "#231f20",
  strokeWidth: "3.54px"
} })));
const ForwardRef = forwardRef(SvgRedCross);
export default ForwardRef;
