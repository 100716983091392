import * as React from "react";
import { forwardRef } from "react";
const SvgQldCircle = (props, ref) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", "data-name": "Layer 1", xmlns: "http://www.w3.org/2000/svg", width: 298.07, height: 298.07, viewBox: "0 0 298.07 298.07", ref, ...props }, /* @__PURE__ */ React.createElement("rect", { width: 298.07, height: 298.07, style: {
  fill: "none",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("circle", { cx: 150.88, cy: 125.32, r: 120.18, style: {
  fill: "#ccc7e0",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "6.63px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M210.17,66.21s1.59-4.1,1.81-8.69-.86-7.58-.9-11.85c-.05-4.27,2.19-2.56,3.37,0s9.61,12.02,6.94,14.5c0,0-1.71,2.16-4.27-2.11-2.56-4.27-5.66,8.76-5.66,8.76l-1.28-.6Z", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".61px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M201.72,67.48s.33-4.38-.79-8.84-3.02-7.01-4.31-11.08c-1.29-4.07,1.35-3.09,3.22-.98,1.87,2.11,12.69,8.71,10.86,11.86,0,0-1.01,2.56-4.7-.78s-2.87,10.02-2.87,10.02l-1.4-.2Z", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".61px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M191.75,68.99s-.56-4.36-2.56-8.5-4.37-6.25-6.45-9.99.71-3.3,2.96-1.61,14.18,5.98,13.02,9.43c0,0-.47,2.71-4.76.18s-.79,10.4-.79,10.4l-1.42.08Z", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".61px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M90.21,194.94s20.13-38.48,48.85-58.27c.41-.28.82-.56,1.24-.84,29.37-19.51-20.74,12.43-20.74,12.43,0,0-32.55,33.89-29.34,46.68Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".61px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M122.2,193.59s20.94-63.31,57.8-84.81c0,0-50.76,32.53-60.45,83.15l2.65,1.67Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".61px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M141.82,132.78c1.9.74,16.9-72.43,70.93-66.34,0,0,2.12,3.75-2.25,2.25,0,0-27.64-3.39-47.12,25.51,0,0-15.27,19.06-18.89,39.62,0,0-5.33-2.08-2.66-1.04Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".61px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M121.03,209.06s-.27-66.68-28.65-98.56c0,0,38.17,46.66,31.69,97.79l-3.03.77Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".61px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M131.1,183.38s-3.21-.55,10.11-15.57c5.39-6.08,38.52-36.61,104.63-9.02,0,0-90.23,8.2-114.74,24.59Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".86px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M130.41,183.46s34.7-44.8,114.74-24.59c0,0-81.96-38.25-114.74,24.59Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".86px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M141.28,138.63s-2.24-.54,7.9-10.51c4.1-4.03,28.97-23.98,74.32-1.29,0,0-64.11,1.41-82.22,11.8Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".61px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M140.8,138.65s26.67-29.96,82.22-11.8c0,0-56.02-30.98-82.22,11.8Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".61px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M169.47,106.86s-6.08-18.62-19.16-28.76c-13.08-10.14-18.4-42.39-33.19-47.71,0,0,30.06-1.99,46.2,41.39", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".61px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M169.47,106.86s-10.13-64.66-52.35-76.46c0,0,52.34,3.16,52.35,76.46Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".61px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M128.45,123.95s-4.96-15.21-15.65-23.49c-10.69-8.28-15.03-34.63-27.12-38.97,0,0,24.56-1.63,37.74,33.81", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".5px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M128.45,123.95s-8.27-52.82-42.76-62.46c0,0,42.76,2.58,42.76,62.46Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".5px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M102.51,186.4s2.16,1.53,3.13-14.73c.39-6.58-1.09-43.1-54.85-65.19,0,0,47.06,56.46,51.72,79.93Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".7px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M102.86,186.84s5.8-45.61-51.72-79.93c0,0,68.96,25.08,51.72,79.93Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".7px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M174.58,84.82s4.8-9.45,3.67-18.34c-1.13-8.89,9.18-23.26,5.55-30.96,0,0,12.27,10.74,1.84,33.52", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".33px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M174.58,84.82s20.87-28.62,9.23-49.3c0,0,18.82,21.24-9.23,49.3Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".33px"
} }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M142.18,110.97s-2.66-7.22-2.28-12.91c0,0,6.08.19,11.2,8.74,0,0,3.23-9.5,12.15-4.56,8.93,4.94,21.08,22.03,9.87,29.81,0,0,4.94,3.23,6.08,5.7,0,0-14.24,2.28-22.79-3.04,0,0-1.33,12.91-11.96,18.8,0,0-2.4-7.67-1.9-10.82,0,0-11.01,4.75-14.43-5.51-3.42-10.25-.95-33.8,14.05-26.21Z", style: {
  fill: "#ce849d",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: 1
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M154.43,128.94s-10.25-13.01-10.16-24.02c0,0,10.06,8.07,10.16,24.02Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".85px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M156.66,130.75s-2.24-26.3,11.63-16.9c0,0-10.6,5.97-11.63,16.9Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".94px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M157.98,131.26s8.78-.38,13.75,4.02c0,0-10.29.46-13.75-4.02Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".8px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M151.44,132.27s.48,9.38-4.17,14.72c0,0-.58-10.98,4.17-14.72Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".85px"
} })), /* @__PURE__ */ React.createElement("path", { d: "M151.61,129.02s-21.45-15.39-20.47,1.34c0,0,10.55-6.05,20.47-1.34Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".94px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M163.76,135.79c1.24,7.77-4.84,11.55-9.69,4.6-6.39-9.16-4.36-17.6-4.36-17.6,0,0,1.27-3.38,4.68-1.32,2.72,1.65,8.8,10.76,9.37,14.32Z", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".85px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M145.96,127.81s1.04-3.13,3.23-2.18,6.27-1.14,6.27-1.9.19-3.42,2.85-1.61,1.86,6.21,0,6.22,1.71,8.41,1.71,8.41c0,0-.79,4.23-3.35,1.32s-3.28-8.67-5.76-8.07-4.31,1.96-4.94-2.18Z", style: {
  fill: "#a33d5f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".94px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M184.17,80.23s-.54-6.82,1.15-11.61c0,0,5.16,1.62,7.5,10.18,0,0,5.05-7.36,11.51-.98s12.76,23.93,1.29,27.91c0,0,3.45,3.95,3.84,6.34,0,0-12.75-1.47-18.79-8.08,0,0-4.24,10.74-14.77,13.23,0,0-.21-7.15.97-9.73,0,0-10.58,1.42-11.04-8.19-.46-9.61,7.31-29.18,18.33-19.07Z", style: {
  fill: "#ce849d",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".89px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M190.34,98.57s-5.66-13.61-2.93-23.02c0,0,6.68,9.33,2.93,23.02Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".76px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M191.82,100.65s4.4-23.07,14.02-11.68c0,0-10.51,2.56-14.02,11.68Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".83px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M192.83,101.4s7.61,1.79,10.81,6.74c0,0-8.92-2.08-10.81-6.74Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".71px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M186.98,100.7s-1.84,8.15-7.11,11.61c0,0,2.14-9.55,7.11-11.61Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".76px"
} })), /* @__PURE__ */ React.createElement("path", { d: "M187.91,97.96s-14.68-18.33-17.85-3.78c0,0,10.49-2.65,17.85,3.78Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".83px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M196.69,106.68c-.81,6.95-6.92,8.73-9.4,1.61-3.27-9.38.5-16.12.5-16.12,0,0,1.9-2.59,4.33,0,1.94,2.06,4.95,11.33,4.58,14.52Z", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".76px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M183.37,95.56s1.65-2.43,3.29-1.09,5.64.53,5.82-.12.98-2.88,2.83-.7.1,5.77-1.49,5.33-.56,7.61-.56,7.61c0,0-1.69,3.43-3.19.33s-.73-8.21-3-8.3-4.16.65-3.7-3.06Z", style: {
  fill: "#a33d5f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".83px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M108.63,112.91s4.54-7.46,9.88-11.15c0,0,4.12,5.54.14,16.14,0,0,10.73-3.84,12.64,7.59,1.91,11.43-4.71,34.31-19.55,29.85,0,0,.62,6.67-.78,9.43,0,0-12.09-11.06-13.39-22.42,0,0-12.43,7.94-25.18,2.64,0,0,5.13-7.55,8.29-9.34,0,0-12-6.45-5.3-16.73,6.71-10.29,29.4-24.72,33.24-6.01Z", style: {
  fill: "#ce849d",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.14px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M101.28,136.51s4.33-18.31,14.2-26.01c0,0-.07,14.66-14.2,26.01Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".97px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M101.26,139.77s21.82-20.57,23.25-1.6c0,0-12.8-5.22-23.25,1.6Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.06px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M101.74,141.3s6.54,7.54,6.14,15.07c0,0-7.68-8.82-6.14-15.07Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".9px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M96.21,136.2s-8,7.05-16.04,6.69c0,0,9.36-8.28,16.04-6.69Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".97px"
} })), /* @__PURE__ */ React.createElement("path", { d: "M99.22,134.05s-1.46-29.95-15.65-17.27c0,0,12.84,5.11,15.65,17.27Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.06px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M101.78,149.65c-6.04,6.59-13.69,3.85-10.94-5.37,3.63-12.15,12.58-16.31,12.58-16.31,0,0,3.91-1.26,4.48,3.23.46,3.58-3.36,15.43-6.12,18.45Z", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".97px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M96.32,128.17s3.53-1.28,4.22,1.33c.7,2.61,5.44,4.77,6.12,4.23s3.18-2.25,3.45,1.39-4.21,6.04-5.53,4.39-6.27,7.46-6.27,7.46c0,0-4.32,2.28-3.55-2.05.77-4.33,5.39-9.04,3.11-10.83-2.28-1.78-4.79-2.45-1.54-5.94Z", style: {
  fill: "#a33d5f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.06px"
} }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M6.71,237.25c13.33-7.42,149.98-137.81,287.82,7.52l-43.73,1.47-2.53,46.54s-85.43-102.28-202.38,0l9.36-49.11-48.54-6.41Z", style: {
  fill: "#f9cd5f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "3.04px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M103.13,196.96c11.44-3.06,23.01,2.79,26.27,14.97,3.52,13.17-3.66,23.97-15.34,27.09-12.37,3.31-23.75-3.32-26.94-15.25-2.83-10.57,1.92-23.04,16.02-26.81ZM102.93,199.93c-6.31,1.69-9.29,9.11-6.36,20.05,2.79,10.45,9.34,18.31,17.68,16.07,5.87-1.57,8.93-8.48,5.9-19.8-3.27-12.24-10.05-18.25-17.22-16.33ZM116.76,233.06c9.54,3.94,17.06,6.57,22.1,7.07l-.06,2c-2.72.99-7.39,2.11-9.98,2.07-5.79-.11-13.62-3.38-19.93-6.13l7.87-5.02Z", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M171.09,223.3c-.61,2.69-2.63,9.29-3.27,10.97l-31.23-1.23.09-2.33c5.56-.16,6.08-.58,6.28-5.87l.94-23.99c.21-5.29-.4-5.82-5.11-6.38l.09-2.33,19.64.77-.09,2.33c-4.93.25-5.58.66-5.78,5.95l-.96,24.43c-.14,3.46-.07,5.04,3.39,5.24.12.07,3.02.18,3.27.19,3.59.14,4.56-.58,6.14-2.22,1.25-1.21,2.99-3.86,4.22-6.39l2.36.85Z", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M203.6,200.97c7.75,2.65,12.89,6.23,15.52,11.19,2.12,3.9,3.03,8.95.94,15.06-2.38,6.96-7.11,11.3-12.97,13.22-5.64,1.86-11.97,1.59-18.88-.77l-16.65-5.69.77-2.24c4.73,1.21,5.46.85,7.17-4.18l7.86-23.01c1.74-5.09,1.24-5.8-3.12-7.69l.76-2.24,18.59,6.35ZM188.06,230.02c-1.47,4.3-.88,6.13,3.54,7.64,8.18,2.79,14.84-1.29,18.76-12.73,1.97-5.75,2.11-11.32.21-14.87-1.67-3.41-4.53-5.34-8.59-6.72-2.24-.77-3.44-.63-3.92-.39-.73.36-1.2,1.35-1.8,3.1l-8.19,23.98Z", style: {
  fill: "#000",
  strokeWidth: 0
} }))));
const ForwardRef = forwardRef(SvgQldCircle);
export default ForwardRef;
