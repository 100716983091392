import * as React from "react";
import { forwardRef } from "react";
const SvgCakesausageSoldout = (props, ref) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", "data-name": "Layer 1", xmlns: "http://www.w3.org/2000/svg", width: 298.07, height: 298.07, viewBox: "0 0 298.07 298.07", ref, ...props }, /* @__PURE__ */ React.createElement("rect", { width: 298.07, height: 298.07, style: {
  fill: "none",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("g", { id: "Cupcake" }, /* @__PURE__ */ React.createElement("path", { d: "M213.2,61.78c5.6,6.71,17.32,18.7,36.07,24.74,6.51,2.1,12.54,3.05,17.66,3.43-4.22,3.32-19.62,14.58-40.99,13.19-10.4-.68-18.47-4.1-23.39-6.72", style: {
  fill: "#ede8e4",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "2.16px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M293.08,148.12s-2.15-27.64-17.82-35.23c0,0-36.87,24.97-73.75,35.23h91.57Z", style: {
  fill: "#f9cd5f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "2.16px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M123.61,146.97s-5.33-22.07,21.33-27.25c26.66-5.18,70.52-1.29,98.87-12.08,28.35-10.78,27.38-15.31,27.38-15.31,0,0,10.66,7.76,9.94,19.63s-13.09,16.39-13.09,16.39l-49.92,18.62h-94.51Z", style: {
  fill: "#fff",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.51px"
} }), /* @__PURE__ */ React.createElement("g", { id: "Cupcake_col", "data-name": "Cupcake col" }, /* @__PURE__ */ React.createElement("path", { d: "M255.23,240.91l17.41-92.79,20.06,1.02c-11.55,36.42-22.65,88.48-30.14,90.97", style: {
  fill: "#6230b0",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("polyline", { points: "155.4 240.91 136.89 147.25 160.47 146.97 168.84 240.91", style: {
  fill: "#6230b0",
  strokeWidth: 0
} })), /* @__PURE__ */ React.createElement("ellipse", { cx: 206.13, cy: 75.21, rx: 21.66, ry: 21.2, style: {
  fill: "#cc2936",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "2.16px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M150.22,118.63s-5.32-10.21,0-19.81c5.32-9.6,24.99-18.78,34.93-20.01,0,0,1.39,4.08,14.58,9.19s64.55,1.02,64.55,1.02c0,0,6.94-1.23,6.48,3.68s-37.25,33.89-120.53,25.93Z", style: {
  fill: "#fff",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.51px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M293.14,147.13c0-5.8-4.5-10.5-10.06-10.5s-9.73,4.37-10.04,9.87h-.02s-10.45,93.86-10.45,93.86c7.5-2.49,19.14-56.76,30.69-93.18", style: {
  fill: "#6230b0",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M252.01,147.45c-.28-6.38-5.54-11.48-12-11.48s-11.72,5.09-12,11.48h-.22l-2.09,93.69h16.75l9.7-93.69h-.14Z", style: {
  fill: "#6230b0",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M205.14,147.49h-.56s.53,0,.53,0c0-6.63-6.16-12.61-12.74-12.61s-10.92,5.84-11.02,12.38h-.16s3.74,93.65,3.74,93.65h20.33l-.13-93.42Z", style: {
  fill: "#6230b0",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M136.77,147.52c0-6.63,5.26-12.01,11.75-12.01s11.75,5.38,11.75,12.01", style: {
  fill: "#6230b0",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M137.39,147.31h-.13c-.14-6.54-4.42-11.79-9.68-11.79s-9.69,5.42-9.69,12.12h.21c1.27,5.05,20.4,85.63,28.69,91.6l9.11,1.73-18.51-93.65Z", style: {
  fill: "#a197c6",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M181.32,146.43h-.29c-.39-5.96-4.82-10.67-10.24-10.67s-9.85,4.7-10.24,10.67h-.29l8.58,94.48,16.09.48-3.62-94.96Z", style: {
  fill: "#a197c6",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M216.4,135.92c-6.22,0-11.26,5.15-11.26,11.51l.13,93.74h20.44l2.3-93.7c0-6.36-5.38-11.54-11.6-11.54Z", style: {
  fill: "#a197c6",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M273.18,147.49c0-6.36-4.74-11.51-10.58-11.51s-10.58,5.15-10.58,11.51h.1l-9.67,93.42h12.78l17.73-93.42h.22Z", style: {
  fill: "#a197c6",
  strokeWidth: 0
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { points: "135.81 147.3 155.9 240.97 137.97 147.3 135.81 147.3", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("polygon", { points: "159.33 147.25 168.84 240.91 161.49 147.25 159.33 147.25", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("polygon", { points: "180.12 147.25 184.94 241.39 182.25 147.17 180.12 147.25", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("polygon", { points: "204.18 147.08 205.33 241.14 206.35 147.11 204.18 147.08", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("polygon", { points: "226.69 147.25 225.7 241.14 228.85 147.25 226.69 147.25", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("polygon", { points: "251.07 147.14 242.45 240.91 253.22 147.22 251.07 147.14", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("polygon", { points: "272.25 147.1 255.23 240.91 274.39 147.22 272.25 147.1", style: {
  fill: "#000",
  strokeWidth: 0
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M227.79,147.22c0-6.56,5.45-11.87,12.18-11.87s12.18,5.31,12.18,11.87", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "2.16px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M181.19,147c0-6.44,5.39-11.65,12.04-11.65s12.04,5.22,12.04,11.65", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "2.16px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M136.89,147.25c0-6.57,5.26-11.9,11.75-11.9s11.75,5.33,11.75,11.9", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "2.16px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M273.31,147.22c0-5.9,4.47-10.69,9.98-10.69s9.98,4.79,9.98,10.69v.03s-25.23,86.26-25.23,86.26c0,0-1.6,5.5-6.71,7.66h-109.1s-4.78-.34-8.27-4.94c-3.35-4.4-25.3-80.65-26.45-88.39l.02-.53c0-6.63,4.34-12.01,9.69-12.01s9.69,5.38,9.69,12.01", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "2.16px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M160.39,147.25c0-6.44,4.66-11.65,10.4-11.65s10.4,5.22,10.4,11.65", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "2.16px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M205.27,147.22c0-6.3,5.04-11.41,11.26-11.41s11.26,5.11,11.26,11.41", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "2.16px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M252.15,147.22c0-6.3,4.74-11.41,10.58-11.41s10.58,5.11,10.58,11.41", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "2.16px"
} }))), /* @__PURE__ */ React.createElement("g", { id: "Snausage" }, /* @__PURE__ */ React.createElement("path", { d: "M24.9,84.83s-13.4,11.56-4.81,33.32c8.59,21.76,61.92,101.93,147.41,133.98,0,0,31.84,10.49,44.19-7.74,0,0,10.8-13.57,2.31-25.59-6.85-11.65-16.29-18.58-34.47-28.26-18.17-9.68-103.92-83.2-117.38-101.18-20.68-20.84-37.24-4.53-37.24-4.53Z", style: {
  fill: "#ff9000",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "2.16px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M205.49,237.69s11.86-11.91,35.76,1.99c0,0-8.45,9.57-27.67,6.14,0,0,11.68,3.07,19.23,13.54,0,0-7.55,4.33-23-9.39,0,0,7.91,6.14,4.49,17.51,0,0-12.76-6.32-12.94-24.19", style: {
  fill: "#ff9000",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "2.16px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M26.18,85.46s-12.1,4.42-22.48-8.2c0,0,9.02-3.76,20.23,1.99,0,0-6.45-4.05-7.48-11.49,0,0,6.5-.97,11.46,9.88,0,0-2.87-5.05,3.55-10.83,0,0,5.95,6.11-.54,16.29", style: {
  fill: "#ff9000",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "2.16px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M214.52,209.74s1.04,8.61-5.88,2.58c-5.18-4.52-10.3-.13-13.64-4.1-3.05-3.62.19-7.64,4.32-8.08,2.91-.31,13.48,0,15.19,9.61Z", style: {
  fill: "#f9cd5f",
  stroke: "#231f20",
  strokeMiterlimit: 10,
  strokeWidth: "1.51px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M176.85,187.02s1.55,10.86-8.01,3.64c-7.16-5.41-14.1.49-18.73-4.35-4.22-4.41.15-9.7,5.8-10.52,3.99-.58,18.46-.84,20.94,11.24Z", style: {
  fill: "#cd2735",
  stroke: "#231f20",
  strokeMiterlimit: 10,
  strokeWidth: "1.51px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M144.81,189.42c-4.9.77-10.1,2.33-18.34-1.53-7.54-3.53-9.67-12.32-10.19-14.42-3.21-12.9,2.56-17.84-1.33-25.74-5.02-10.19-28.65-3.48-41.21-16.11-14.17-14.24-2.12-28.67-9.06-34.98-.11-.1-1.56-2.43-5.74-2-3.88.4-5.68,4.92-11.85,2.4-1.45-.59-3.09-2.46-2.2-5.11,1.61-4.79,5.28-6.12,7.8-7.01,7.5-2.64,14.9.08,21,6.97,3.77,4.26,5.05,11.64,3.68,17.67-.47,2.09-3.39,9.2,2.63,14.53,8.28,7.32,21.8,1,36.96,11.37,7.16,4.9,12.1,14.81,10.23,22.26-2.07,8.25-2.52,9-1.81,15.51.45,4.17,5.69,8.64,11.65,9.22,4.53.44,6.57-2.43,10.81-1.64,2.32.43,3.59,1.71,3.66,4.08.06,2.12-1.74,3.75-6.67,4.52Z", style: {
  fill: "#cd2735",
  stroke: "#231f20",
  strokeMiterlimit: 10,
  strokeWidth: "1.51px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M54.07,100.81c5.98-1.5,10.84-2.6,21.07.42,8.18,2.41,13.56,11.61,14.5,14.06,5.79,15.06-.09,22.02,5.68,30.91,7.45,11.48,32,1.4,50.25,12.54,15.78,9.62,6.14,35.74,16.87,41.82,2.76,1.57,3.59,1.93,13.99-2.85,1.6-.74,8.19-1.63,9.15,3.08,1.22,6.04-2.74,8.91-5.51,10.4-8.24,4.46-20.43,2.76-28.03-4.41-4.93-4.65-7.71-13.23-7.1-20.75.21-2.61,2.44-11.7-5.39-17.13-10.76-7.46-25.37,2.47-44.57-7.52-9.07-4.72-16.38-15.87-15.42-25.2,1.06-10.33,1.46-11.31-.4-19.07-1.19-4.97-7.97-9.49-14.96-9.19-5.31.23-7.21,4.04-12.23,3.8-2.75-.13-4.43-1.47-4.89-4.32-.41-2.55,1.32-5.17,6.99-6.59Z", style: {
  fill: "#f9cd5f",
  stroke: "#231f20",
  strokeMiterlimit: 10,
  strokeWidth: "1.51px"
} })), /* @__PURE__ */ React.createElement("g", { id: "Plus" }, /* @__PURE__ */ React.createElement("circle", { cx: 92.26, cy: 105.35, r: 58.3, style: {
  fill: "#f9cd5f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "2.16px"
} })), /* @__PURE__ */ React.createElement("rect", { x: 50.27, y: 95.52, width: 83.99, height: 19.67, style: {
  fill: "#fff",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "2.16px"
} }));
const ForwardRef = forwardRef(SvgCakesausageSoldout);
export default ForwardRef;
