import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { values } from 'lodash-es';
import { Coordinate } from 'ol/coordinate';
import { RootState } from '../../app/store';
import { IMapPollingPlaceGeoJSONFeatureCollection } from '../map/mapHelpers';
import { IMapFilterSettings, IPollingPlace } from '../pollingPlaces/pollingPlacesInterfaces';

export interface AppState {
	mapFilterSettings: {
		[key: number]: IMapFilterSettings;
	};
	searchBarFilterControlState: boolean;
	pollingPlaces: IMapPollingPlaceGeoJSONFeatureCollection | undefined;
	mapFeatures: IPollingPlace | null | undefined;
}

export enum ESearchDrawerSubComponent {
	SEARCH_FIELD = 1,
	REQUEST_LOCATION = 2,
	FILTER_CONTROL = 3,
}

export interface OLMapView {
	center: Coordinate;
	zoom: number;
	resolution: number;
}

export enum eMapFeaturesLoadingStatus {
	LOADING,
	SUCCEEDED,
	FAILED,
}

export const initialState: AppState = {
	mapFilterSettings: {},
	searchBarFilterControlState: false,
	pollingPlaces: undefined,
	mapFeatures: undefined,
};

// Used for storing state in localStorage pollingPlaces is excluded
// from storage and mapFeatures isn't implemented yet.
export const isAppState = (o: object): o is AppState => {
	return 'mapFilterSettings' in o && 'searchBarFilterControlState' in o;
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
// export const incrementAsync = createAsyncThunk(
//   "counter/fetchCount",
//   async (amount: number) => {
//     const response = await fetchCount(amount);
//     // The value we return becomes the `fulfilled` action payload
//     return response.data;
//   }
// );

export const appSlice = createSlice({
	name: 'app',
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {
		// increment: (state) => {
		//   // Redux Toolkit allows us to write "mutating" logic in reducers. It
		//   // doesn't actually mutate the state because it uses the Immer library,
		//   // which detects changes to a "draft state" and produces a brand new
		//   // immutable state based off those changes
		//   state.value += 1;
		// },
		// decrement: (state) => {
		//   state.value -= 1;
		// },
		// Use the PayloadAction type to declare the contents of `action.payload`
		// setActiveElectionId: (state, action: PayloadAction<number | undefined>) => {
		// 	state.electionId = action.payload;
		// },
		setMapFilterSettings: (
			state,
			action: PayloadAction<{ mapFilterSettings: IMapFilterSettings; electionId: number }>,
		) => {
			state.mapFilterSettings[action.payload.electionId] = action.payload.mapFilterSettings;
		},
		setSearchBarFilterControlState: (state, action: PayloadAction<boolean>) => {
			state.searchBarFilterControlState = action.payload;
		},
		setPollingPlaces: (state, action: PayloadAction<IMapPollingPlaceGeoJSONFeatureCollection | undefined>) => {
			state.pollingPlaces = action.payload;
		},
	},
	// The `extraReducers` field lets the slice handle actions defined elsewhere,
	// including actions generated by createAsyncThunk or in other slices.
	// extraReducers: (builder) => {
	// 	builder
	// 		// .addCase(prepareFeaturesForMap.pending, (state) => {
	// 		// 	state.mapFeatures.status = eMapFeaturesLoadingStatus.LOADING;
	// 		// })
	// 		.addCase(prepareFeaturesForMap.fulfilled, (state, action) => {
	// 			// state.mapFeatures.status = eMapFeaturesLoadingStatus.SUCCEEDED;
	// 			// if (action.payload !== undefined) {
	// 			// 	state.mapFeatures = action.payload;
	// 			// }
	// 			state.mapFeatures = action.payload;
	// 		});
	// 	// .addCase(prepareFeaturesForMap.rejected, (state) => {
	// 	// 	state.mapFeatures.status = eMapFeaturesLoadingStatus.FAILED;
	// 	// })
	// },
});

export const { setMapFilterSettings, setSearchBarFilterControlState, setPollingPlaces } = appSlice.actions;

// export const prepareFeaturesForMap = createAsyncThunk(
// 	'app/prepareFeaturesForMap',
// 	async (arg: { electionId: number; name: string; premises: string; state: string } /*, { getState }*/) => {
// 		// const state = getState() as RootState;

// 		// console.log('arg', arg);

// 		try {
// 			const response = await fetch(
// 				`${getAPIBaseURL()}/0.1/polling_places/lookup/?election_id=${arg.electionId}&name=${arg.name}&premises=${
// 					arg.premises
// 				}&state=${arg.state}`,
// 			);

// 			if (response.ok === true) {
// 				// setPollingPlace((await response.json()) as IPollingPlace);
// 				return (await response.json()) as IPollingPlace;
// 			} else {
// 				// setPollingPlace(null);
// 				return null;
// 			}
// 		} catch (error) {
// 			// setPollingPlace(null);
// 			return null;
// 		}
// 	},
// );

export const selectMapFeatures = (state: RootState) => state.app.mapFeatures;

// These selectors for the map filter settings accept electionId into the selector as an external parameter
// Ref: https://stackoverflow.com/a/61220891/7368493
export const selectMapFilterSettings = createSelector(
	[
		// First input selector extracts filter settings from the state
		(state: RootState) => state.app.mapFilterSettings,
		// Second input selector forwards the electionId argument
		(mapFilterSettings, electionId: number) => electionId,
	],
	(mapFilterSettings, electionId) => mapFilterSettings[electionId] || {},
);

export const selectIsMapFiltered = createSelector(
	[
		// First input selector extracts forwards the electionId argument to selectMapFilterSettings
		(state: RootState, electionId: number) => selectMapFilterSettings(state, electionId),
	],
	(mapFilterSettingsForElection) => values(mapFilterSettingsForElection).find((option) => option === true) || false,
);

export const selectNumberOfMapFilterSettingsApplied = createSelector(
	[
		// First input selector extracts forwards the electionId argument to selectMapFilterSettings
		(state: RootState, electionId: number) => selectMapFilterSettings(state, electionId),
	],
	(mapFilterSettingsForElection) => values(mapFilterSettingsForElection).filter((option) => option === true).length,
);

export const selectSearchBarFilterControlState = (state: RootState) => state.app.searchBarFilterControlState;

export const selectPollingPlaces = (state: RootState) => state.app.pollingPlaces;
export default appSlice.reducer;
