import * as React from "react";
import { forwardRef } from "react";
const SvgSausagePlus = (props, ref) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", "data-name": "Layer 1", xmlns: "http://www.w3.org/2000/svg", width: 298.07, height: 298.07, viewBox: "0 0 298.07 298.07", ref, ...props }, /* @__PURE__ */ React.createElement("rect", { width: 298.07, height: 298.07, style: {
  fill: "none",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("g", { id: "Snausage" }, /* @__PURE__ */ React.createElement("path", { d: "M66.19,58.29s-13.4,11.56-4.81,33.32c8.59,21.76,61.92,101.93,147.41,133.98,0,0,31.84,10.49,44.19-7.74,0,0,10.8-13.57,2.31-25.59-6.85-11.65-16.29-18.58-34.47-28.26-18.17-9.68-103.92-83.2-117.38-101.18-20.68-20.84-37.24-4.53-37.24-4.53Z", style: {
  fill: "#ff9000",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "2.16px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M246.78,211.15s11.86-11.91,35.76,1.99c0,0-8.45,9.57-27.67,6.14,0,0,11.68,3.07,19.23,13.54,0,0-7.55,4.33-23-9.39,0,0,7.91,6.14,4.49,17.51,0,0-12.76-6.32-12.94-24.19", style: {
  fill: "#ff9000",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "2.16px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M67.47,58.93s-12.1,4.42-22.48-8.2c0,0,9.02-3.76,20.23,1.99,0,0-6.45-4.05-7.48-11.49,0,0,6.5-.97,11.46,9.88,0,0-2.87-5.05,3.55-10.83,0,0,5.95,6.11-.54,16.29", style: {
  fill: "#ff9000",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "2.16px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M255.81,183.2s1.04,8.61-5.88,2.58c-5.18-4.52-10.3-.13-13.64-4.1-3.05-3.62.19-7.64,4.32-8.08,2.91-.31,13.48,0,15.19,9.61Z", style: {
  fill: "#f9cd5f",
  stroke: "#231f20",
  strokeMiterlimit: 10,
  strokeWidth: "1.51px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M218.14,160.48s1.55,10.86-8.01,3.64c-7.16-5.41-14.1.49-18.73-4.35-4.22-4.41.15-9.7,5.8-10.52,3.99-.58,18.46-.84,20.94,11.24Z", style: {
  fill: "#cd2735",
  stroke: "#231f20",
  strokeMiterlimit: 10,
  strokeWidth: "1.51px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M186.1,162.88c-4.9.77-10.1,2.33-18.34-1.53-7.54-3.53-9.67-12.32-10.19-14.42-3.21-12.9,2.56-17.84-1.33-25.74-5.02-10.19-28.65-3.48-41.21-16.11-14.17-14.24-2.12-28.67-9.06-34.98-.11-.1-1.56-2.43-5.74-2-3.88.4-5.68,4.92-11.85,2.4-1.45-.59-3.09-2.46-2.2-5.11,1.61-4.79,5.28-6.12,7.8-7.01,7.5-2.64,14.9.08,21,6.97,3.77,4.26,5.05,11.64,3.68,17.67-.47,2.09-3.39,9.2,2.63,14.53,8.28,7.32,21.8,1,36.96,11.37,7.16,4.9,12.1,14.81,10.23,22.26-2.07,8.25-2.52,9-1.81,15.51.45,4.17,5.69,8.64,11.65,9.22,4.53.44,6.57-2.43,10.81-1.64,2.32.43,3.59,1.71,3.66,4.08.06,2.12-1.74,3.75-6.67,4.52Z", style: {
  fill: "#cd2735",
  stroke: "#231f20",
  strokeMiterlimit: 10,
  strokeWidth: "1.51px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M95.36,74.27c5.98-1.5,10.84-2.6,21.07.42,8.18,2.41,13.56,11.61,14.5,14.06,5.79,15.06-.09,22.02,5.68,30.91,7.45,11.48,32,1.4,50.25,12.54,15.78,9.62,6.14,35.74,16.87,41.82,2.76,1.57,3.59,1.93,13.99-2.85,1.6-.74,8.19-1.63,9.15,3.08,1.22,6.04-2.74,8.91-5.51,10.4-8.24,4.46-20.43,2.76-28.03-4.41-4.93-4.65-7.71-13.23-7.1-20.75.21-2.61,2.44-11.7-5.39-17.13-10.76-7.46-25.37,2.47-44.57-7.52-9.07-4.72-16.38-15.87-15.42-25.2,1.06-10.33,1.46-11.31-.4-19.07-1.19-4.97-7.97-9.49-14.96-9.19-5.31.23-7.21,4.04-12.23,3.8-2.75-.13-4.43-1.47-4.89-4.32-.41-2.55,1.32-5.17,6.99-6.59Z", style: {
  fill: "#f9cd5f",
  stroke: "#231f20",
  strokeMiterlimit: 10,
  strokeWidth: "1.51px"
} })), /* @__PURE__ */ React.createElement("g", { id: "Plus" }, /* @__PURE__ */ React.createElement("circle", { cx: 101.21, cy: 187.72, r: 58.3, style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "2.16px"
} }), /* @__PURE__ */ React.createElement("polygon", { points: "143.2 177.99 110.94 177.99 110.94 145.73 91.48 145.73 91.48 177.99 59.21 177.99 59.21 197.45 91.48 197.45 91.48 229.72 110.94 229.72 110.94 197.45 143.2 197.45 143.2 177.99", style: {
  fill: "#fff",
  stroke: "#231f20",
  strokeMiterlimit: 10,
  strokeWidth: "2.16px"
} })));
const ForwardRef = forwardRef(SvgSausagePlus);
export default ForwardRef;
