import * as React from "react";
import { forwardRef } from "react";
const SvgAct = (props, ref) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", "data-name": "Layer 1", xmlns: "http://www.w3.org/2000/svg", width: 298.07, height: 298.07, viewBox: "0 0 298.07 298.07", ref, ...props }, /* @__PURE__ */ React.createElement("rect", { width: 298.07, height: 298.07, style: {
  fill: "none",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M139.32,91.74s13.35,18.51,19.31,39.02c5.96,20.51,36.75,39.11,45.9,46.1,0,0,6.06-76.14-65.21-85.12Z", style: {
  fill: "#705fd3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.05px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M144.3,97.15s38.13,20.08,59.75,20.58c21.62.49,47.36-19.78,54.07-24.08,0,0-17.57-3.66-30.42-7.97-12.85-4.31-47.92-10.97-88.79,2.42", style: {
  fill: "#705fd3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".99px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M151.36,90.42s26.01-1.24,40.72-16.43S207.68,8.39,216.24,2.67c0,0-26.14,5.65-37.09,26.02-10.95,20.36-28.24,48.61-27.57,52.93s-.22,8.81-.22,8.81Z", style: {
  fill: "#705fd3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".99px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M148.94,92.08s6.85,4.24,6.68-10.11c0,0,9.93-2.28,12.18-9.11,0,0-2.47-15.31-11.75-21.68-9.28-6.37-16.24-15.2-15.91-25.02,0,0-10.19,9.1-11.58,14.64-1.39,5.54,1.04,18.65,6.7,28.42,5.66,9.76,6.07,15.31,13.68,22.86Z", style: {
  fill: "#705fd3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".99px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M142.01,106.62s-1.58-9.88.97-9.36,6.34,1.37,11.07,11.5,9.81,29.28-1.37,41.68c-11.18,12.4-28.36,12.68-32.11,20.28,0,0-1.42-15.02.81-23.78,2.24-8.76,20.76-31.82,20.62-40.32Z", style: {
  fill: "#705fd3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".99px"
} })), /* @__PURE__ */ React.createElement("path", { d: "M123.73,141.45s16.27-23.97,17.91-28.41-.29-11.15,1.97-12.46,4.37-2.61,5.57-3.8-.78-7.1-.92-8.42-6.76-10.71-8.31-12.67-17.06,9.58-17.47,13.09c-.42,3.51,5.79,12.4,12.4,13.29,0,0-.99,20.26-11.16,39.38Z", style: {
  fill: "#a197c6",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".99px"
} })), /* @__PURE__ */ React.createElement("path", { d: "M145.67,99.35s-3.01.79-3.14,3.48c-.13,2.69,5.09,4.68,5.02,6.76,0,0,4.95-1.75,4.67-4.37,0,0,6.35-1.43,8.69,2.22,0,0-1.67-2.23-2.12-2.75,0,0-4.31-2.42-5.95-3.92,0,0,5.75,1.33,8.72-.28s5.58-2.66,6.96-2.78c0,0-5-1.25-7.4-2.47s-3.49-4.69-7.15-4.29c0,0,6.97-3.43,10.31-6.99s4.18-11.67,4.18-11.67l-4.44,3.8s-3.53-3.57-7.15-3.84-10.14-3.01-11.22-4.87c0,0,4.02,8.58,2.49,14.61-1.53,6.02-.9,2.88.1,6.32s1.41,5.29,1.41,5.29l-4,5.75Z", style: {
  fill: "#d8d3f4",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".99px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M204.52,176.86s-36.74-76.09-58.05-78.68", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".99px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M214.46,3.12s-26.39,20.73-36.3,54.29c0,0-2.25,10.98-22.55,26.2", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".99px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M140.14,26.16c-16.31,16.42,15.57,56.64,15.57,56.64,0,0,.93,6.51-6.92,7.9", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".99px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M258.13,93.64s-59.45,12.64-108.91.61", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".99px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M120.57,170.72s35.42-47.58,25.1-71.37", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".99px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M152.53,105.76s-3.75-6.42-6.22-7.32", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".99px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M149.28,92.26s3.95-1.42,5.9-1.95", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".99px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M66.55,50.94s-19.68-8.31-19.03-26.65c.65-18.33-2.34-24.03,9.91-14.62,12.25,9.41,17.48,33.83,14.26,40.55s-2.73,1.93-5.14.72Z", style: {
  fill: "#705fd3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.1px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M70.13,53.02s-18.58-17.32-16.44-27.24c2.15-9.92-3.41-12.98,4.65-6.24,8.06,6.74,13.14,27.29,11.79,33.48Z", style: {
  fill: "#a197c6",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.17px"
} })), /* @__PURE__ */ React.createElement("path", { d: "M108.29,186.91s-18.6-39.83-17.57-62.42c1.02-22.59,9.96-39.47,31.91-29.88,0,0,2.04,8.82,0,14.18,0,0,2.49-3.32,3.03-8.93,0,0,2.58,2.3,1.56,7.66,0,0,.22-7.33.24-11.33s-3.85-12.16,0-17.52c0,0-3.81,1.67-5.6,7.09,0,0-26.11-3-32.46,22.78,0,0-13.61-48.68-18.09-55.25,0,0,4.85-11.63,2.81-21.59,0,0-2.68,17.26-4.25,18.64s-.35,1.28-.35,1.28c0,0-12.8-1.35-16.61-5.88,0,0,1.51,7.2,15.57,8.45,0,0,14.83,45.93,16.87,62.52s.56,38.55,18.08,71.19", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".96px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M6.71,235.49c13.33-7.42,149.98-137.81,287.82,7.52l-43.73,1.47-2.53,46.54s-85.43-102.28-202.38,0l9.36-49.11-48.54-6.41Z", style: {
  fill: "#f9cd5f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "3.04px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M113.63,236.44l-.65-2.24c3.65-1.66,3.46-2.32,1.85-4.68-1.13-1.64-2.9-4.08-4.51-6.23l-11.79,3.45c-.12,2.07-.29,4.61-.35,6.67-.16,4.18,1.44,4.24,6.34,3.27l.65,2.24-14.94,4.36-.65-2.24c3.67-1.6,4.48-2.42,5.05-8.1l3.44-34.94,2.74-1.39c6.95,9.39,14.2,18.95,21.08,27.9,3.88,4.97,4.55,5.23,8.62,4.57l.65,2.24-17.54,5.12ZM99.86,209.09c-.48,4.87-.99,9.41-1.21,14.27l9.62-2.81-8.4-11.46Z", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M167.77,221.3c-.88,3.1-2.5,7.81-3.49,9.97-1.61.37-6.7,1.6-11.41,1.59-16.68-.04-23.53-10.78-23.51-20.63.03-12.83,9.84-21.49,24.72-21.46,5.39.01,10.16,1.32,11.71,1.51.3,2.91.73,7.5,1.16,10.23l-2.48.43c-1.29-4.84-3.15-7.14-6.43-8.45-1.49-.62-3.72-.88-5.39-.88-8.99-.02-13.53,7.47-13.55,17.21-.03,11.35,5.79,19.05,14.28,19.06,5.21.01,8.69-2.71,12.05-9.52l2.35.94Z", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M211.62,214.26c.17-2.93-.45-5.58-.79-6.59-.69-1.57-1.02-2.19-4.52-3.25l-3.8-1.15-8.45,27.89c-1.51,4.98-1.02,5.52,4.13,7.54l-.67,2.2-19.7-5.97.67-2.2c5.17,1.11,5.81.92,7.32-4.07l8.45-27.89-3.62-1.1c-3.32-1.01-4.43-.76-5.99.32-1.17.88-2.65,2.76-4.14,4.9l-2.25-.68c1.73-4.21,3.54-8.71,4.53-11.52l1.78.54c.48,1.63,1.15,1.97,2.93,2.51l23.74,7.19c1.6.49,2.54.38,3.89-.44l1.72.52c-.7,2.51-2.04,7.8-2.91,11.75l-2.31-.51Z", style: {
  fill: "#000",
  strokeWidth: 0
} }))));
const ForwardRef = forwardRef(SvgAct);
export default ForwardRef;
