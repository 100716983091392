import * as React from "react";
import { forwardRef } from "react";
const SvgSaCircle = (props, ref) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", "data-name": "Layer 1", xmlns: "http://www.w3.org/2000/svg", width: 298.07, height: 298.07, viewBox: "0 0 298.07 298.07", ref, ...props }, /* @__PURE__ */ React.createElement("rect", { width: 298.07, height: 298.07, style: {
  fill: "none",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("circle", { cx: 157.08, cy: 123.94, r: 120.18, style: {
  fill: "#ccc7e0",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "6.63px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M99.81,209.84s16.39-25.66,29.66-33.38c13.27-7.72,51.75-16.85,98.31-.07l.31-1.31s-50.13-18.39-93.91-5.02c-2.51.77,33.68-33.48,33.68-33.48,0,0,51.73-2.18,74.14-15.06l-1.84-.44s-35.03,15.15-69.68,12c0,0,30.76-35.35,27.77-83.8l-1.6.99s11.35,52.9-66.52,118.47c0,0-13.87-64.72-28.72-92.94l-1.74,1.08s22.24,51.61,25.85,92.12c0,0-.28,3.47-5.43,10.84l-25.06,25.12,4.76,4.87Z", style: {
  fill: "#7c6e5b",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".8px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M166.9,163.98c-.45.43-5.92-14.36-1.82-18.41,5.71-5.63,7.11.35,7.5,2.37.39,2.02.11,10.53-5.68,16.04Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.17px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M166.9,163.98s2.71-14.96,2.32-20.76", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.17px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M181.06,164.43c-.49.39-4.7-14.81-.28-18.5,6.16-5.14,7.06.94,7.27,2.99s-.77,10.5-7,15.51Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.17px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M181.06,164.43s3.95-14.68,4.04-20.49", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.17px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M195.2,165.12c-.51.26-1.51-13.97,3.11-16.46,6.43-3.47,6.13,2.08,5.95,3.94s-2.59,9.18-9.06,12.52Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.06px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M195.2,165.12s6.19-12.32,7.33-17.47", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.06px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M208.03,168.34c-.54.18.74-13.6,5.68-15.3,6.89-2.37,5.7,2.9,5.23,4.65s-4.02,8.41-10.92,10.65Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.04px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M208.03,168.34s8.06-10.88,10-15.64", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.04px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M218.93,173.01c-.57.02,4.37-12.9,9.6-13.2,7.27-.43,4.71,4.33,3.79,5.89s-6.13,7.02-13.38,7.32Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.04px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M218.93,173.01s10.69-8.31,13.84-12.37", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.04px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M166.88,164.52c-.16-.61-12.61,9.07-11.27,14.67,1.88,7.8,6.22,3.46,7.62,1.95s5.66-8.88,3.65-16.62Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.17px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M166.88,164.52s-5.59,14.13-8.99,18.85", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.17px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M181.31,164.95c-.21-.59-11.81,10.09-10,15.56,2.52,7.62,6.49,2.93,7.75,1.3s4.89-9.33,2.25-16.86Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.17px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M181.31,164.95s-4.39,14.55-7.39,19.54", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.17px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M194.83,165.4c-.29-.49-8.65,11.07-6.05,15.62,3.63,6.34,6.3,1.46,7.13-.2.83-1.67,2.65-9.17-1.08-15.42Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.06px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M194.83,165.4s-1.25,13.73-3,18.7", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.06px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M208.41,168.05c-.37-.43-6.55,11.94-3.25,15.99,4.6,5.65,6.38.55,6.9-1.19.52-1.73,1.03-9.26-3.65-14.81Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.04px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M208.41,168.05s1.1,13.5.23,18.56", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.04px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M219.49,172.69c-.47-.32-3.09,13.26,1.18,16.28,5.95,4.2,6.29-1.19,6.32-3s-1.5-9.2-7.5-13.28Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.04px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M219.49,172.69s4.69,12.7,5.22,17.81", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.04px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M226.6,173.73s10.47-3.88,17.39,5.1c0,0,3.05,3.61-.65,3.49s-13.97,1.7-17.73-6.29c0,0-1.85-1.65.99-2.3Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".8px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M225.07,174.68c13.12.36,20.09,6.7,20.09,6.7", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".8px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M208.27,130.53s9.21-5.39,6.75-16.04c0,0-.83-4.47-2.98-1.66-2.15,2.82-9.72,9.33-6.08,17.02,0,0,.1,2.38,2.3.68Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".77px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M206.63,131.08c7.68-9.96,7.2-19.02,7.2-19.02", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".77px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M221.5,126.83s7.73-5.94,4.29-15.31c0,0-1.26-3.96-2.89-1.16-1.63,2.8-7.74,9.57-3.56,16.12,0,0,.36,2.15,2.16.35Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".71px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M220.08,127.52c5.82-9.91,4.36-18.07,4.36-18.07", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".71px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M233.93,122.54s6.2-6.34,1.9-14.34c0,0-1.64-3.41-2.75-.68-1.11,2.73-5.75,9.61-1.14,14.97,0,0,.6,1.89,1.99.04Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".64px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M232.74,123.34c3.98-9.67,1.61-16.83,1.61-16.83", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".64px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M241.39,121.89s8.31-3.09,7.83-12.16c0,0-.04-3.78-2.2-1.78-2.16,2-9.29,6.24-7.41,13.06,0,0-.26,1.96,1.79.88Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".64px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M239.97,122.11c7.72-7.05,8.62-14.54,8.62-14.54", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".64px"
} }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M186.14,105c-.63.02,6.05,14.31,11.81,14.24,8.02-.1,4.75-5.3,3.59-7s-7.41-7.48-15.4-7.24Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.17px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M186.14,105s12.54,8.59,16.39,12.95", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.17px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M190.54,92.4c-.58.23,10.45,11.5,15.86,9.52,7.53-2.75,2.72-6.58,1.06-7.79s-9.47-4.6-16.93-1.73Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.17px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M190.54,92.4s14.68,3.94,19.76,6.78", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.17px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M193.91,78.57c-.48.31,11.33,8.31,15.8,5.56,6.23-3.82,1.27-6.35-.42-7.12s-9.24-2.36-15.38,1.56Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.06px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M193.91,78.57s13.77.83,18.79,2.42", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.06px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M197.64,64.96c-.42.38,12.13,6.18,16.09,2.76,5.5-4.77.35-6.39-1.4-6.86-1.75-.47-9.29-.75-14.69,4.1Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.04px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M197.81,64.85s13.46-1.51,18.55-.8", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.04px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M198.43,53.58c-.31.48,13.35,2.69,16.23-1.68,4.02-6.07-1.38-6.25-3.19-6.23s-9.15,1.78-13.04,7.91Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.04px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M198.43,53.58s12.55-5.08,17.64-5.77", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.04px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M186.08,104.92c.45.44-14.17,6.36-18.34,2.38-5.81-5.54.13-7.12,2.14-7.57s10.52-.43,16.2,5.18Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.17px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M186.08,104.92s-15.03-2.25-20.82-1.68", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.17px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M190.44,92.43c.3.55-15.41,1.96-18.24-3.06-3.94-6.99,2.2-6.77,4.25-6.62,2.05.16,10.19,2.65,14,9.67Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.17px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M190.44,92.43s-13.73-6.53-19.43-7.66", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.17px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M194.14,78.44c.28.5-13.91,1.94-16.55-2.61-3.66-6.32,1.9-6.19,3.75-6.07s9.26,2.31,12.79,8.67Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.06px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M194.14,78.44s-12.51-5.81-17.68-6.79", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.06px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M196.7,65.63c.19.54-13.61-.32-15.47-5.21-2.58-6.81,2.73-5.79,4.49-5.37,1.76.42,8.53,3.75,10.98,10.58Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.04px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M196.7,65.63s-11.13-7.72-15.94-9.51", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.04px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M198.24,53.72c.04.57-13.02-3.98-13.49-9.19-.65-7.25,4.18-4.84,5.77-3.96s7.2,5.91,7.72,13.15Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.04px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M198.24,53.72s-8.64-10.43-12.79-13.45", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.04px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M200.91,47.05s8.61-7.11,4.31-17.6c0,0-1.59-4.44-3.34-1.19-1.75,3.26-8.48,11.23-3.45,18.49,0,0,.5,2.42,2.48.3Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".8px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M199.32,47.9c6.27-11.53,4.28-20.74,4.28-20.74", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".8px"
} })))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M75.82,138.71s22.14-1.04,37.87-33.12c0,0-1.39-14.07-12.96-3.4,0,0-11.93,27.48-24.91,36.51Z", style: {
  fill: "#cd2735",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".69px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M72.85,161.74s29.76-5.13,45.85-52.36c0,0-4.16-19.33-18.07-2.57,0,0-11.69,40.21-27.78,54.92Z", style: {
  fill: "#cd2735",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".95px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M168.42,140.62s-23.75-1.99-39.64-38.01c0,0,1.94-15.48,14.03-3.26,0,0,11.95,30.81,25.6,41.27Z", style: {
  fill: "#cd2735",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".75px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M163.39,167.38s-30.05-9.01-40.75-59.71c0,0,6.7-19.42,18.96-.39,0,0,7.04,42.92,21.79,60.1Z", style: {
  fill: "#dd2c42",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".99px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M94.07,172.2s34.71-6.64,37.24-65.61c0,0-10.69-16.9-17.76,3.28,0,0-4.47,42.77-19.48,62.33Z", style: {
  fill: "#dd2c42",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.06px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M103.26,104.18s-13.28-16.13-14.23-26.56c-.95-10.44,11.62-34.39,11.86-40.79,0,0,5.45,18.74,11.62,24.9,6.17,6.17,3.79,27.27,2.13,33.68-1.66,6.4-4.74,8.78-4.74,8.78h-6.64Z", style: {
  fill: "#cd2735",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".94px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M100.85,37.3s2.71,49.64,5.58,66.88", style: {
  fill: "#000",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".91px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M139.38,102.46s11.98-14.54,12.83-23.95-10.48-31.01-10.69-36.79c0,0-4.92,16.9-10.48,22.46-5.56,5.56-3.42,24.6-1.92,30.37,1.5,5.77,4.28,7.91,4.28,7.91h5.99Z", style: {
  fill: "#cd2735",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".85px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M141.56,42.15s-2.45,44.77-5.03,60.31", style: {
  fill: "#000",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".82px"
} })), /* @__PURE__ */ React.createElement("path", { d: "M116.5,103.32s-16.12-3.44-15.66-16.63,14.6-38.87,17.87-53.88c0,0,12.18,28.11,15,32,3.53,4.88,8.13,22.57,3.99,28.89-4.14,6.33-9.51,9.62-9.51,9.62h-11.69Z", style: {
  fill: "#dd2c42",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".96px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M118.73,35.22s6.03,64.52,2.93,68.19", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".95px"
} })), /* @__PURE__ */ React.createElement("circle", { cx: 139.85, cy: 102.46, r: 9.3, style: {
  fill: "#3a3334",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".62px"
} }), /* @__PURE__ */ React.createElement("circle", { cx: 103.31, cy: 101.24, r: 9.24, style: {
  fill: "#3a3334",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".62px"
} }), /* @__PURE__ */ React.createElement("circle", { cx: 121.84, cy: 105.99, r: 12.98, style: {
  fill: "#3a3334",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".87px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M120.33,96.53s6.86-2.69,10.81,4.12c3.95,6.81-5.2,1.51-6.21.08s-9.18-2.57-4.6-4.2Z", style: {
  fill: "#93898b",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".91px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M138.83,95.89s4.32-1.69,6.81,2.6-3.28.95-3.91.05-5.78-1.62-2.9-2.65Z", style: {
  fill: "#93898b",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".57px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M101.72,94.98s4.32-1.69,6.81,2.6c2.49,4.29-3.28.95-3.91.05s-5.78-1.62-2.9-2.65Z", style: {
  fill: "#93898b",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".57px"
} }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M6.71,238.14c13.33-7.42,149.98-137.81,287.82,7.52l-43.73,1.47-2.53,46.54s-85.43-102.28-202.38,0l9.36-49.11-48.54-6.41Z", style: {
  fill: "#f9cd5f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "3.04px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M140.17,202.1c-1.83-3.95-4.26-8.42-10.19-7.63-3.74.49-5.84,3.46-5.36,7.07.47,3.54,3.13,5.35,9.18,7.44,7.35,2.5,12.17,5.27,13.01,11.65,1.01,7.6-4.58,13.98-13.79,15.2-4.12.54-7.94-.06-11.09-.57-.58-1.43-2.56-7.99-3.42-10.96l2.44-.91c1.83,3.89,6.36,10.43,12.68,9.6,3.93-.52,5.99-3.28,5.45-7.41-.49-3.74-3.1-5.62-8.9-7.81-7.18-2.72-11.99-5.36-12.87-12.07-.9-6.77,3.68-12.81,13.35-14.09,4.45-.59,7.93-.06,9.37-.06.57,2.35,1.47,5.64,2.52,9.63l-2.37.9Z", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M171.14,234.13l.19-2.32c4-.23,4.06-.92,3.41-3.7-.47-1.94-1.24-4.84-1.97-7.43l-12.24-1.03c-.86,1.89-1.93,4.2-2.72,6.09-1.65,3.84-.19,4.47,4.74,5.33l-.2,2.32-15.5-1.3.2-2.32c4-.17,5.05-.65,7.62-5.75l15.78-31.36,3.06-.31c3.1,11.26,6.43,22.79,9.63,33.62,1.83,6.03,2.36,6.52,6.4,7.36l-.19,2.32-18.2-1.53ZM168.14,203.66c-2.2,4.37-4.31,8.42-6.26,12.88l9.98.84-3.72-13.71Z", style: {
  fill: "#000",
  strokeWidth: 0
} }))));
const ForwardRef = forwardRef(SvgSaCircle);
export default ForwardRef;
