import * as React from "react";
import { forwardRef } from "react";
const SvgActCircle = (props, ref) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", "data-name": "Layer 1", xmlns: "http://www.w3.org/2000/svg", width: 298.07, height: 298.07, viewBox: "0 0 298.07 298.07", ref, ...props }, /* @__PURE__ */ React.createElement("rect", { width: 298.07, height: 298.07, style: {
  fill: "none",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("circle", { cx: 157.08, cy: 124.66, r: 120.18, style: {
  fill: "#ccc7e0",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "6.63px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M153.63,112.12s10.49,14.54,15.17,30.66c4.69,16.12,28.87,30.73,36.06,36.22,0,0,4.76-59.82-51.23-66.88Z", style: {
  fill: "#705fd3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".82px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M157.54,116.38s29.96,15.78,46.95,16.17c16.98.39,37.21-15.54,42.48-18.92,0,0-13.81-2.88-23.9-6.26s-37.65-8.62-69.76,1.91", style: {
  fill: "#705fd3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".78px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M163.09,111.09s20.44-.98,32-12.91c11.56-11.93,12.25-51.54,18.98-56.04,0,0-20.54,4.44-29.14,20.44-8.61,16-22.19,38.19-21.66,41.58s-.17,6.92-.17,6.92Z", style: {
  fill: "#705fd3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".78px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M161.19,112.39s5.38,3.33,5.25-7.95c0,0,7.8-1.79,9.57-7.16,0,0-1.94-12.03-9.23-17.03s-12.76-11.94-12.5-19.66c0,0-8,7.15-9.1,11.5s.82,14.66,5.26,22.33c4.45,7.67,4.77,12.03,10.75,17.96Z", style: {
  fill: "#705fd3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".78px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M155.74,123.81s-1.25-7.76.76-7.35,4.98,1.08,8.7,9.04c3.72,7.96,7.71,23-1.08,32.75-8.79,9.74-22.28,9.96-25.22,15.93,0,0-1.12-11.8.64-18.69,1.76-6.89,16.31-25,16.2-31.68Z", style: {
  fill: "#705fd3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".78px"
} })), /* @__PURE__ */ React.createElement("path", { d: "M141.38,151.18s12.78-18.83,14.07-22.32-.23-8.76,1.54-9.79,3.44-2.05,4.38-2.99-.61-5.58-.72-6.61-5.31-8.41-6.53-9.95-13.4,7.53-13.73,10.28c-.33,2.75,4.55,9.74,9.74,10.44,0,0-.78,15.92-8.77,30.94Z", style: {
  fill: "#a197c6",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".78px"
} })), /* @__PURE__ */ React.createElement("path", { d: "M158.62,118.11s-2.36.62-2.47,2.73c-.1,2.11,4,3.67,3.95,5.31,0,0,3.89-1.38,3.67-3.44,0,0,4.99-1.12,6.83,1.74,0,0-1.31-1.75-1.66-2.16,0,0-3.38-1.9-4.67-3.08,0,0,4.51,1.04,6.85-.22s4.38-2.09,5.47-2.19c0,0-3.93-.98-5.81-1.94s-2.74-3.68-5.62-3.37c0,0,5.48-2.69,8.1-5.49,2.62-2.8,3.29-9.17,3.29-9.17l-3.49,2.99s-2.77-2.8-5.62-3.01-7.97-2.36-8.82-3.82c0,0,3.16,6.74,1.96,11.48-1.2,4.73-.7,2.27.08,4.97.75,2.58,1.11,4.16,1.11,4.16l-3.14,4.52Z", style: {
  fill: "#d8d3f4",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".78px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M204.86,179s-28.87-59.78-45.61-61.82", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".78px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M212.67,42.5s-20.74,16.28-28.52,42.65c0,0-1.77,8.63-17.72,20.59", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".78px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M154.27,60.6c-12.81,12.9,12.24,44.5,12.24,44.5,0,0,.73,5.12-5.43,6.21", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".78px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M246.97,113.62s-46.71,9.93-85.57.48", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".78px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M138.9,174.18s27.83-37.38,19.72-56.07", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".78px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M164.01,123.14s-2.95-5.04-4.89-5.75", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".78px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M161.46,112.53s3.1-1.11,4.64-1.53", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".78px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M96.45,80.07s-15.46-6.53-14.95-20.94-1.84-18.88,7.79-11.49,13.74,26.58,11.2,31.86-2.14,1.52-4.04.57Z", style: {
  fill: "#705fd3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".87px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M99.26,81.71s-14.6-13.61-12.91-21.4c1.69-7.8-2.68-10.2,3.65-4.9,6.33,5.29,10.32,21.44,9.26,26.31Z", style: {
  fill: "#a197c6",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".92px"
} })), /* @__PURE__ */ React.createElement("path", { d: "M129.25,186.9s-14.61-31.3-13.81-49.04c.8-17.75,7.82-31.01,25.07-23.48,0,0,1.6,6.93,0,11.14,0,0,1.96-2.61,2.38-7.02,0,0,2.03,1.81,1.23,6.02,0,0,.17-5.76.19-8.9s-3.02-9.55,0-13.77c0,0-3,1.31-4.4,5.57,0,0-20.51-2.36-25.5,17.9,0,0-10.7-38.25-14.21-43.41,0,0,3.81-9.14,2.21-16.96,0,0-2.11,13.56-3.34,14.64s-.27,1-.27,1c0,0-10.06-1.06-13.05-4.62,0,0,1.19,5.65,12.23,6.64,0,0,11.65,36.09,13.25,49.12,1.6,13.04.44,30.29,14.2,55.93", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".75px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M6.71,238.49c13.33-7.42,149.98-137.81,287.82,7.52l-43.73,1.47-2.53,46.54s-85.43-102.28-202.38,0l9.36-49.11-48.54-6.41Z", style: {
  fill: "#f9cd5f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "3.04px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M113.63,239.44l-.65-2.24c3.65-1.66,3.46-2.32,1.85-4.68-1.13-1.64-2.9-4.08-4.51-6.23l-11.79,3.45c-.12,2.07-.29,4.61-.35,6.67-.16,4.18,1.44,4.24,6.34,3.27l.65,2.24-14.94,4.36-.65-2.24c3.67-1.6,4.48-2.42,5.05-8.1l3.44-34.94,2.74-1.39c6.95,9.39,14.2,18.95,21.08,27.9,3.88,4.97,4.55,5.23,8.62,4.57l.65,2.24-17.54,5.12ZM99.86,212.09c-.48,4.87-.99,9.41-1.21,14.27l9.62-2.81-8.4-11.46Z", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M167.77,224.3c-.88,3.1-2.5,7.81-3.49,9.97-1.61.37-6.7,1.6-11.41,1.59-16.68-.04-23.53-10.78-23.51-20.63.03-12.83,9.84-21.49,24.72-21.46,5.39.01,10.16,1.32,11.71,1.51.3,2.91.73,7.5,1.16,10.23l-2.48.43c-1.29-4.84-3.15-7.14-6.43-8.45-1.49-.62-3.72-.88-5.39-.88-8.99-.02-13.53,7.47-13.55,17.21-.03,11.35,5.79,19.05,14.28,19.06,5.21.01,8.69-2.71,12.05-9.52l2.35.94Z", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M211.62,217.26c.17-2.93-.45-5.58-.79-6.59-.69-1.57-1.02-2.19-4.52-3.25l-3.8-1.15-8.45,27.89c-1.51,4.98-1.02,5.52,4.13,7.54l-.67,2.2-19.7-5.97.67-2.2c5.17,1.11,5.81.92,7.32-4.07l8.45-27.89-3.62-1.1c-3.32-1.01-4.43-.76-5.99.32-1.17.88-2.65,2.76-4.14,4.9l-2.25-.68c1.73-4.21,3.54-8.71,4.53-11.52l1.78.54c.48,1.63,1.15,1.97,2.93,2.51l23.74,7.19c1.6.49,2.54.38,3.89-.44l1.72.52c-.7,2.51-2.04,7.8-2.91,11.75l-2.31-.51Z", style: {
  fill: "#000",
  strokeWidth: 0
} }))));
const ForwardRef = forwardRef(SvgActCircle);
export default ForwardRef;
