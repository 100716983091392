import * as React from "react";
import { forwardRef } from "react";
const SvgWaCircle = (props, ref) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", "data-name": "Layer 1", xmlns: "http://www.w3.org/2000/svg", width: 298.07, height: 298.07, viewBox: "0 0 298.07 298.07", ref, ...props }, /* @__PURE__ */ React.createElement("rect", { width: 298.07, height: 298.07, style: {
  fill: "none",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("circle", { cx: 148.71, cy: 124.69, r: 120.18, style: {
  fill: "#ccc7e0",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "6.63px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { points: "232.91 131.42 245.69 147.68 239.3 135.59 250.03 142.74 235.08 128.41 232.91 131.42", style: {
  fill: "#f9cd5f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".9px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M235.2,117.34l14.83,11.86-7.41-3.76,5.93,10.95s-16.08-17.13-15.63-17.52,2.28-1.53,2.28-1.53Z", style: {
  fill: "#f9cd5f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".9px"
} })), /* @__PURE__ */ React.createElement("path", { d: "M70.02,129.09s-5.36,1.68-13.46,2.32c0,0,1.37-4.6,9.81-5.4,0,0-10.84.46-16.65-6.05,0,0,11.29-2.4,20.3,1.94,0,0-15.29-3.54-22.7-11.52,0,0,18.14,3.54,25.67,9.13,0,0-3.76-3.65-5.36-4.56s-15.51-5.25-18.82-17.22c0,0,.11-.8,1.94,0,0,0,28.18,13,28.06,19.16,0,0-2.4-11.06-6.62-12.43-4.22-1.37-16.77-1.37-18.82-12.43,0,0-.34-1.14,2.17,0s23.16,7.3,26.35,18.59c0,0,.52,2.98,1.86-.16s-.49-9.42-9.16-13.07-13.69-11.29-14.14-17.34c0,0-.23-4.68,4.68,0s17.91,7.19,23.27,25.55c5.36,18.37-1.03,18.48-1.03,18.48l6.96,2.51s3.76-24.3-3.54-35.7c-7.3-11.41-11.06-4.11-12.43-24.41,0,0-1.14-4.68,3.19-1.71,4.33,2.97,24.53,34.79,18.14,49.28l-4.56,13.23,6.73,4.15,2.97-17.95s.46-18.48-6.84-32.4c-7.3-13.92-11.64-23.5-7.53-30.34,0,0,6.05-8.78,4.68,0-1.37,8.78,7.19,9.24,6.27,24.75,0,0,.57,2.17,2.51,7.41,1.94,5.25,8.76,24.94,4.11,32.17s-4.79,17.45-4.79,17.45l7.19,3.08,6.1-19.28s5.3-23.38,5.76-46.77,4.45-31.94,9.92-36.05c0,0,6.27-3.99,2.85,2.74-3.42,6.73,4.33,15.4-5.13,27.03,0,0-1.48,1.83-1.48,5.36s-2.52,37.51-6.27,47.11l-6.73,8.67-3.54,12.09,7.98,5.7,8.1-23.61s2.62-1.6,2.4-1.25,8.1-64.79,42.32-76.88c0,0,20.53-5.48,7.19,4.79,0,0-18.37,11.86-21.45,16.2,0,0-14.23,14.53-20.3,49.96,0,0,.34,8.21-4.79,13.86s-8.13,3.42-8.13,3.42l-4.79,13.52,7.36,11.29s7.44-5.65,7.19-5.48,15.49-15.66,15.49-15.66c0,0,18.14-32.25,45.69-34.82s51.33,27.03,50.82,27.03l-7.02-3.25,5.13,7.87-7.87-6.5,5.13,10.44s-41.46-58.01-76.48-5.3c-.76,1.15-1.2,8.56-8.9,8.21s-9.81,1.03-9.81,1.03l-12.61,13.92,7.98,9.47s19.51-18.99,19.11-18.88,38.44-59.72,84.75-5.53c0,0-8.21-3.88-8.78-3.88s6.84,8.44,6.84,8.44l-10.38-6.84,5.59,8.98s-28.75-46.39-62.74-2.47c-.38.49.34,7.98-5.82,9.13-6.16,1.14-13.92-1.03-14.14-.57s-9.81,13.46-9.81,13.46c0,0,1.58,10.38,1.42,10.72s-19.67-18.82-19.67-18.82l-2.05.11-14.26-13.23-17.45-11.29s-16.65-4.79-18.02-3.99-7.07,3.31-7.07,3.31Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".9px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M66.28,126.11s-3.19.46-4.16,1.83,4.05,1.77,4.79,1.08-.63-2.91-.63-2.91Z", style: {
  fill: "#897a13",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".9px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M69.93,123.03c-.17,0-2.81-2.05-5.37-1.71s-2.22,2.51-2.22,2.51c0,0,1.37,2.62,5.93,2.28,4.56-.34,1.65-3.08,1.65-3.08Z", style: {
  fill: "#897a13",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".9px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M73.12,122.17c.18-.04,1.37-2.97-3.71-4.22s-4.56.74-4.56.74c0,0,1.37,4.85,8.27,3.48Z", style: {
  fill: "#897a13",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".9px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M76.66,118.12c.61-.31,2.34-3.42-3.82-6.33-6.16-2.91-3.73,3.17-3.73,3.17,0,0,3.73,5.1,7.56,3.17Z", style: {
  fill: "#897a13",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".9px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M81.16,117.15s1.58-4.27.56-6.67c-1.02-2.39-5.92-5.98-4.04,0,1.88,5.98-1.43,7.63,3.48,6.67Z", style: {
  fill: "#897a13",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".9px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M83.9,120.63c-.23.17-.17,2.57,2.68,2.22s2.63,1.58,3.4-1.58c.77-3.16-2.2-14.68-3.45-17.36s-2.85-1.14-2.4.97c.46,2.11.34,6.39-2.4,8.56s2.17,7.19,2.17,7.19Z", style: {
  fill: "#897a13",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".9px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M96.51,114.78s4.03,1.56,3.65-6.87c-.38-8.43-3.88-13.63-4.51-7.07-.63,6.56-.51,11.8.86,13.94Z", style: {
  fill: "#897a13",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".9px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M105.69,115.5s3.08-1.57,3.54-6.8c.46-5.23-3.94-19.38-4.68-11.33-.74,8.04,1.03,16.71,1.14,18.14Z", style: {
  fill: "#897a13",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".9px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M118.98,116.41s5.69-8.46,6.41-16.52-1.39-17.64-4.13-4.47c-2.74,13.18-5.19,19.85-4.28,20.7s2,.29,2,.29Z", style: {
  fill: "#897a13",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".9px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M132.33,122.06s3.99-3.19,6.27-15.11c2.28-11.92,3.26-25.03-.51-17.34-3.77,7.69-7.98,27.15-7.64,30.06.34,2.91,1.88,2.4,1.88,2.4Z", style: {
  fill: "#897a13",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".9px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M152.52,137.11s3.71.11,8.33-2.57,5.36-12.03,11.18-17c5.82-4.96,14.83-15.97,5.59-11.06-9.24,4.91-25.82,22.13-27.42,26.14s2.32,4.49,2.32,4.49Z", style: {
  fill: "#897a13",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".9px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M163.73,148.03c.06.23,6.56,2.05,9.81-.74,3.25-2.79,2.91-6.33,3.88-8.67.97-2.34,22.47-23.1,12.78-19.28-9.7,3.82-21.48,13.57-28.68,22.39s2.21,6.3,2.21,6.3Z", style: {
  fill: "#897a13",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".9px"
} })), /* @__PURE__ */ React.createElement("path", { d: "M143.25,176.89s-19.16-34.22-62.51-47.91c0,0-9.58,10.04-13.92,11.64,0,0,1.37-7.45,3.19-9.2,0,0-7.53,4.86-11.18,6.92,0,0,1.17-4.85,5.93-9.35,0,0-2.14-.74,1.48-3.28,0,0-2.91-3.34,2.57-4.11,0,0-2.6-3.54,3.34-3.08,0,0-1.45-7.02,5.22-2.74.56.36.32-2.84,3.34-3.42.82-.16,3.08,3.94,3.68,2.57,0,0,.86-5.66,3.07-3.25,2.9,3.16,1.46,10.27,1.46,10.27l4.82,2.02.46-5.7s-5.02-5.7,2.05-9.58c0,0,1.38-.46,2.17.91s4.59,5.47,0,9.35l-1.26,7.3,3.88,1.37.91-4.79s1.14-11.86,4.11-13.23,2.97,1.6,2.97,1.6c0,0,2.97,6.84-3.65,12.78l-.91,5.7s5.48,3.42,5.48,2.74,1.37-6.39,2.05-7.98c0,0-.23-10.72,5.7-12.78,5.93-2.05,5.48,3.88,5.48,3.88,0,0-.23,8.21-7.07,11.18l-2.74,7.98,5.93,3.65s1.6-8.67,3.42-10.95c0,0,1.14-10.49,9.13-11.86,7.98-1.37,5.48,5.93,5.48,5.93,0,0-2.28,6.62-10.72,7.98,0,0-3.19,7.07-3.88,11.86,0,0,2.97.9,10.49,6.38,0,0,8.44-10.49,11.64-11.17s.46-5.93,9.81-7.3,4.56,6.84,4.56,6.84c0,0-6.84,5.93-12.78,2.97,0,0-9.13,9.81-8.67,12.09l6.16,5.48s9.35-9.35,9.58-10.27c0,0,5.25-6.84,10.04-5.7s10.49,2.05,10.49,4.33,1.83,6.16-9.13,6.39l-5.7-.91s-3.77-.23-10.67,9.58c0,0,6.11,7.53,9.3,15.06", style: {
  fill: "#cd2735",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".9px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M6.71,236.17c13.33-7.42,149.98-137.81,287.82,7.52l-43.73,1.47-2.53,46.54s-85.43-102.28-202.38,0l9.36-49.11-48.54-6.41Z", style: {
  fill: "#f9cd5f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "3.04px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M152.88,192.75c-4.3,1.34-4.92,2.39-5.74,7.7-.75,4.98-2.73,20.26-4.35,33.4l-3.42.61c-4.68-8.23-9.78-16.64-14.9-25.17-1.52,9.78-2.98,19.24-4.26,28.6l-3.54.63c-3.98-8.17-10.15-20.1-15.5-30.29-2.35-4.36-3.46-5.24-7.24-4.94l-.4-2.26,18.43-3.3.4,2.26c-4.5,1.25-4.46,2.18-3.04,5.2,3.27,6.66,6.68,13.42,9.89,19.71,1.57-9.16,2.85-18.9,3.97-28.86l3.24-.58c5.03,8.74,10.13,17.21,15.08,25.51,1.08-6.99,2.18-17.71,2.42-21.34.21-3.75-.6-4.74-5.92-4.23l-.41-2.26,14.89-2.67.4,2.26Z", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M177.45,236.54l.43-2.37c4.13.16,4.26-.55,3.87-3.46-.29-2.03-.79-5.1-1.29-7.83l-12.47-2.26c-1.06,1.85-2.4,4.12-3.4,5.99-2.07,3.79-.63,4.58,4.34,5.94l-.43,2.37-15.8-2.87.43-2.37c4.12.22,5.25-.17,8.4-5.15l19.3-30.65,3.17-.02c2.07,11.87,4.36,24.04,6.57,35.48,1.29,6.38,1.78,6.93,5.84,8.2l-.43,2.37-18.55-3.37ZM177.37,204.95c-2.69,4.27-5.26,8.23-7.7,12.61l10.17,1.84-2.46-14.45Z", style: {
  fill: "#000",
  strokeWidth: 0
} }))));
const ForwardRef = forwardRef(SvgWaCircle);
export default ForwardRef;
