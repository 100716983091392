import * as React from "react";
import { forwardRef } from "react";
const SvgQld = (props, ref) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", "data-name": "Layer 1", xmlns: "http://www.w3.org/2000/svg", width: 298.07, height: 298.07, viewBox: "0 0 298.07 298.07", ref, ...props }, /* @__PURE__ */ React.createElement("rect", { width: 298.07, height: 298.07, style: {
  fill: "none",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M226.62,49.97s2.01-5.19,2.29-11-1.08-9.6-1.15-15.01,2.78-3.24,4.27,0,12.17,15.22,8.79,18.36c0,0-2.16,2.73-5.41-2.68s-7.17,11.09-7.17,11.09l-1.63-.76Z", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".77px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M215.93,51.58s.42-5.55-1.01-11.19-3.83-8.87-5.46-14.03,1.72-3.91,4.08-1.24,16.07,11.03,13.75,15.01c0,0-1.28,3.24-5.95-.99s-3.63,12.69-3.63,12.69l-1.78-.26Z", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".77px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M203.3,53.49s-.71-5.52-3.24-10.76-5.53-7.92-8.17-12.64c-2.63-4.72.89-4.18,3.75-2.04s17.96,7.57,16.48,11.94c0,0-.6,3.43-6.03.23s-1.01,13.16-1.01,13.16l-1.79.1Z", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".77px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M74.75,212.95s25.49-48.72,61.84-73.77c.52-.36,1.04-.71,1.57-1.06,37.18-24.71-26.26,15.73-26.26,15.73,0,0-41.21,42.91-37.15,59.1Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".77px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M115.25,211.25s26.51-80.15,73.18-107.38c0,0-64.26,41.18-76.53,105.27l3.35,2.11Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".77px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M140.09,134.25c2.41.94,21.39-91.7,89.8-83.99,0,0,2.68,4.74-2.85,2.85,0,0-35-4.3-59.66,32.3,0,0-19.33,24.13-23.92,50.16,0,0-6.75-2.64-3.37-1.32Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".77px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M113.77,230.82s-.35-84.42-36.28-124.78c0,0,48.32,59.08,40.12,123.81l-3.84.97Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".77px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M126.51,198.32s-4.07-.69,12.8-19.72c6.83-7.7,48.76-46.35,132.47-11.41,0,0-114.24,10.38-145.27,31.13Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.09px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M125.65,198.42s43.93-56.72,145.27-31.13c0,0-103.76-48.42-145.27,31.13Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.09px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M139.41,141.66s-2.84-.69,10-13.3c5.19-5.1,36.68-30.36,94.09-1.64,0,0-81.17,1.79-104.09,14.94Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".77px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M138.8,141.69s33.77-37.93,104.09-14.94c0,0-70.93-39.22-104.09,14.94Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".77px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M175.1,101.43s-7.69-23.57-24.25-36.41S127.56,11.36,108.83,4.63c0,0,38.06-2.53,58.49,52.4", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".77px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M175.1,101.43S162.28,19.57,108.83,4.63c0,0,66.26,4,66.28,96.81Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".77px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M123.16,123.07s-6.28-19.25-19.81-29.74c-13.53-10.49-19.03-43.84-34.33-49.34,0,0,31.09-2.06,47.78,42.81", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".63px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M123.16,123.07s-10.47-66.88-54.14-79.08c0,0,54.13,3.27,54.14,79.08Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".63px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M90.33,202.15s2.73,1.94,3.97-18.65c.5-8.33-1.39-54.56-69.44-82.54,0,0,59.58,71.49,65.48,101.19Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".88px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M90.77,202.7s7.34-57.74-65.48-101.19c0,0,87.3,31.75,65.48,101.19Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".88px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M181.57,73.53s6.08-11.97,4.65-23.22,11.62-29.45,7.03-39.2c0,0,15.53,13.6,2.33,42.44", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".42px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M181.57,73.53s26.42-36.24,11.68-62.42c0,0,23.83,26.89-11.68,62.42Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".42px"
} }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M140.54,106.64s-3.37-9.14-2.89-16.35c0,0,7.69.24,14.19,11.06,0,0,4.09-12.02,15.39-5.77,11.3,6.25,26.69,27.89,12.5,37.75,0,0,6.25,4.09,7.69,7.21,0,0-18.03,2.89-28.85-3.85,0,0-1.68,16.35-15.15,23.8,0,0-3.04-9.71-2.4-13.7,0,0-13.94,6.01-18.27-6.97-4.33-12.98-1.2-42.8,17.79-33.18Z", style: {
  fill: "#ce849d",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.27px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M156.05,129.4s-12.98-16.47-12.86-30.41c0,0,12.74,10.22,12.86,30.41Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.08px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M158.88,131.68s-2.83-33.3,14.72-21.4c0,0-13.42,7.55-14.72,21.4Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.19px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M160.55,132.33s11.12-.48,17.4,5.09c0,0-13.02.59-17.4-5.09Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.01px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M152.27,133.62s.61,11.87-5.28,18.64c0,0-.74-13.91,5.28-18.64Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.08px"
} })), /* @__PURE__ */ React.createElement("path", { d: "M152.48,129.5s-27.16-19.48-25.92,1.69c0,0,13.36-7.66,25.92-1.69Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.19px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M167.87,138.07c1.57,9.83-6.13,14.62-12.27,5.83-8.09-11.59-5.52-22.28-5.52-22.28,0,0,1.61-4.28,5.93-1.67,3.45,2.08,11.14,13.62,11.86,18.13Z", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.08px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M145.34,127.96s1.32-3.97,4.09-2.76,7.93-1.44,7.93-2.4.24-4.33,3.61-2.04,2.36,7.86,0,7.88,2.16,10.64,2.16,10.64c0,0-1,5.35-4.25,1.68s-4.16-10.97-7.29-10.22-5.45,2.49-6.25-2.76Z", style: {
  fill: "#a33d5f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.19px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M193.71,67.72s-.69-8.63,1.46-14.7c0,0,6.53,2.05,9.49,12.88,0,0,6.39-9.31,14.57-1.24,8.18,8.07,16.16,30.3,1.64,35.34,0,0,4.37,5,4.86,8.03,0,0-16.14-1.86-23.79-10.23,0,0-5.37,13.6-18.69,16.75,0,0-.27-9.05,1.23-12.32,0,0-13.39,1.8-13.98-10.36-.59-12.16,9.25-36.95,23.21-24.14Z", style: {
  fill: "#ce849d",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.13px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M201.52,90.94s-7.16-17.23-3.71-29.14c0,0,8.46,11.81,3.71,29.14Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".96px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M203.4,93.58s5.58-29.2,17.75-14.79c0,0-13.31,3.24-17.75,14.79Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.05px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M204.67,94.53s9.64,2.26,13.69,8.54c0,0-11.29-2.63-13.69-8.54Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".9px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M197.27,93.64s-2.33,10.31-9,14.69c0,0,2.71-12.09,9-14.69Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".96px"
} })), /* @__PURE__ */ React.createElement("path", { d: "M198.44,90.17s-18.58-23.21-22.61-4.78c0,0,13.28-3.35,22.61,4.78Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.05px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M209.56,101.21c-1.02,8.8-8.77,11.05-11.91,2.04-4.14-11.87.63-20.41.63-20.41,0,0,2.41-3.28,5.48,0,2.45,2.61,6.27,14.35,5.8,18.38Z", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".96px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M192.69,87.13s2.09-3.08,4.17-1.39,7.14.67,7.37-.15,1.25-3.65,3.58-.88.13,7.3-1.89,6.75-.71,9.64-.71,9.64c0,0-2.14,4.34-4.04.42s-.92-10.4-3.79-10.5-5.27.82-4.69-3.87Z", style: {
  fill: "#a33d5f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.05px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M98.07,109.1s5.75-9.45,12.51-14.12c0,0,5.22,7.01.18,20.43,0,0,13.58-4.86,16,9.61,2.42,14.47-5.96,43.44-24.75,37.79,0,0,.78,8.45-.98,11.94,0,0-15.3-14-16.96-28.39,0,0-15.73,10.05-31.88,3.34,0,0,6.49-9.56,10.49-11.82,0,0-15.2-8.16-6.7-21.18,8.49-13.02,37.23-31.3,42.09-7.61Z", style: {
  fill: "#ce849d",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.44px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M88.77,138.97s5.48-23.18,17.97-32.93c0,0-.09,18.55-17.97,32.93Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.23px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M88.74,143.11s27.62-26.04,29.43-2.02c0,0-16.2-6.6-29.43,2.02Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.35px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M89.35,145.04s8.28,9.55,7.77,19.08c0,0-9.72-11.17-7.77-19.08Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.14px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M82.35,138.59s-10.13,8.93-20.31,8.47c0,0,11.85-10.48,20.31-8.47Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.22px"
} })), /* @__PURE__ */ React.createElement("path", { d: "M86.16,135.87s-1.85-37.92-19.81-21.86c0,0,16.25,6.47,19.81,21.86Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.35px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M89.4,155.61c-7.64,8.34-17.34,4.87-13.85-6.8,4.6-15.39,15.93-20.65,15.93-20.65,0,0,4.95-1.59,5.67,4.1.58,4.54-4.25,19.53-7.75,23.35Z", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.23px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M82.48,128.42s4.46-1.63,5.35,1.68c.88,3.31,6.89,6.04,7.74,5.36.86-.68,4.02-2.84,4.37,1.76s-5.33,7.65-7.01,5.56-7.94,9.44-7.94,9.44c0,0-5.47,2.89-4.49-2.59.98-5.48,6.83-11.45,3.94-13.71-2.89-2.26-6.06-3.1-1.96-7.51Z", style: {
  fill: "#a33d5f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.35px"
} }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M6.71,237.25c13.33-7.42,149.98-137.81,287.82,7.52l-43.73,1.47-2.53,46.54s-85.43-102.28-202.38,0l9.36-49.11-48.54-6.41Z", style: {
  fill: "#f9cd5f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "3.04px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M103.13,196.96c11.44-3.06,23.01,2.79,26.27,14.97,3.52,13.17-3.66,23.97-15.34,27.09-12.37,3.31-23.75-3.32-26.94-15.25-2.83-10.57,1.92-23.04,16.02-26.81ZM102.93,199.93c-6.31,1.69-9.29,9.11-6.36,20.05,2.79,10.45,9.34,18.31,17.68,16.07,5.87-1.57,8.93-8.48,5.9-19.8-3.27-12.24-10.05-18.25-17.22-16.33ZM116.76,233.06c9.54,3.94,17.06,6.57,22.1,7.07l-.06,2c-2.72.99-7.39,2.11-9.98,2.07-5.79-.11-13.62-3.38-19.93-6.13l7.87-5.02Z", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M171.09,223.3c-.61,2.69-2.63,9.29-3.27,10.97l-31.23-1.23.09-2.33c5.56-.16,6.08-.58,6.28-5.87l.94-23.99c.21-5.29-.4-5.82-5.11-6.38l.09-2.33,19.64.77-.09,2.33c-4.93.25-5.58.66-5.78,5.95l-.96,24.43c-.14,3.46-.07,5.04,3.39,5.24.12.07,3.02.18,3.27.19,3.59.14,4.56-.58,6.14-2.22,1.25-1.21,2.99-3.86,4.22-6.39l2.36.85Z", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M203.6,200.97c7.75,2.65,12.89,6.23,15.52,11.19,2.12,3.9,3.03,8.95.94,15.06-2.38,6.96-7.11,11.3-12.97,13.22-5.64,1.86-11.97,1.59-18.88-.77l-16.65-5.69.77-2.24c4.73,1.21,5.46.85,7.17-4.18l7.86-23.01c1.74-5.09,1.24-5.8-3.12-7.69l.76-2.24,18.59,6.35ZM188.06,230.02c-1.47,4.3-.88,6.13,3.54,7.64,8.18,2.79,14.84-1.29,18.76-12.73,1.97-5.75,2.11-11.32.21-14.87-1.67-3.41-4.53-5.34-8.59-6.72-2.24-.77-3.44-.63-3.92-.39-.73.36-1.2,1.35-1.8,3.1l-8.19,23.98Z", style: {
  fill: "#000",
  strokeWidth: 0
} }))));
const ForwardRef = forwardRef(SvgQld);
export default ForwardRef;
