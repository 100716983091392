import * as React from "react";
import { forwardRef } from "react";
const SvgGreenPlus = (props, ref) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", "data-name": "Layer 1", xmlns: "http://www.w3.org/2000/svg", width: 298.07, height: 298.07, viewBox: "0 0 298.07 298.07", ref, ...props }, /* @__PURE__ */ React.createElement("rect", { width: 298.07, height: 298.07, style: {
  fill: "none",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("g", { id: "Plus" }, /* @__PURE__ */ React.createElement("circle", { cx: 146.36, cy: 151.03, r: 95.57, style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "3.55px"
} }), /* @__PURE__ */ React.createElement("polygon", { points: "215.2 135.09 162.31 135.09 162.31 82.19 130.41 82.19 130.41 135.09 77.52 135.09 77.52 166.98 130.41 166.98 130.41 219.87 162.31 219.87 162.31 166.98 215.2 166.98 215.2 135.09", style: {
  fill: "#fff",
  stroke: "#231f20",
  strokeMiterlimit: 10,
  strokeWidth: "3.55px"
} })));
const ForwardRef = forwardRef(SvgGreenPlus);
export default ForwardRef;
