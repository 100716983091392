import * as React from "react";
import { forwardRef } from "react";
const SvgAusCircle = (props, ref) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", "data-name": "Layer 1", xmlns: "http://www.w3.org/2000/svg", width: 299.44, height: 298.07, viewBox: "0 0 299.44 298.07", ref, ...props }, /* @__PURE__ */ React.createElement("rect", { width: 298.07, height: 298.07, style: {
  fill: "none",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("circle", { cx: 152.24, cy: 125.04, r: 120.18, style: {
  fill: "#ccc7e0",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "6.63px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M196.12,105.37s3.55-10.14.71-19.23l1.57-.38s1.56,13.58,0,17.98", style: {
  fill: "#b2b272",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".42px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 116.66, y1: 123.1, x2: 118.23, y2: 128.88, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 120.65, y1: 120.87, x2: 123.09, y2: 122.22, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 120, y1: 113.61, x2: 126.12, y2: 110.22, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 117.79, y1: 109.84, x2: 120.22, y2: 106.86, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 110.54, y1: 104.81, x2: 112, y2: 109.39, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 103.66, y1: 109.73, x2: 107.66, y2: 111.87, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 103.33, y1: 120.26, x2: 106.98, y2: 118.66, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 110.52, y1: 129.57, x2: 112.67, y2: 122.66, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 121.3, y1: 116.22, x2: 128.47, y2: 115.58, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".5px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M97.37,188.79c1.58-.74,42.05-31.12,45.97-35.65s18.54-43.28,18.54-43.28l1.13,2.63s-14.82,36.48-15.29,37.87c0,0,37.21-33.37,38.53-35.36,0,0,7.23-18.21,7.31-21.2,0,0-2.87,14.45-4.3,17.5,0,0,17.83-15.57,21.2-21.25,0,0-45.38,50.69-57.16,59.37,0,0,58.93-27.85,71.49-37.86l.23,2.61s-73.74,39.32-76.55,41.37c-2.81,2.04-61.05,45.82-60.79,48.78", style: {
  fill: "#7c6e5b",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".79px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M110.32,177.17s21.87-34.57,29.51-66.87l2.08-.07s-18.34,58.05-29.57,68.39", style: {
  fill: "#7c6e5b",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".42px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M161.78,149.17s7.99,1.41,26.23-.19,41.93-17.97,46.06-19.22c4.14-1.25,4.3-3.8.52-3.68-3.78.13-28.27,2.1-38.6,6.07-10.33,3.97-31.92,11.22-35.9,14.14s1.68,2.88,1.68,2.88Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".65px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M158.84,148.51s72.65-19.72,78.53-21.54", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".56px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M151.17,171.04s18.3-3.37,40.05-1.12c21.75,2.26,40.75,10,55.28,3.26,0,0,5.86-2.64.11-5.04,0,0-24.4-14.68-44.56-15.89-20.16-1.21-48.78,3.44-55.66,6.82-6.89,3.38-33.38,14.64,4.78,11.97Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".67px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M131.03,169.11s51.83-16.59,86.01-7.44c12.61,3.38,22.8,9.38,32.1,8.55", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".67px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M90.4,172.77s-6-15.07-17.75-30.06c-11.75-15-26.28-25.42-28.05-39.27,0,0-.77-5.55,3.67-2.42,0,0,22.15,11.19,32.39,25.5,10.24,14.31,20.08,37.58,20.78,44.23.7,6.65,4.68,31.42-11.04,2.03Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".58px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M101.16,186.74s-11.8-45.93-34.4-66.91c-8.34-7.74-17.5-12.47-21.19-19.72", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".58px"
} })), /* @__PURE__ */ React.createElement("path", { d: "M215.04,118.19s11.65-23.78,13.77-30.5l1.97.52s-10.45,23.8-12.19,27.54l-3.55,2.43Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".79px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M155.53,120.33s-1.31-10.4,2.3-33.85c3.61-23.45,27.91-53.06,29.94-58.33s5.55-5.33,5.08-.46-5.07,36.36-11.32,49.46c-6.25,13.1-17.87,40.54-22.18,45.51-4.31,4.97-3.82-2.34-3.82-2.34Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".87px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M156.21,124.16s32.68-92.61,35.64-100.09", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".75px"
} })), /* @__PURE__ */ React.createElement("path", { d: "M103.02,186.79s10.72-30.52,12.48-57.52l1.38-.72s-3.38,45.19-10.47,56.14l-3.4,2.1Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".79px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M116.41,104s2.86-2.45,3.31,1.3c0,0,3.24-1.83,2.27,1.57,0,0,3.48-1.88,2.02,2.12,0,0,3.73-.83,1.57,2.59,0,0,3.73-.02,1.06,2.71,0,0,2.39.9.29,3.21,0,0,3.36,1.41-.17,3.3,0,0,2.56,3.71-1.89,3.76,0,0,2.28,4.73-3.12,3.58,0,0-.23,5.19-4.27,2.36,0,0-2.88,6.13-6.04.79,0,0-5.84,4.4-5.46-2,0,0-6,4.18-4.2-3.54,0,0-6.68.37-2.37-6.03,0,0-6.7-4.33.83-7.06,0,0-5.96-6.67,3.02-5.02,0,0-.64-3.89,2.64-2.07,0,0,.12-4.11,3.05-1.37,0,0,1.11-2.97,2.57-.53,0,0,3.01-5.42,4.86.33Z", style: {
  fill: "#efe47b",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".76px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M107.11,112.11s4.45,2.39,6.51-3.13c0,0-1.35,5.61,2.88,3.69,0,0-4.32,3.64.53,3.28,0,0-3.37,1.46.1,2.83,0,0-4-.26-1.68,3.26,0,0-2.71-2.39-3.71,1.07,0,0-.52-3.56-3.53-1.61,0,0,2.29-3.11-.86-3.24,0,0,2.25-1.5-3.04-2.47,0,0,6.88-.04,2.8-3.67Z", style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".49px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M201.1,69.71s1.99-1.7,2.3.9c0,0,2.26-1.27,1.58,1.09,0,0,2.42-1.31,1.41,1.48,0,0,2.6-.58,1.1,1.8,0,0,2.6-.02.74,1.89,0,0,1.66.62.2,2.23,0,0,2.34.98-.12,2.29,0,0,1.78,2.58-1.32,2.62,0,0,1.59,3.29-2.17,2.49,0,0-.16,3.61-2.97,1.64,0,0-2,4.26-4.2.55,0,0-4.06,3.06-3.8-1.39,0,0-4.17,2.91-2.92-2.46,0,0-4.65.26-1.65-4.19,0,0-4.66-3.01.58-4.91,0,0-4.15-4.64,2.1-3.49,0,0-.44-2.71,1.84-1.44,0,0,.08-2.86,2.12-.95,0,0,.77-2.07,1.79-.37,0,0,2.09-3.77,3.38.23Z", style: {
  fill: "#efe47b",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".53px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M194.64,75.35s3.1,1.67,4.53-2.18c0,0-.94,3.9,2,2.57,0,0-3,2.53.37,2.28,0,0-2.34,1.01.07,1.97,0,0-2.78-.18-1.17,2.27,0,0-1.89-1.66-2.58.74,0,0-.36-2.48-2.46-1.12,0,0,1.59-2.16-.6-2.26,0,0,1.56-1.04-2.11-1.72,0,0,4.79-.03,1.95-2.55Z", style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".34px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 199.43, y1: 83.4, x2: 200.48, y2: 87.29, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".34px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 202.11, y1: 81.9, x2: 203.75, y2: 82.81, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".34px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 201.67, y1: 77.02, x2: 205.79, y2: 74.74, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".34px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 200.19, y1: 74.49, x2: 201.82, y2: 72.48, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".34px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 195.31, y1: 71.11, x2: 196.29, y2: 74.18, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".34px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 190.69, y1: 74.41, x2: 193.38, y2: 75.85, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".34px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 190.47, y1: 81.49, x2: 192.91, y2: 80.41, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".34px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 195.3, y1: 87.75, x2: 196.74, y2: 83.1, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".34px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 202.54, y1: 78.77, x2: 207.36, y2: 78.34, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".34px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M236.45,66.52s2.43-2.07,2.81,1.1c0,0,2.75-1.55,1.92,1.33,0,0,2.95-1.6,1.71,1.8,0,0,3.16-.7,1.33,2.19,0,0,3.16-.02.9,2.3,0,0,2.03.76.24,2.72,0,0,2.85,1.19-.14,2.8,0,0,2.17,3.15-1.6,3.19,0,0,1.93,4.01-2.64,3.03,0,0-.2,4.4-3.61,2,0,0-2.44,5.19-5.12.67,0,0-4.95,3.73-4.63-1.69,0,0-5.08,3.54-3.55-3,0,0-5.66.32-2.01-5.11,0,0-5.68-3.67.7-5.98,0,0-5.05-5.65,2.56-4.25,0,0-.54-3.3,2.24-1.76,0,0,.1-3.48,2.59-1.16,0,0,.94-2.52,2.18-.45,0,0,2.55-4.59,4.12.28Z", style: {
  fill: "#efe47b",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".64px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M228.57,73.4s3.77,2.03,5.51-2.66c0,0-1.14,4.75,2.44,3.13,0,0-3.66,3.09.45,2.78,0,0-2.85,1.24.08,2.4,0,0-3.39-.22-1.42,2.76,0,0-2.3-2.03-3.14.91,0,0-.44-3.02-2.99-1.37,0,0,1.94-2.63-.73-2.75,0,0,1.9-1.27-2.58-2.09,0,0,5.83-.03,2.37-3.11Z", style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".41px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 234.41, y1: 83.2, x2: 235.69, y2: 87.94, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".41px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 237.67, y1: 81.38, x2: 239.67, y2: 82.48, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".41px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 237.14, y1: 75.43, x2: 242.15, y2: 72.65, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".41px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 235.33, y1: 72.35, x2: 237.32, y2: 69.91, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".41px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 229.4, y1: 68.23, x2: 230.59, y2: 71.98, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".41px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 223.76, y1: 72.25, x2: 227.04, y2: 74.01, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".41px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 223.49, y1: 80.87, x2: 226.47, y2: 79.57, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".41px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 229.38, y1: 88.5, x2: 231.13, y2: 82.84, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".41px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 238.2, y1: 77.57, x2: 244.08, y2: 77.04, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".41px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M205.82,118.05s2.19-1.87,2.54,1c0,0,2.49-1.4,1.74,1.2,0,0,2.67-1.44,1.55,1.63,0,0,2.86-.64,1.21,1.99,0,0,2.86-.02.81,2.08,0,0,1.83.69.22,2.46,0,0,2.58,1.08-.13,2.53,0,0,1.96,2.85-1.45,2.88,0,0,1.75,3.63-2.39,2.74,0,0-.18,3.98-3.27,1.81,0,0-2.21,4.7-4.63.61,0,0-4.48,3.38-4.18-1.53,0,0-4.6,3.21-3.22-2.72,0,0-5.12.29-1.82-4.62,0,0-5.13-3.32.63-5.41,0,0-4.57-5.11,2.31-3.84,0,0-.49-2.99,2.02-1.59,0,0,.09-3.15,2.34-1.05,0,0,.85-2.28,1.97-.41,0,0,2.31-4.15,3.73.25Z", style: {
  fill: "#efe47b",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".58px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M198.7,124.27s3.41,1.83,4.99-2.4c0,0-1.04,4.3,2.21,2.83,0,0-3.31,2.79.4,2.51,0,0-2.58,1.12.08,2.17,0,0-3.06-.2-1.28,2.5,0,0-2.08-1.83-2.84.82,0,0-.4-2.73-2.71-1.24,0,0,1.75-2.38-.66-2.49,0,0,1.72-1.15-2.33-1.89,0,0,5.27-.03,2.15-2.81Z", style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".37px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 203.97, y1: 133.13, x2: 205.13, y2: 137.42, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".37px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 206.93, y1: 131.49, x2: 208.73, y2: 132.49, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".37px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 206.44, y1: 126.1, x2: 210.98, y2: 123.59, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".37px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 204.81, y1: 123.32, x2: 206.6, y2: 121.11, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".37px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 199.44, y1: 119.59, x2: 200.52, y2: 122.98, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".37px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 194.34, y1: 123.23, x2: 197.31, y2: 124.82, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".37px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 194.1, y1: 131.03, x2: 196.8, y2: 129.85, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".37px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 199.42, y1: 137.93, x2: 201.01, y2: 132.81, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".37px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 207.4, y1: 128.04, x2: 212.72, y2: 127.56, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".37px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M135.03,102.31s-1.25-11.78-6.57-24.65c-5.31-12.87-13.48-23.07-12-33.16,0,0,.55-4.06,3.06-.98,0,0,13.38,12.24,17.77,24.32,4.39,12.09,6.73,30.4,5.91,35.22-.81,4.82-2.89,23.03-8.17-.75Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".42px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M139.86,114.26s.75-34.65-11.03-53.86c-4.34-7.09-9.86-12.22-11.03-18.05", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".42px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M151.02,121.11s3.23-.43,1.79,2.5c0,0,3.22.19.91,2.2,0,0,3.41.27.47,2.49,0,0,3.1,1.15-.07,2.62,0,0,2.72,1.74-.51,2.47,0,0,1.32,1.77-1.3,2.47,0,0,1.78,2.6-1.67,2.32,0,0,.11,3.9-3.14,1.84,0,0-.57,4.51-3.94,1.13,0,0-2.61,3.66-4.2-.3,0,0-4.97,3.09-4.76-2.27,0,0-6.31.45-3.02-4.02,0,0-6.32.21-1.38-4.54,0,0-5.02-2.87,1.11-5.49,0,0-2.83-6.29,3.92-4.74,0,0-1.19-7.64,4.55-2.22,0,0,1.37-3.13,2.89-.26,0,0,2.02-2.93,2.86.44,0,0,2.2-1.63,2.12.82,0,0,4.73-2.52,3.37,2.53Z", style: {
  fill: "#efe47b",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".66px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M140.46,122.63s2.11,3.83,6.2.78c0,0-3.62,3.44.36,4.03,0,0-4.85.61-1.16,2.63,0,0-3.13-.52-1.26,2.1,0,0-2.78-2.07-2.75,1.58,0,0-.85-3.01-3.2-.97,0,0,1.3-2.83-1.81-2.83,0,0,3.12-1.18.9-2.76,0,0,2.33-.03-1.05-3.22,0,0,5.01,3.21,3.76-1.35Z", style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".42px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M224.07,107.87s1.23,3.07-2.01,2.39c0,0,.61,3.21-1.94,1.45,0,0,.59,3.42-2.33,1.09,0,0-.36,3.34-2.6.58,0,0-1.03,3.11-2.56.12,0,0-1.42,1.74-2.75-.66,0,0-2.12,2.4-2.7-1.06,0,0-3.8,1.08-2.59-2.63,0,0-4.58.57-2.1-3.6,0,0-4.25-1.65-.76-4.21,0,0-4.29-4.11,1.04-5.25,0,0-2.02-6.09,3.19-3.98,0,0-1.79-6.16,4.12-2.49,0,0,1.56-5.66,5.68-.28,0,0,5.48-4.35,5.64,2.67,0,0,7.22-3.09,3.32,3.92,0,0,3.42.56.98,2.78,0,0,3.38,1.25.28,2.92,0,0,2.16,1.75-.28,2.29,0,0,3.66,4.02-1.64,3.95Z", style: {
  fill: "#efe47b",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".67px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M219.93,97.86s-3.24,3.03.78,6.29c0,0-4.28-2.7-3.88,1.36,0,0-1.82-4.61-2.87-.48,0,0-.27-3.21-2.38-.71,0,0,1.34-3.25-2.24-2.31,0,0,2.75-1.59.15-3.39,0,0,3.11.57,2.33-2.48,0,0,1.94,2.77,2.94.2,0,0,.62,2.29,2.91-1.83,0,0-1.9,5.73,2.27,3.36Z", style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".43px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 208.39, y1: 100.35, x2: 203.31, y2: 100.03, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".43px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 209.09, y1: 104.15, x2: 207.35, y2: 105.74, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".43px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 215.11, y1: 105.6, x2: 216.17, y2: 111.44, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".43px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 218.73, y1: 104.84, x2: 220.47, y2: 107.6, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".43px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 224.74, y1: 100.38, x2: 220.66, y2: 100.31, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".43px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 222.65, y1: 93.52, x2: 219.84, y2: 96.16, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".43px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 214.28, y1: 90.4, x2: 214.58, y2: 93.76, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".43px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 204.85, y1: 93.65, x2: 209.82, y2: 97.25, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".43px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 212.66, y1: 105.93, x2: 211.23, y2: 111.87, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".43px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M180.12,122.88s-.75,3.22-3.02.8c0,0-1.34,2.98-2.42.07,0,0-1.48,3.14-2.53-.45,0,0-2.21,2.53-2.46-1.01,0,0-2.63,1.96-2.16-1.37,0,0-2.16.61-1.87-2.12,0,0-3.11.75-1.6-2.42,0,0-3.74-1.29-.62-3.64,0,0-4.07-2.16.34-4.15,0,0-2.53-3.79,1.79-3.88,0,0-1.15-5.83,3.86-3.7,0,0,1.84-6.15,4.9-1.43,0,0,2.07-6.08,4.81.32,0,0,4.53-3.74,4.81,3.03,0,0,6.99-.42,3.09,5.42,0,0,7.68,1.61.47,5.12,0,0,2.48,2.42-.79,2.84,0,0,2.05,2.96-1.45,2.56,0,0,.76,2.68-1.54,1.72,0,0,.69,5.39-3.61,2.3Z", style: {
  fill: "#efe47b",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".67px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M182.47,112.31s-4.39.62-2.97,5.6c0,0-1.96-4.67-3.96-1.11,0,0,1.16-4.82-2.08-2.04,0,0,1.62-2.78-1.54-1.95,0,0,2.96-1.9-.51-3.18,0,0,3.16.28,2.07-2.69,0,0,2.22,2.24,3.33-.7,0,0,0,3.39,2.29,1.85,0,0-.81,2.23,3.43.16,0,0-4.84,3.61-.07,4.05Z", style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".43px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 171.59, y1: 107.72, x2: 167.61, y2: 104.56, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".43px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 169.99, y1: 111.24, x2: 167.64, y2: 111.55, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".43px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 174.08, y1: 115.88, x2: 171.6, y2: 121.27, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".43px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 177.48, y1: 117.34, x2: 177.32, y2: 120.6, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".43px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 184.96, y1: 117.13, x2: 181.66, y2: 114.74, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".43px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 187.19, y1: 110.32, x2: 183.38, y2: 110.86, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".43px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 182.12, y1: 102.96, x2: 180.44, y2: 105.88, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".43px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 172.53, y1: 100.21, x2: 174.54, y2: 106.01, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".43px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 171.88, y1: 114.75, x2: 167.3, y2: 118.79, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".43px"
} }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M8.19,237.91c13.33-7.42,149.98-137.81,287.82,7.52l-43.73,1.47-2.53,46.54s-85.43-102.28-202.38,0l9.36-49.11-48.54-6.41Z", style: {
  fill: "#f9cd5f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "3.04px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M117,238.61l-.66-2.27c3.71-1.68,3.52-2.35,1.89-4.75-1.15-1.67-2.94-4.15-4.57-6.34l-11.99,3.48c-.12,2.1-.31,4.69-.37,6.77-.17,4.25,1.45,4.31,6.43,3.33l.66,2.27-15.18,4.41-.66-2.27c3.73-1.62,4.55-2.45,5.14-8.22l3.56-35.49,2.79-1.41c7.04,9.55,14.39,19.28,21.37,28.38,3.93,5.06,4.61,5.33,8.75,4.66l.66,2.27-17.82,5.17ZM103.06,210.81c-.5,4.94-1.02,9.56-1.26,14.49l9.77-2.84-8.51-11.66Z", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M175.24,196.44c-3.97.33-5.04,1.49-5.31,3.72-.21,1.86-.36,3.96-.41,8.3l-.09,8.12c-.06,5.33-.99,10.72-4.57,14.39-2.89,2.94-7.3,4.19-11.71,4.14-4.09-.05-8.17-.96-11.06-3.35-3.56-2.77-5.26-6.57-5.18-13.52l.18-15.68c.07-5.7-.49-6.14-5.19-6.63l.03-2.29,19.16.22-.03,2.29c-4.66.38-5.22.81-5.28,6.51l-.19,16.49c-.08,6.94,3.28,11.57,9.91,11.65,7.38.08,10.66-5.33,10.77-14.63l.09-7.75c.05-4.28-.11-6.51-.21-8.37-.21-2.85-1.94-3.49-6.28-3.79l.03-2.29,15.38.18-.03,2.29Z", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M206.4,212.33c-.11-4.29-.55-9.27-6.21-10.89-3.57-1.02-6.63.84-7.61,4.28-.97,3.38.73,6.07,5.37,10.32,5.65,5.14,8.92,9.54,7.19,15.63-2.07,7.26-9.61,10.83-18.41,8.32-3.94-1.12-7.15-3.17-9.79-4.86.03-1.52.81-8.22,1.21-11.23l2.56.13c.12,4.23,1.66,11.92,7.69,13.64,3.75,1.07,6.7-.62,7.82-4.56,1.02-3.57-.6-6.29-4.98-10.54-5.42-5.27-8.72-9.54-6.9-15.94,1.84-6.46,8.35-10.13,17.58-7.5,4.25,1.21,7.18,3.05,8.48,3.62-.4,2.35-.89,5.67-1.5,9.69l-2.5-.11Z", style: {
  fill: "#000",
  strokeWidth: 0
} }))));
const ForwardRef = forwardRef(SvgAusCircle);
export default ForwardRef;
