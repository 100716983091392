import * as React from "react";
import { forwardRef } from "react";
const SvgSubmitMystall = (props, ref) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", "data-name": "Layer 1", xmlns: "http://www.w3.org/2000/svg", width: 298.07, height: 298.07, viewBox: "0 0 298.07 298.07", ref, ...props }, /* @__PURE__ */ React.createElement("rect", { width: 298.07, height: 298.07, style: {
  fill: "none",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M14.92,248.69l79.26-79.26s8.48-10.24,17.84,0c9.36,10.24,19.3,20.77,19.3,20.77,0,0,10.14,6.62-2.34,16.59-12.82,10.24-81.89,80.51-81.89,80.51,0,0-8.58,7.2-15.79,0-9.44-9.42-23.4-23.98-23.4-23.98,0,0-3.9-6.84,7.02-14.62Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "2.32px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M111.22,167.92l42.56-42.56v-45.19s0-10.97,5.7-14.48c5.7-3.51,60.54-59.23,60.54-59.23,0,0,6.14-5.26,14.04,0s57.03,56.16,57.03,56.16c0,0,6.14,9.21,0,17.55s-53.96,56.59-53.96,56.59c0,0-2.65,6.73-14.48,7.46-14.89.92-50.89,2.63-50.89,2.63l-41.45,42.12-19.09-21.06Z", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M183.61,75.35l37.73-37.73s17.11-6.14,12.28,13.16l-38.17,35.97s-16.23,5.7-11.85-11.41Z", style: {
  fill: "#fff",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M211.58,100.83l37.73-37.73s17.11-6.14,12.28,13.16l-38.17,35.97s-16.23,5.7-11.85-11.41Z", style: {
  fill: "#fff",
  strokeWidth: 0
} })));
const ForwardRef = forwardRef(SvgSubmitMystall);
export default ForwardRef;
