import * as React from "react";
import { forwardRef } from "react";
const SvgNtCircle = (props, ref) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", "data-name": "Layer 1", xmlns: "http://www.w3.org/2000/svg", width: 298.96, height: 298.07, viewBox: "0 0 298.96 298.07", ref, ...props }, /* @__PURE__ */ React.createElement("rect", { width: 298.07, height: 298.07, style: {
  fill: "none",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("circle", { cx: 153.33, cy: 124.38, r: 120.18, style: {
  fill: "#ccc7e0",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "6.63px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M155.27,126.58s39.82-69.74,102.12-2.85c0,0-44.97,56.53-102.12,2.85Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".75px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M157.83,124.06s30.36-24.51,46.67-27.05c-6.79,4.62-15.13,5.58-23.91,15.37-.68.76,36.96-3.77,69.16,8.33,0,0-34.27-13.46-70.87-2.77,0,0,32.36,16.66,36.11,21.71,0,0-33.66-23.7-57.16-15.58Z", style: {
  fill: "#b2b272",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".75px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M189.87,159.53s-55.13-83-127.03-7.32c0,0,60.44,67.77,127.03,7.32Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".9px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M186.49,156.49s-39.69-29.81-60.15-33.41c8.81,5.66,19.23,7.1,30.94,18.89.9.91-46.2-5.84-85.19,7.02,0,0,41.46-14.38,87.77-.47,0,0-38.83,18.19-43.07,23.94,0,0,39.86-26.36,69.7-15.97Z", style: {
  fill: "#b2b272",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".9px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M155.33,105.02s24.37-65.19-53.15-70.9c0,0-12.92,61.45,53.15,70.9Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".65px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M155.61,101.91s-.25-34.02-7.37-46.49c.62,7.14,4.48,13.32,2.78,24.65-.13.87-17.44-27.16-42.73-42.41,0,0,27.31,15.91,40.01,46.6,0,0-28.39-12.86-33.72-12.65,0,0,33.71,9.89,41.04,30.29Z", style: {
  fill: "#b2b272",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".65px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M84.39,83.73s-.99,33.69,37.6,37.38c0,0,6.92,2.06,7.86-6.92s-7.65-30.67-21.46-39.72c-13.81-9.05-27.07-5.32-24,9.26Z", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".99px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M87.22,71.99s5.98,29.77,25.55,39.73c19.57,9.96,13.33,9.2,16.85,7.21s.41-20.38-10.95-34.32-33.32-19.44-31.45-12.62Z", style: {
  fill: "#ce849d",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.02px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M82.22,139.89s21.67,25.81,52.94,2.9c0,0,6.54-3.06,1.26-10.4-5.27-7.34-26.11-17.82-42.45-15.38-16.33,2.43-23.76,14.04-11.76,22.88Z", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".99px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M76.52,129.23s24.27,18.25,45.51,12.66c21.24-5.59,16.07-2,17.38-5.83,1.31-3.83-13.25-15.48-31.01-18.34-17.75-2.85-37.8,7.65-31.88,11.5Z", style: {
  fill: "#ce849d",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.02px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M162.75,119.44l-18.08-44.17s10.78-15.65,38.95-11.13c28.17,4.52,36.52,35.47,37.91,44.17,1.39,8.69-47.99,15.3-47.99,15.3l-10.78-4.17Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".92px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M164.08,128.79s12.22-12.5,16.58-25.94-1.58-32.81-24.7-29.68c-23.12,3.12-40.62,23.12-39.37,43.43,1.25,20.31,31.27,18.12,32.67,17.81s14.83-5.62,14.83-5.62Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".82px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M187.44,133l24.69,22.95s10.78,3.13,16-22.95c5.22-26.08-17.2-42.43-20.95-42.78s-17.51-3.83-18.1,9.04c-.6,12.87,4.97,30.6-1.64,33.73Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".92px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M152.94,124.37s-7.44-13.09,1.28-16.68c8.73-3.59,15.14-1.54,15.14,6.42l2.57,1.07s2.57-11.08,12.83-7.74c10.27,3.34,14.67,12.58,8.75,16.94-5.92,4.36-9.52,3.08-9.52,3.08,0,0,.81,1.36,1.56,1.05,2.03-.84,5.89-1.99,10.62-1.3,7.07,1.03,5.02,12.06,1.94,13.86-3.08,1.8-7.96,4.62-14.12,2.57,0,0,6.67,3.08,4.88,5.13s-7.96,6.67-11.29,3.85c-3.34-2.82-24.64-28.23-24.64-28.23Z", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.02px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M161.31,137.06s-3.9-7.12.33-11.09l.88,2.46s1.26-5.06,3.21-6.23c0,0,1.19,2.63,1.04,2.93s.15-.31.15-.31c0,0,2.48-5.01,3.28-5.12,0,0,.21,2.48.12,3.13,0,0,2.2-3.48,3.66-4.16,0,0-.39,3.29-.54,3.82s2.94-2.48,4.63-2.58c0,0-1.78,3.98-3,4.7,0,0,2.34-.23,4.25.59,0,0-1.88,2.41-5.36,2.59,0,0,2.78-.17,4.77,1.61,0,0-2.69,1.02-5.06,1.24,0,0,2.29.32,3.83,2.09,0,0-1.62,1.22-4.85,1.21,0,0,1.84.37,2.84,1.96,0,0-1.98.61-5.53.56,0,0,1.06-.41,2.92,2.19,0,0-7.27,4.22-11.58-1.62Z", style: {
  fill: "#f9f9e1",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".87px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M153.5,131.54s-20.18-24.23-16.52-36.74c3.66-12.52-8.61-1.54-9.56,2.82s-15.04,51.09,16.05,67.77c31.09,16.68,24.26-18.99,24.26-18.99l-14.23-14.86Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".85px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M150.82,119.02s15.2,24.88,21.38,26.42c8.27,2.06,10.56.34,16.22-8.36,5.37-8.26,2.15-14.86,11.17-15.69,9.02-.83,24.92,11.7,21.48,24.43-3.44,12.73-17.18,34.14-46.52,18.51-40.48-21.57-36.08-50.89-36.08-50.89,0,0,4.51-10.43,12.35,5.57Z", style: {
  fill: "#ddb3c3",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.11px"
} }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M7.71,237.64c13.33-7.42,149.98-137.81,287.82,7.52l-43.73,1.47-2.53,46.54s-85.43-102.28-202.38,0l9.36-49.11-48.54-6.41Z", style: {
  fill: "#f9cd5f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "3.04px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M151.11,193.03c-3.79,1.05-4.88,2.05-4.67,4.93.16,2.5.27,4.68,1.06,10.19l3.57,25.22-3.55.5-30.91-27.94,2.03,14.32c.78,5.51,1.26,7.97,1.71,9.78.65,2.75,2.19,4.02,6.91,3.61l.33,2.34-15.71,2.23-.33-2.34c3.57-.83,5.02-2,4.97-5.04-.07-2.32-.27-4.62-1.05-10.13l-2.13-15.02c-.3-2.09-.62-3.47-2.06-4.94-1.37-1.42-2.91-1.4-5.61-1.27l-.33-2.34,12.36-1.75,28.12,24.65-1.62-11.41c-.78-5.51-1.14-7.6-1.66-9.91-.58-2.7-1.78-3.89-7.08-3.46l-.33-2.34,15.65-2.22.33,2.34Z", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M198.8,209.14c-.15-3.02-1.09-5.67-1.56-6.67-.89-1.53-1.3-2.13-5.01-2.82l-4.03-.75-5.51,29.57c-.98,5.28-.43,5.78,5.09,7.26l-.43,2.33-20.89-3.89.43-2.33c5.43.56,6.07.28,7.05-5l5.51-29.57-3.84-.71c-3.52-.66-4.64-.28-6.11,1.01-1.1,1.03-2.41,3.13-3.69,5.5l-2.39-.45c1.3-4.51,2.65-9.34,3.34-12.33l1.89.35c.67,1.62,1.41,1.89,3.29,2.24l25.17,4.69c1.7.32,2.65.1,3.94-.89l1.82.34c-.43,2.65-1.21,8.24-1.66,12.39l-2.43-.26Z", style: {
  fill: "#000",
  strokeWidth: 0
} }))));
const ForwardRef = forwardRef(SvgNtCircle);
export default ForwardRef;
