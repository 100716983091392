import * as React from "react";
import { forwardRef } from "react";
const SvgNsw = (props, ref) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", "data-name": "Layer 1", xmlns: "http://www.w3.org/2000/svg", width: 298.07, height: 298.07, viewBox: "0 0 298.07 298.07", ref, ...props }, /* @__PURE__ */ React.createElement("rect", { width: 298.07, height: 298.07, style: {
  fill: "none",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M147.71,141.86s-2.86-8.09-36.07-6.14c-33.22,1.95-51.92-4.47-51.92-4.47,0,0-5.48-1.54-9.35-.49s0,4.71,0,4.71c0,0,19.31,18.63,47.81,10.89s44.08-8.9,49.53-4.5Z", style: {
  fill: "#a9af6e",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.1px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M149.72,181.91s25.68-3.8,49.13-18.37c23.45-14.58,39.23-17.81,39.23-17.81,0,0,5.59-.76.26,4.99-5.32,5.75-7.1,16.18-15.15,21.76-8.05,5.58-50.18,17.56-71.18,10.45", style: {
  fill: "#a9af6e",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.27px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M148.32,150.1s-9.05-9.91-27.54,0-21.98,10.33-29.1,11.72-21.21,8.51-4.19,10.89,23.34-9.6,28.81-13.96,17.48-12.28,32.02-8.65Z", style: {
  fill: "#a9af6e",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.1px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M151.99,139.77s38.64-10.26,60.41-15.91c0,0,13.94-3.96,25.54-9,10.78-4.69,21.14-14.77,21.14-14.77,0,0,8.79-15.18-5.23-12.04s-41.66,22.21-48.15,26.81-53.71,24.9-53.71,24.9Z", style: {
  fill: "#a9af6e",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.1px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M141.51,111.51s-.55,21.58,4.33,36.21c5.3,15.91,5.85,43.54,1.88,45.22l13.79,2.23s.06-17.14,0-24.14c-.04-5.05-2.14-16.64-5.32-27.49-.51-1.75-5.04-15.19-1.79-27.34,3.47-12.99-12.88-4.69-12.88-4.69Z", style: {
  fill: "#efd7a0",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.1px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M149.08,119.19s-41.35-26.17-48.32-33.93c-6.8-7.56-16.89-19.05,5.79-44.73,22.68-25.68,31.05-30.98,34.12-33.22s8.45-5.58,20.83,1.12c12.38,6.7,31.44,23.45,37.82,29.03s19.78,26.8,6.38,38.8-28.3,43.96-56.63,42.93Z", style: {
  fill: "#c45b79",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.1px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M147.71,119.47s1.22.42,7.73-3.98,16.56-7.33,22.84-6.28,24.95-5.86,28.7-8.58c0,0-20.33,19.26-27.86,21.35s-31.41-2.51-31.41-2.51Z", style: {
  fill: "#b9002f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.1px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M145.83,114.86s-.21-11.72-5.02-17.24-19.26-13.4-20.03-18.21c0,0,2.86,25.11,7.26,28.81s16.61,6.84,17.79,6.64Z", style: {
  fill: "#b9002f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.1px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M71.98,112.13s14.72,11.44,23.45,12.91,20.17-4.66,22.68-6.83,21.21-7.2,30.22,1.39c0,0-16.33-11.65-31.19-11.37-14.86.28-24.41,5.27-26.73,5.3s-16.4-.07-18.42-1.4Z", style: {
  fill: "#b9002f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.1px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M173.47,101.95c-.7-.03,4.61-11.72,14.65-17.45s15.21-3.91,21.91-7.68c6.7-3.77,15.21-22.05,15.21-23.45,0,0-2.63,50.97-51.78,48.57Z", style: {
  fill: "#b9002f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.1px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M124.34,103.06c.42.13-21.77-8.93-29.73-16.89-7.96-7.96-7.26-19.4-30.84-22.47,0,0,12.7,24.7,28.05,31.12s24.98,5.86,32.52,8.23Z", style: {
  fill: "#b9002f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.1px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M152.67,108.78s36.65-14.79,24.68-41.45c0,0,.45,10.61-4.58,14.65s-19.38,18.72-20.1,26.8Z", style: {
  fill: "#b9002f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.1px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M150.44,110.18s0-16.05-4.61-27.91c-4.61-11.86,4.61-21.91,4.61-21.91,0,0,8.2,8.93,5,21.77-3.19,12.84-5.28,24.98-5,28.05Z", style: {
  fill: "#b9002f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.1px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M120.78,96.43c.09.72-12-9.94-13.68-15.6s.31-12.77,3.04-17.58c0,0-2.83,7.64.52,12.04,3.35,4.4,9.56,16.54,10.12,21.14Z", style: {
  fill: "#b9002f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.1px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M192.04,77.7s33.36-21.2,16.74-45.24c0,0,2.36,10.35-1.84,15.24-4.21,4.89-15.66,21.92-14.9,30Z", style: {
  fill: "#b9002f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.1px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M103.21,79.86s-33.36-21.2-16.74-45.24c0,0-2.36,10.35,1.84,15.24,4.21,4.89,15.66,21.92,14.9,30Z", style: {
  fill: "#b9002f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.1px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M161.96,82.18s3.31-13.1,1.99-23.73,8.27-16.93,8.27-16.93c0,0,4.85,8.98-.4,18.8-5.25,9.82-9.46,19.3-9.86,21.86Z", style: {
  fill: "#b9002f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".93px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M137.22,86.94s-3.31-13.1-1.99-23.73c1.31-10.63-8.27-16.93-8.27-16.93,0,0-4.85,8.98.4,18.8,5.25,9.82,9.46,19.3,9.86,21.86Z", style: {
  fill: "#b9002f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".93px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M182.88,66.41s3.31-13.1,1.99-23.73c-1.31-10.63,8.27-16.93,8.27-16.93,0,0,4.85,8.98-.4,18.8-5.25,9.82-9.46,19.3-9.86,21.86Z", style: {
  fill: "#b9002f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".93px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M116.31,68.24s-3.31-13.1-1.99-23.73c1.31-10.63-8.27-16.93-8.27-16.93,0,0-4.85,8.98.4,18.8,5.25,9.82,9.46,19.3,9.86,21.86Z", style: {
  fill: "#b9002f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".93px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M179.45,39.76s10.48-23.64-7.28-30.7c0,0,5.04,4.78,4.52,8.97s-.58,17.61,2.76,21.74Z", style: {
  fill: "#b9002f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".72px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M121.68,41.1s-10.48-23.64,7.28-30.7c0,0-5.04,4.78-4.52,8.97.52,4.19.58,17.61-2.76,21.74Z", style: {
  fill: "#b9002f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".72px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M5.71,236.13c13.33-7.42,149.98-137.81,287.82,7.52l-43.73,1.47-2.53,46.54s-85.43-102.28-202.38,0l9.36-49.11-48.54-6.41Z", style: {
  fill: "#f9cd5f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "3.04px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M119.85,195.11c-3.58,1.64-4.49,2.8-3.83,5.61.55,2.44,1.01,4.58,2.66,9.9l7.53,24.33-3.42,1.06-34.96-22.68,4.28,13.82c1.65,5.32,2.51,7.67,3.24,9.38,1.08,2.61,2.8,3.62,7.4,2.47l.7,2.26-15.16,4.69-.7-2.26c3.39-1.38,4.63-2.77,4.11-5.76-.44-2.28-1-4.52-2.64-9.83l-4.48-14.49c-.62-2.02-1.16-3.33-2.82-4.56-1.57-1.19-3.1-.92-5.74-.37l-.7-2.26,11.92-3.69,31.68,19.88-3.41-11.01c-1.65-5.32-2.33-7.32-3.21-9.52-1-2.57-2.37-3.55-7.54-2.29l-.7-2.26,15.1-4.67.7,2.26Z", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M156.03,201.8c-1.37-4.14-3.27-8.85-9.25-8.75-3.77.06-6.19,2.77-6.13,6.41.06,3.57,2.5,5.68,8.27,8.44,7.01,3.33,11.49,6.63,11.59,13.07.13,7.67-6.15,13.36-15.44,13.52-4.16.07-7.88-.97-10.95-1.83-.42-1.49-1.63-8.23-2.14-11.27l2.52-.63c1.37,4.07,5.12,11.1,11.49,10.99,3.96-.07,6.33-2.58,6.26-6.73-.06-3.77-2.44-5.94-7.95-8.77-6.82-3.53-11.29-6.7-11.41-13.46-.11-6.82,5.12-12.31,14.87-12.47,4.48-.07,7.88.84,9.31,1.02.3,2.4.81,5.77,1.4,9.86l-2.46.63Z", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M230.85,211.61c-4.57-.82-5.65-.15-8.92,4.33-3.05,4.21-12.14,17.27-19.86,28.54l-3.42-1.06c-.38-9.77-1.05-19.9-1.69-30.15-6.05,8.24-11.88,16.21-17.5,24.18l-3.54-1.1c.23-9.38.26-23.24.2-35.12-.08-5.12-.68-6.44-4.29-7.97l.7-2.26,18.46,5.74-.7,2.26c-4.72-1-5.12-.12-5.26,3.33-.17,7.65-.25,15.47-.31,22.76,5.79-7.65,11.6-15.96,17.35-24.56l3.24,1.01c.45,10.4,1.11,20.58,1.69,30.55,4.31-5.9,10.42-15.19,12.36-18.41,1.98-3.34,1.71-4.63-3.42-6.69l.7-2.26,14.91,4.64-.7,2.26Z", style: {
  fill: "#000",
  strokeWidth: 0
} }))));
const ForwardRef = forwardRef(SvgNsw);
export default ForwardRef;
