import * as React from "react";
import { forwardRef } from "react";
const SvgYellowMinus = (props, ref) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", "data-name": "Layer 1", xmlns: "http://www.w3.org/2000/svg", width: 298.07, height: 298.07, viewBox: "0 0 298.07 298.07", ref, ...props }, /* @__PURE__ */ React.createElement("rect", { width: 298.07, height: 298.07, style: {
  fill: "none",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("g", { id: "Plus" }, /* @__PURE__ */ React.createElement("circle", { cx: 149.41, cy: 150.27, r: 95.57, style: {
  fill: "#f9cd5f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "3.55px"
} }), /* @__PURE__ */ React.createElement("rect", { x: 80.57, y: 134.32, width: 137.68, height: 32.24, style: {
  fill: "#fff",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "3.55px"
} })));
const ForwardRef = forwardRef(SvgYellowMinus);
export default ForwardRef;
