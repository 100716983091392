import * as React from "react";
import { forwardRef } from "react";
const SvgTasCircle = (props, ref) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", "data-name": "Layer 1", xmlns: "http://www.w3.org/2000/svg", width: 298.07, height: 298.07, viewBox: "0 0 298.07 298.07", ref, ...props }, /* @__PURE__ */ React.createElement("rect", { width: 298.07, height: 298.07, style: {
  fill: "none",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("circle", { cx: 148.31, cy: 124.11, r: 120.18, style: {
  fill: "#ccc7e0",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "6.63px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M126.7,45.9s.94,14.52,15.02,24.56c14.08,10.04,65.82,31.38,84,65.85,18.17,34.47,17.56,44.77,17.56,44.77,0,0,1.57-34.85-26.36-83.34-27.04-46.94-70.98-65.98-86.02-55.98l-4.19,4.14Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".8px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M138.44,48.23c1.68.46,88.93,23.84,105.2,128.35,0,0-17.21-75.7-72.33-108.95,0,0-29.81-17.12-48.27-19.44l-2.28-2.49,1.83-.47s-.97-4.08,15.85,3.01Z", style: {
  fill: "#b2b272",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".78px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M111.2,62.68s.69,9.23-7.5,15.79c-8.19,6.56-39.66,20.82-48.3,42.95-8.64,22.12-7.33,28.65-7.33,28.65,0,0-4.13-22.09,9.55-53.25,13.25-30.16,39.89-42.85,50.5-36.71l3.08,2.57Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".51px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M103.83,64.32c-1.04.32-55.24,16.36-56.39,82.89,0,0,4.34-48.27,36.93-70.13,0,0,17.7-11.28,29.4-13.01l1.25-1.61-1.22-.28s.26-2.6-9.96,2.13Z", style: {
  fill: "#b2b272",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".5px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M147.06,73.91s1.84,11.13-7.4,20.06c-9.24,8.93-45.94,29.92-54.05,57.8-8.11,27.88-5.81,35.66-5.81,35.66,0,0-7.4-26.36,5.86-65.8,12.84-38.18,43.84-56.7,57.38-50.47l4.01,2.76Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".63px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M138.28,76.76c-1.23.51-65.35,26.3-59.56,107.27,0,0,.05-59.15,37.29-89.51,0,0,20.29-15.76,34.32-19.22l1.34-2.1-1.52-.19s.03-3.19-11.87,3.75Z", style: {
  fill: "#b2b272",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".61px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M154.17,73.68s-8.61,10.99-3.8,26.65c4.81,15.66,32.48,62.05,24.81,97.71-7.66,35.67-14.79,42.69-14.79,42.69,0,0,23.72-24.08,32.61-75.68,8.6-49.94-14.31-89.99-32.8-91.84l-6.03.45Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".79px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M162.07,82.4c1.05,1.34,55.85,70.5,1.51,155.32,0,0,35.01-64.7,12.31-121.67,0,0-12.84-30.19-26.12-42.94l-.22-3.16,1.77.76s1.86-3.51,10.75,11.68Z", style: {
  fill: "#b2b272",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".77px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M142.39,40.58s2.5,8.89,12.39,12.02,44.19,4.92,59.82,22.43c15.62,17.52,16.62,24.11,16.62,24.11,0,0-3.66-22.18-27.08-46.29-22.67-23.34-51.92-25.43-59.74-15.79l-2,3.53Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".51px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M149.84,39.41c1.08-.09,57.25-4.98,81,56.83,0,0-20.51-43.56-58.44-52.04,0,0-20.4-4.05-31.93-1.37l-1.72-1.05,1.05-.71s-1.13-2.34,10.04-1.66Z", style: {
  fill: "#b2b272",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".5px"
} }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M119.24,32.67s-.3-7.6,3.89-11.12c0,0,5.4,4.99,5.46,12.97,0,0,9.3-7.37,12.67-7.61,0,0-2.87,6.98-7.03,11.17-.94.94,7.83-6.89,12.83-5.62,0,0-6.45,5.95-10.22,9.29,0,0,7.77.61,13.45,2.52,0,0-6.15,3.81-12.15,5.22,1.58,1.04,10.86,8.36,10.29,11.1,0,0-7.3-3.03-13.74-4.93,0,0,5.74,9.56,5.44,15.68,0,0-5.23-3.71-12.1-10.7,0,0,1.32,10.05-2.39,17.41,0,0-5.41-10.02-5.84-16.84,0,0-4.02,10.54-10.93,13.8,0,0-.41-11.08,2.99-16.71,0,0-10.68,8.13-15.48,4.07,0,0,4.84-6.93,13.46-11.46,0,0-11.37,3.49-15.43-1.4,0,0,2.51-5.05,10.45-5.17,6.58-.1.02,0,0,0-1.03-.45-5.19-3.59-7.31-8.96,0,0,9.27-4.42,15.24.67,0,0-5.57-4.51-3.86-10.47,0,0,3.9-.16,10.32,7.1Z", style: {
  fill: "#f9f9e1",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.03px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M120.98,35.85s.21-.63,1.62-1.95c0,0,2.08-.7,2.25,2.12,0,0,1.71-1.38,2.82-1.41,0,0,1.51.63.66,2.78-.18.44,1.12-.46,2.91-.12,0,0,1.48,1.99.21,3.24,0,0,.19.33,1.83,1.89,0,0,.03,2.39-2.04,2.36.58.33,1.16,1.43,1.01,2.4,0,0-.12,1.91-2.43,1.37,0,0-.31.43-.3,2.59,0,0-.97,2.59-3.53.27,0,0-.29-.28-1.46,2.39,0,0-3.07.96-3.35-1.43,0,0-.24-.59-2.62.7,0,0-2.73-.57-1.63-2.62,0,0-1.24-.25-3.01-1.58,0,0-1.73-1.05,1.22-2.83,0,0-.64-.03-2.16-1.67,0,0-.18-2.92,2.62-2.69,0,0,.98.66.97.66-.37-.14-1.9-1.16-2.75-3.01,0,0,1.27-3.06,3.47-1.39,0,0-.14-.16.36-2.3,0,0,.91-2.18,3.31.25Z", style: {
  fill: "#f9f9e1",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".69px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M118.09,37.09s-5.24-9.72-2.89-13.55c0,0,7.38,4.99,6.84,12.01,0,0,2.27-9.72,7.39-12.58,0,0-.07,7.52-1.37,12.88-.5,2.09,2.89-10.32,7.01-10.41,0,0-.68,10.05-3.2,13.38,0,0,7.13-3.47,11.62-.61,0,0-5.11,6.39-9.68,7.36,1.46.5,10.48,2.63,11.11,4.57,0,0-9.96,2.31-15.4.99,0,0,9.4,4.41,10.64,9.51,0,0-6.26-1.94-13.19-6.14,0,0,4.8,8.03,3.74,14.91,0,0-5.97-7.64-7.36-14.2,0,0-.97,11.7-6.18,15.47,0,0,.03-11.23,1.99-16.6,0,0-9.21,12.93-13.85,10.67,0,0,.89-5.88,6.38-11.54,0,0-7.42,2.87-11.69-.24,0,0,6.04-5.14,12.32-6.01.02,0-5.57-.59-5.59-.59-.89-.14-4.83-1.78-7.74-5.72,0,0,9.01-3.4,14.79-.56,0,0-8.33-4.77-8.46-10.05,0,0,6.12,2.54,12.77,7.06Z", style: {
  fill: "#f9f9e1",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".85px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M118.59,34.49s.29-.87,2.27-2.72c0,0,2.91-.97,3.15,2.96,0,0,2.38-1.93,3.95-1.97,0,0,2.12.88.93,3.88-.25.62,1.57-.65,4.06-.16,0,0,2.06,2.78.29,4.53,0,0,.27.46,2.56,2.64,0,0,.05,3.34-2.85,3.29.81.47,1.62,1.99,1.41,3.36,0,0-.17,2.66-3.39,1.91,0,0-.43.59-.42,3.62,0,0-1.36,3.63-4.93.38,0,0-.4-.39-2.04,3.34,0,0-4.29,1.35-4.69-2,0,0-.34-.82-3.66.98,0,0-3.81-.79-2.28-3.67,0,0-1.73-.34-4.2-2.21,0,0-2.42-1.47,1.71-3.95,0,0-.89-.04-3.02-2.33,0,0-.25-4.08,3.66-3.77.01,0,1.36.92,1.35.92-.52-.19-2.65-1.62-3.84-4.21,0,0,1.78-4.28,4.85-1.95,0,0-.19-.22.5-3.21,0,0,1.28-3.05,4.63.34Z", style: {
  fill: "#f9cd5f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".97px"
} }), /* @__PURE__ */ React.createElement("ellipse", { cx: 121.04, cy: 45.16, rx: 9.39, ry: 8.35, transform: "translate(-7.04 28.29) rotate(-12.96)", style: {
  fill: "#b2b272",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".82px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M169.07,57.98s4.45-7.69,10.78-8.32c0,0,2.19,8.55-2.73,16.45,0,0,13.76-.99,17.23,1.05,0,0-7.19,4.94-13.9,6.26-1.51.3,12.02-1.5,16.14,3.11,0,0-10.06,1.51-15.87,2.25,0,0,7.27,5.83,11.66,11.54,0,0-8.43-.4-15.22-3.04.91,2.09,5.46,15.54,3.19,17.86,0,0-5.29-7.91-10.44-14.11,0,0-.32,13.28-4.44,19.1,0,0-2.83-7.18-5.22-18.68,0,0-4.98,10.79-13.24,15.52,0,0,.94-13.5,4.78-20.51,0,0-10.54,7.67-19.38,6.23,0,0,6.52-11.18,13.39-14.43,0,0-15.6.81-17.78-6.42,0,0,9.09-3.56,20.41-2.22,0,0-13.38-4.23-14.31-11.77,0,0,5.62-3.28,13.52,1.94,6.54,4.33.01.02,0,0-.73-1.13-2.87-7.03-1.6-13.75,0,0,11.89,1.9,14.58,10.92,0,0-2.67-8.19,2.75-12.91,0,0,3.93,2.47,5.72,13.94Z", style: {
  fill: "#f9f9e1",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.21px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M168.8,62.29s.6-.48,2.81-.83c0,0,2.48.72.89,3.6,0,0,2.55-.21,3.66.52,0,0,1.1,1.64-1.08,3.18-.45.32,1.39.3,2.94,1.84,0,0,.21,2.95-1.82,3.33,0,0-.02.45.62,3.09,0,0-1.46,2.37-3.48.95.36.72.25,2.18-.51,3.05,0,0-1.31,1.8-3.24-.29,0,0-.57.21-1.91,2.35,0,0-2.58,1.9-3.64-2.11,0,0-.11-.47-2.93,1.37,0,0-3.63-1.12-2.4-3.67,0,0,.13-.74-3.01-1.07,0,0-2.33-2.4.03-3.68,0,0-1.07-1.08-1.97-3.58,0,0-1.05-2.2,2.97-1.96,0,0-.61-.46-1.08-3.1,0,0,1.65-3,4.26-.89,0,0,.55,1.31.54,1.3-.28-.38-1.14-2.42-.82-4.82,0,0,3.17-2.16,4.29.97,0,0-.03-.25,1.79-2.02,0,0,2.26-1.53,3.11,2.47Z", style: {
  fill: "#f9f9e1",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".81px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M165.18,61.56s.92-13.1,5.63-15.28c0,0,4.14,9.88-.77,16.43,0,0,8.31-8.04,15.14-7.4,0,0-4.77,7.36-9.4,11.76-1.8,1.71,9.3-8.22,13.41-5.52,0,0-6.95,9.43-11.52,11.01,0,0,9.19,1.38,11.82,7.22,0,0-9.02,2.85-14.13.72,1.12,1.47,8.67,9.65,8.08,11.98,0,0-11.25-4.43-15.78-9.39,0,0,6.49,10.67,4.53,16.53,0,0-4.95-6.13-9.15-14.93,0,0-.29,11.14-5.64,17.19,0,0-1.11-11.54,1.64-18.93,0,0-8.27,10.86-15.76,11.07,0,0,7.05-11.03,12.34-15,0,0-17.15,6.53-20.31,1.18,0,0,4.55-5.19,13.49-7.06,0,0-9.1-2.18-11.36-8.11,0,0,9.16-.99,15.88,2.39.02,0-5.11-4.33-5.13-4.35-.79-.73-3.64-5-4.04-10.85,0,0,11,2.72,14.91,9.41,0,0-5.22-10.31-2.05-15.6,0,0,4.43,6.62,8.15,15.55Z", style: {
  fill: "#f9f9e1",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M167.29,59.34s.83-.66,3.93-1.16c0,0,3.47,1,1.25,5.03,0,0,3.56-.3,5.12.72,0,0,1.53,2.29-1.51,4.44-.63.44,1.95.42,4.1,2.57,0,0,.29,4.13-2.55,4.66,0,0-.02.63.87,4.32,0,0-2.04,3.32-4.86,1.32.5,1,.35,3.05-.71,4.26,0,0-1.83,2.51-4.53-.4,0,0-.8.29-2.68,3.28,0,0-3.6,2.65-5.08-2.95,0,0-.15-.65-4.1,1.91,0,0-5.07-1.57-3.36-5.13,0,0,.18-1.04-4.21-1.5,0,0-3.25-3.35.05-5.15,0,0-1.49-1.5-2.75-5.01,0,0-1.46-3.08,4.15-2.73,0,0-.86-.64-1.51-4.33,0,0,2.31-4.19,5.96-1.24.01,0,.76,1.83.76,1.82-.39-.54-1.6-3.38-1.15-6.73,0,0,4.43-3.02,5.99,1.35,0,0-.05-.35,2.5-2.82,0,0,3.16-2.14,4.34,3.46Z", style: {
  fill: "#f9cd5f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.14px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M173.16,76.07c-2.09,4.99-8.33,6.98-13.92,4.45-5.6-2.53-8.44-8.63-6.34-13.62s8.33-6.98,13.92-4.45,8.44,8.63,6.34,13.62Z", style: {
  fill: "#b2b272",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".97px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M121.17,96.43s-.26-6.51,3.33-9.51c0,0,4.62,4.27,4.67,11.1,0,0,7.96-6.3,10.85-6.51,0,0-2.46,5.97-6.01,9.56-.8.81,6.7-5.89,10.98-4.81,0,0-5.52,5.09-8.75,7.95,0,0,6.65.52,11.51,2.16,0,0-5.26,3.26-10.4,4.47,1.35.89,9.29,7.15,8.81,9.5,0,0-6.25-2.6-11.76-4.22,0,0,4.91,8.18,4.66,13.42,0,0-4.48-3.18-10.36-9.16,0,0,1.13,8.6-2.05,14.9,0,0-4.63-8.57-5-14.41,0,0-3.44,9.02-9.35,11.81,0,0-.35-9.48,2.56-14.3,0,0-9.14,6.96-13.25,3.48,0,0,4.14-5.93,11.52-9.81,0,0-9.73,2.99-13.2-1.2,0,0,2.15-4.32,8.94-4.43,5.63-.09.02,0,0,0-.88-.38-4.44-3.07-6.26-7.67,0,0,7.93-3.78,13.04.57,0,0-4.77-3.86-3.3-8.96,0,0,3.33-.13,8.83,6.08Z", style: {
  fill: "#f9f9e1",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".88px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M122.66,99.15s.18-.54,1.39-1.67c0,0,1.78-.6,1.93,1.81,0,0,1.46-1.18,2.42-1.21,0,0,1.3.54.57,2.38-.15.38.96-.4,2.49-.1,0,0,1.26,1.7.18,2.78,0,0,.16.28,1.57,1.62,0,0,.03,2.04-1.75,2.02.49.29.99,1.22.86,2.06,0,0-.1,1.63-2.08,1.17,0,0-.26.36-.26,2.22,0,0-.83,2.22-3.02.23,0,0-.25-.24-1.25,2.05,0,0-2.63.82-2.87-1.23,0,0-.21-.5-2.24.6,0,0-2.33-.49-1.4-2.25,0,0-1.06-.21-2.57-1.35,0,0-1.48-.9,1.05-2.42,0,0-.55-.02-1.85-1.43,0,0-.15-2.5,2.24-2.31,0,0,.83.57.83.56-.32-.12-1.62-.99-2.35-2.58,0,0,1.09-2.62,2.97-1.19,0,0-.12-.14.3-1.97,0,0,.78-1.87,2.83.21Z", style: {
  fill: "#f9f9e1",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".59px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M120.19,100.21s-4.48-8.32-2.47-11.6c0,0,6.31,4.27,5.86,10.27,0,0,1.94-8.32,6.33-10.77,0,0-.06,6.44-1.17,11.02-.43,1.78,2.47-8.84,6-8.91,0,0-.58,8.6-2.74,11.45,0,0,6.1-2.97,9.94-.53,0,0-4.37,5.47-8.29,6.29,1.25.43,8.97,2.25,9.51,3.91,0,0-8.52,1.98-13.18.85,0,0,8.04,3.77,9.11,8.14,0,0-5.36-1.66-11.29-5.25,0,0,4.11,6.87,3.2,12.76,0,0-5.11-6.53-6.29-12.15,0,0-.83,10.01-5.29,13.24,0,0,.03-9.61,1.7-14.21,0,0-7.88,11.07-11.85,9.13,0,0,.76-5.03,5.46-9.88,0,0-6.35,2.45-10.01-.2,0,0,5.17-4.4,10.54-5.14.02,0-4.76-.51-4.78-.51-.76-.12-4.13-1.52-6.63-4.9,0,0,7.71-2.91,12.66-.48,0,0-7.13-4.08-7.24-8.6,0,0,5.23,2.17,10.92,6.04Z", style: {
  fill: "#f9f9e1",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".72px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M120.62,97.99s.25-.75,1.94-2.33c0,0,2.49-.83,2.69,2.53,0,0,2.04-1.66,3.38-1.69,0,0,1.81.75.79,3.32-.21.53,1.34-.55,3.48-.14,0,0,1.77,2.38.25,3.88,0,0,.23.39,2.19,2.26,0,0,.04,2.86-2.44,2.82.69.4,1.38,1.71,1.21,2.87,0,0-.14,2.28-2.9,1.63,0,0-.37.51-.36,3.1,0,0-1.16,3.1-4.22.32,0,0-.34-.33-1.75,2.86,0,0-3.67,1.15-4.01-1.72,0,0-.29-.7-3.13.84,0,0-3.26-.68-1.95-3.14,0,0-1.48-.29-3.6-1.89,0,0-2.07-1.26,1.46-3.38,0,0-.76-.03-2.58-2,0,0-.21-3.5,3.13-3.22,0,0,1.17.79,1.16.79-.44-.16-2.27-1.39-3.29-3.6,0,0,1.52-3.66,4.15-1.66,0,0-.16-.19.43-2.75,0,0,1.09-2.61,3.96.29Z", style: {
  fill: "#f9cd5f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".83px"
} }), /* @__PURE__ */ React.createElement("ellipse", { cx: 122.71, cy: 107.12, rx: 8.03, ry: 7.14, transform: "translate(-20.89 30.24) rotate(-12.96)", style: {
  fill: "#b2b272",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".7px"
} }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M6.71,236.91c12.75-7.37,143.45-136.81,275.27,7.47l-41.82,1.46-2.42,46.2s-81.71-101.54-193.55,0l8.95-48.76-46.43-6.37Z", style: {
  fill: "#f9cd5f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "2.96px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M118.87,205.76c-1.35-2.61-3.22-4.55-4.01-5.24-1.37-.98-1.96-1.34-5.4-.4l-3.73,1.02,7.24,28.42c1.29,5.08,1.97,5.28,7.26,4.28l.57,2.24-19.34,5.31-.57-2.24c4.84-1.78,5.28-2.29,3.98-7.37l-7.24-28.42-3.55.97c-3.26.89-4.06,1.7-4.79,3.45-.52,1.37-.78,3.77-.93,6.4l-2.21.61c-.72-4.53-1.51-9.36-2.13-12.29l1.75-.48c1.23,1.15,1.96,1.08,3.71.6l23.3-6.39c1.57-.43,2.3-1.02,2.99-2.44l1.69-.46c.71,2.52,2.29,7.78,3.58,11.63l-2.17.79Z", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M149.44,234.51l.06-2.39c3.93-.48,3.95-1.19,3.14-3.99-.58-1.95-1.5-4.88-2.38-7.48l-12.12-.3c-.73,1.98-1.66,4.41-2.32,6.4-1.4,4.03.08,4.59,4.98,5.16l-.06,2.39-15.35-.38.06-2.39c3.93-.42,4.94-.97,7.17-6.34l13.7-33.03,3-.51c3.72,11.33,7.68,22.91,11.47,33.79,2.16,6.06,2.71,6.52,6.74,7.14l-.06,2.39-18.03-.45ZM144.68,203.54c-1.91,4.6-3.75,8.88-5.41,13.55l9.88.25-4.47-13.8Z", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M198.93,213.17c-.04-4.33-.4-9.36-5.87-11.07-3.45-1.08-6.45.74-7.46,4.2-.99,3.4.62,6.13,5.07,10.48,5.42,5.27,8.53,9.75,6.75,15.87-2.12,7.29-9.5,10.77-18.01,8.11-3.81-1.19-6.9-3.31-9.44-5.05.06-1.53.91-8.27,1.34-11.31l2.48.17c.06,4.26,1.43,12.04,7.26,13.87,3.63,1.14,6.52-.52,7.67-4.47,1.04-3.58-.49-6.35-4.68-10.7-5.18-5.4-8.33-9.76-6.46-16.18,1.89-6.49,8.26-10.08,17.18-7.29,4.11,1.29,6.93,3.18,8.19,3.78-.43,2.36-.94,5.7-1.6,9.74l-2.42-.15Z", style: {
  fill: "#000",
  strokeWidth: 0
} }))));
const ForwardRef = forwardRef(SvgTasCircle);
export default ForwardRef;
