import * as React from "react";
import { forwardRef } from "react";
const SvgTas = (props, ref) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", "data-name": "Layer 1", xmlns: "http://www.w3.org/2000/svg", width: 298.07, height: 298.07, viewBox: "0 0 298.07 298.07", ref, ...props }, /* @__PURE__ */ React.createElement("rect", { width: 298.07, height: 298.07, style: {
  fill: "none",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M125.71,38.19s1.25,18.7,20.07,31.62,87.96,40.4,112.25,84.78c24.29,44.38,23.47,57.64,23.47,57.64,0,0,2.1-44.87-35.23-107.3-36.13-60.43-94.85-84.94-114.96-72.07l-5.6,5.33Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.05px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M141.4,41.18c2.25.59,118.84,30.69,140.58,165.24,0,0-23-97.46-96.67-140.27,0,0-39.84-22.04-64.5-25.03l-3.05-3.2,2.45-.61s-1.29-5.25,21.18,3.88Z", style: {
  fill: "#b2b272",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.03px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M104.99,59.79s.93,11.88-10.02,20.33c-10.94,8.45-53,26.81-64.55,55.29-11.55,28.48-9.79,36.88-9.79,36.88,0,0-5.52-28.44,12.77-68.55s53.31-55.16,67.48-47.26l4.11,3.31Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".67px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M95.14,61.9c-1.4.41-73.83,21.06-75.36,106.72,0,0,5.79-62.14,49.35-90.29,0,0,23.66-14.52,39.29-16.75l1.67-2.07-1.64-.35s.35-3.35-13.31,2.75Z", style: {
  fill: "#b2b272",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".66px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M152.92,74.24s2.46,14.33-9.89,25.82c-12.35,11.49-61.39,38.51-72.23,74.41-10.84,35.9-7.76,45.91-7.76,45.91,0,0-9.9-33.93,7.83-84.72,17.16-49.16,58.59-73,76.69-64.98l5.36,3.56Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".82px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M141.19,77.91c-1.65.65-87.33,33.86-79.6,138.11,0,0,.07-76.15,49.83-115.23,0,0,27.11-20.29,45.86-24.75l1.79-2.71-2.03-.25s.05-4.11-15.86,4.83Z", style: {
  fill: "#b2b272",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".8px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M162.42,73.95s-11.51,14.15-5.08,34.31c6.43,20.16,43.4,79.88,33.16,125.8-10.24,45.92-19.77,54.97-19.77,54.97,0,0,31.7-31.01,43.58-97.43,11.5-64.3-19.12-115.86-43.84-118.24l-8.05.58Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.03px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M172.98,85.18c1.4,1.73,74.63,90.77,2.02,199.97,0,0,46.79-83.29,16.45-156.64,0,0-17.15-38.87-34.9-55.28l-.3-4.06,2.37.98s2.48-4.52,14.37,15.04Z", style: {
  fill: "#b2b272",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.01px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M146.68,31.34s3.34,11.44,16.56,15.47c13.22,4.03,59.06,6.33,79.94,28.88,20.88,22.56,22.21,31.04,22.21,31.04,0,0-4.9-28.55-36.19-59.6-30.29-30.05-69.38-32.74-79.84-20.33l-2.68,4.55Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".67px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M156.64,29.83c1.45-.11,76.5-6.42,108.24,73.16,0,0-27.4-56.08-78.1-67,0,0-27.26-5.21-42.67-1.76l-2.29-1.35,1.4-.91s-1.51-3.01,13.42-2.14Z", style: {
  fill: "#b2b272",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".66px"
} }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M6.71,235.57c13.33-7.42,149.98-137.81,287.82,7.52l-43.73,1.47-2.53,46.54s-85.43-102.28-202.38,0l9.36-49.11-48.54-6.41Z", style: {
  fill: "#f9cd5f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "3.04px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M123.98,204.19c-1.41-2.62-3.36-4.59-4.2-5.28-1.43-.99-2.05-1.35-5.64-.4l-3.9,1.03,7.57,28.63c1.35,5.12,2.06,5.32,7.59,4.31l.6,2.25-20.22,5.34-.6-2.25c5.07-1.79,5.52-2.3,4.16-7.42l-7.57-28.63-3.72.98c-3.41.9-4.24,1.71-5.01,3.48-.55,1.38-.82,3.8-.97,6.45l-2.31.61c-.75-4.56-1.58-9.42-2.23-12.38l1.83-.48c1.28,1.16,2.05,1.09,3.88.61l24.37-6.44c1.64-.43,2.4-1.03,3.13-2.46l1.77-.47c.74,2.54,2.4,7.84,3.75,11.72l-2.27.79Z", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M155.94,233.15l.06-2.4c4.11-.49,4.13-1.2,3.28-4.02-.6-1.97-1.57-4.91-2.49-7.54l-12.67-.3c-.76,2-1.73,4.45-2.43,6.44-1.46,4.06.08,4.62,5.21,5.2l-.06,2.4-16.05-.38.06-2.4c4.11-.42,5.16-.98,7.5-6.39l14.32-33.27,3.13-.51c3.89,11.41,8.03,23.08,11.99,34.04,2.26,6.1,2.83,6.57,7.05,7.19l-.06,2.4-18.85-.45ZM150.97,201.95c-2,4.63-3.92,8.94-5.66,13.65l10.33.25-4.67-13.9Z", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M207.69,211.66c-.04-4.36-.41-9.43-6.14-11.15-3.61-1.09-6.74.75-7.8,4.23-1.03,3.42.65,6.17,5.3,10.56,5.66,5.31,8.92,9.82,7.06,15.98-2.22,7.34-9.93,10.85-18.83,8.17-3.98-1.2-7.21-3.33-9.87-5.08.06-1.54.96-8.33,1.4-11.39l2.6.17c.06,4.3,1.5,12.13,7.6,13.97,3.8,1.15,6.81-.52,8.01-4.51,1.09-3.61-.51-6.4-4.89-10.78-5.42-5.44-8.71-9.83-6.76-16.3,1.97-6.53,8.63-10.16,17.97-7.34,4.29,1.3,7.25,3.21,8.56,3.81-.45,2.38-.99,5.74-1.67,9.82l-2.53-.15Z", style: {
  fill: "#000",
  strokeWidth: 0
} }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M115.74,21.15s-.41-9.79,5.19-14.31c0,0,7.21,6.43,7.3,16.7,0,0,12.43-9.48,16.94-9.79,0,0-3.83,8.99-9.39,14.38-1.25,1.22,10.47-8.87,17.15-7.23,0,0-8.62,7.66-13.66,11.95,0,0,10.39.78,17.97,3.25,0,0-8.22,4.9-16.24,6.72,2.11,1.34,14.51,10.76,13.76,14.29,0,0-9.76-3.91-18.37-6.35,0,0,7.68,12.31,7.28,20.19,0,0-6.99-4.78-16.17-13.78,0,0,1.77,12.94-3.2,22.41,0,0-7.22-12.9-7.81-21.68,0,0-5.37,13.57-14.6,17.77,0,0-.55-14.26,4-21.51,0,0-14.27,10.47-20.69,5.24,0,0,6.46-8.92,17.98-14.76,0,0-15.2,4.49-20.62-1.8,0,0,3.35-6.5,13.96-6.66,8.79-.13.03.01,0,0-1.37-.57-6.94-4.62-9.77-11.54,0,0,12.39-5.69,20.36.86,0,0-7.45-5.8-5.16-13.48,0,0,5.21-.2,13.79,9.14Z", style: {
  fill: "#f9f9e1",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.34px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M118.07,25.24s.28-.81,2.17-2.51c0,0,2.78-.9,3.01,2.73,0,0,2.28-1.78,3.77-1.81,0,0,2.02.81.89,3.57-.23.57,1.5-.6,3.88-.15,0,0,1.97,2.56.28,4.18,0,0,.25.42,2.45,2.43,0,0,.04,3.07-2.73,3.03.77.43,1.55,1.84,1.35,3.09,0,0-.16,2.46-3.24,1.76,0,0-.41.55-.4,3.34,0,0-1.3,3.34-4.71.35,0,0-.38-.36-1.95,3.08,0,0-4.1,1.24-4.48-1.85,0,0-.32-.76-3.5.9,0,0-3.64-.73-2.18-3.38,0,0-1.66-.32-4.02-2.04,0,0-2.31-1.35,1.64-3.64,0,0-.85-.04-2.89-2.15,0,0-.24-3.76,3.5-3.47.01,0,1.3.85,1.29.85-.49-.18-2.54-1.49-3.67-3.88,0,0,1.7-3.95,4.64-1.79,0,0-.18-.2.48-2.96,0,0,1.22-2.81,4.43.32Z", style: {
  fill: "#f9f9e1",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".91px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M114.21,26.84s-7-12.52-3.86-17.45c0,0,9.86,6.42,9.15,15.46,0,0,3.03-12.51,9.88-16.2,0,0-.09,9.68-1.83,16.58-.67,2.69,3.86-13.29,9.37-13.4,0,0-.91,12.94-4.28,17.22,0,0,9.53-4.47,15.52-.79,0,0-6.83,8.23-12.94,9.47,1.95.64,14.01,3.38,14.85,5.88,0,0-13.31,2.98-20.58,1.28,0,0,12.56,5.68,14.22,12.24,0,0-8.37-2.5-17.63-7.9,0,0,6.42,10.34,4.99,19.19,0,0-7.98-9.83-9.83-18.28,0,0-1.3,15.06-8.26,19.92,0,0,.04-14.45,2.66-21.37,0,0-12.31,16.65-18.51,13.74,0,0,1.18-7.56,8.52-14.86,0,0-9.92,3.69-15.63-.31,0,0,8.08-6.62,16.46-7.73.02,0-7.44-.76-7.46-.77-1.19-.18-6.45-2.29-10.35-7.36,0,0,12.04-4.38,19.77-.73,0,0-11.14-6.14-11.31-12.94,0,0,8.17,3.27,17.06,9.09Z", style: {
  fill: "#f9f9e1",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.11px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M114.87,23.5s.39-1.13,3.03-3.51c0,0,3.88-1.25,4.21,3.81,0,0,3.19-2.49,5.27-2.54,0,0,2.83,1.13,1.24,4.99-.33.8,2.09-.83,5.43-.21,0,0,2.76,3.58.39,5.83,0,0,.36.59,3.42,3.4,0,0,.06,4.3-3.81,4.24,1.08.6,2.16,2.57,1.88,4.32,0,0-.22,3.43-4.53,2.46,0,0-.58.77-.56,4.66,0,0-1.82,4.67-6.58.48,0,0-.54-.5-2.73,4.3,0,0-5.74,1.73-6.26-2.58,0,0-.45-1.06-4.89,1.26,0,0-5.09-1.02-3.05-4.72,0,0-2.31-.44-5.62-2.85,0,0-3.23-1.89,2.29-5.08,0,0-1.19-.05-4.03-3,0,0-.33-5.26,4.89-4.85.01,0,1.82,1.19,1.81,1.19-.69-.25-3.55-2.09-5.13-5.42,0,0,2.38-5.51,6.48-2.5,0,0-.25-.29.67-4.13,0,0,1.7-3.93,6.19.44Z", style: {
  fill: "#f9cd5f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.27px"
} }), /* @__PURE__ */ React.createElement("ellipse", { cx: 118.14, cy: 37.23, rx: 12.53, ry: 10.76, transform: "translate(-4.63 20.79) rotate(-9.86)", style: {
  fill: "#b2b272",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.08px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M182.33,53.74s5.95-9.9,14.4-10.72c0,0,2.93,11.01-3.65,21.18,0,0,18.39-1.27,23.03,1.35,0,0-9.61,6.36-18.57,8.06-2.02.38,16.06-1.93,21.57,4,0,0-13.45,1.95-21.21,2.9,0,0,9.72,7.51,15.58,14.86,0,0-11.27-.51-20.34-3.92,1.21,2.69,7.3,20.01,4.27,22.99,0,0-7.07-10.18-13.96-18.17,0,0-.43,17.09-5.94,24.59,0,0-3.78-9.24-6.98-24.06,0,0-6.66,13.89-17.69,19.99,0,0,1.26-17.38,6.39-26.41,0,0-14.09,9.87-25.9,8.02,0,0,8.72-14.39,17.89-18.58,0,0-20.84,1.04-23.76-8.27,0,0,12.15-4.58,27.28-2.85,0,0-17.88-5.44-19.13-15.15,0,0,7.52-4.22,18.07,2.5,8.74,5.57.02.03,0,0-.98-1.46-3.84-9.05-2.13-17.7,0,0,15.89,2.44,19.49,14.06,0,0-3.56-10.55,3.67-16.62,0,0,5.25,3.18,7.64,17.94Z", style: {
  fill: "#f9f9e1",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.59px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M181.97,59.28s.8-.61,3.76-1.06c0,0,3.32.92,1.19,4.64,0,0,3.4-.27,4.89.66,0,0,1.46,2.11-1.44,4.09-.6.41,1.86.38,3.92,2.37,0,0,.28,3.8-2.44,4.29,0,0-.02.58.83,3.98,0,0-1.95,3.06-4.65,1.22.48.92.33,2.81-.68,3.92,0,0-1.75,2.31-4.33-.37,0,0-.76.27-2.56,3.02,0,0-3.45,2.44-4.86-2.72,0,0-.15-.6-3.92,1.76,0,0-4.85-1.44-3.21-4.73,0,0,.17-.96-4.03-1.38,0,0-3.11-3.08.04-4.74,0,0-1.42-1.39-2.63-4.61,0,0-1.4-2.83,3.97-2.52,0,0-.82-.59-1.45-3.99,0,0,2.21-3.86,5.7-1.14,0,0,.73,1.68.72,1.67-.37-.49-1.53-3.12-1.1-6.2,0,0,4.24-2.78,5.73,1.25,0,0-.05-.32,2.39-2.6,0,0,3.02-1.97,4.15,3.19Z", style: {
  fill: "#f9f9e1",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.07px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M177.13,58.35s1.23-16.87,7.53-19.68c0,0,5.54,12.71-1.03,21.15,0,0,11.1-10.35,20.24-9.53,0,0-6.38,9.47-12.56,15.14-2.41,2.21,12.43-10.58,17.92-7.11,0,0-9.29,12.15-15.39,14.17,0,0,12.28,1.78,15.79,9.3,0,0-12.06,3.67-18.88.93,1.5,1.89,11.59,12.42,10.8,15.43,0,0-15.04-5.71-21.09-12.09,0,0,8.67,13.74,6.05,21.28,0,0-6.62-7.89-12.23-19.22,0,0-.39,14.34-7.54,22.13,0,0-1.48-14.86,2.19-24.37,0,0-11.06,13.98-21.06,14.25,0,0,9.42-14.2,16.49-19.31,0,0-22.92,8.41-27.14,1.52,0,0,6.07-6.68,18.03-9.09,0,0-12.16-2.8-15.19-10.44,0,0,12.24-1.27,21.22,3.07.03.01-6.83-5.58-6.85-5.6-1.06-.95-4.87-6.44-5.4-13.96,0,0,14.7,3.51,19.93,12.11,0,0-6.98-13.27-2.73-20.08,0,0,5.92,8.53,10.89,20.02Z", style: {
  fill: "#f9f9e1",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.31px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M179.95,55.49s1.12-.85,5.26-1.49c0,0,4.64,1.29,1.67,6.48,0,0,4.75-.38,6.84.93,0,0,2.05,2.95-2.02,5.72-.84.57,2.6.54,5.48,3.31,0,0,.39,5.31-3.4,6,0,0-.03.81,1.16,5.57,0,0-2.73,4.27-6.5,1.7.67,1.29.46,3.93-.95,5.48,0,0-2.44,3.23-6.06-.52,0,0-1.07.38-3.58,4.22,0,0-4.82,3.42-6.8-3.8,0,0-.2-.84-5.47,2.46,0,0-6.77-2.02-4.49-6.6,0,0,.24-1.34-5.63-1.93,0,0-4.35-4.31.06-6.63,0,0-1.99-1.94-3.68-6.45,0,0-1.95-3.96,5.55-3.52,0,0-1.14-.82-2.02-5.58,0,0,3.09-5.39,7.96-1.6.01.01,1.02,2.35,1.01,2.34-.52-.69-2.14-4.35-1.53-8.67,0,0,5.92-3.88,8.01,1.74,0,0-.06-.44,3.34-3.64,0,0,4.23-2.76,5.8,4.45Z", style: {
  fill: "#f9cd5f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.49px"
} }), /* @__PURE__ */ React.createElement("ellipse", { cx: 174.26, cy: 71.13, rx: 12.69, ry: 14.77, transform: "translate(39.47 202.5) rotate(-66.47)", style: {
  fill: "#b2b272",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.27px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M118.33,103.25s-.35-8.38,4.44-12.25c0,0,6.17,5.5,6.25,14.29,0,0,10.63-8.12,14.49-8.38,0,0-3.28,7.69-8.03,12.3-1.07,1.04,8.96-7.59,14.67-6.19,0,0-7.37,6.55-11.69,10.23,0,0,8.89.67,15.38,2.78,0,0-7.04,4.19-13.89,5.75,1.81,1.14,12.42,9.21,11.77,12.23,0,0-8.35-3.34-15.72-5.44,0,0,6.57,10.53,6.23,17.28,0,0-5.99-4.09-13.84-11.79,0,0,1.51,11.08-2.74,19.18,0,0-6.18-11.04-6.68-18.55,0,0-4.59,11.61-12.49,15.21,0,0-.47-12.2,3.42-18.41,0,0-12.21,8.96-17.7,4.48,0,0,5.53-7.63,15.39-12.63,0,0-13.01,3.85-17.64-1.54,0,0,2.87-5.56,11.95-5.7,7.52-.11.02,0,0,0-1.17-.49-5.94-3.95-8.36-9.87,0,0,10.6-4.87,17.42.73,0,0-6.37-4.97-4.42-11.54,0,0,4.46-.17,11.8,7.82Z", style: {
  fill: "#f9f9e1",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.15px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M120.32,106.75s.24-.69,1.85-2.15c0,0,2.38-.77,2.58,2.33,0,0,1.95-1.53,3.23-1.55,0,0,1.73.69.76,3.06-.2.49,1.28-.51,3.32-.13,0,0,1.69,2.19.24,3.57,0,0,.22.36,2.09,2.08,0,0,.04,2.63-2.33,2.6.66.37,1.32,1.57,1.15,2.65,0,0-.14,2.1-2.77,1.51,0,0-.35.47-.34,2.85,0,0-1.11,2.86-4.03.3,0,0-.33-.31-1.67,2.63,0,0-3.51,1.06-3.83-1.58,0,0-.28-.65-2.99.77,0,0-3.12-.63-1.87-2.89,0,0-1.42-.27-3.44-1.74,0,0-1.98-1.16,1.4-3.11,0,0-.73-.03-2.47-1.84,0,0-.2-3.22,3-2.97,0,0,1.12.73,1.11.73-.42-.15-2.17-1.28-3.14-3.32,0,0,1.46-3.38,3.97-1.53,0,0-.15-.17.41-2.53,0,0,1.04-2.4,3.79.27Z", style: {
  fill: "#f9f9e1",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".78px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M117.01,108.11s-5.99-10.71-3.3-14.93c0,0,8.43,5.49,7.83,13.23,0,0,2.59-10.71,8.46-13.86,0,0-.08,8.29-1.56,14.19-.58,2.3,3.31-11.37,8.02-11.47,0,0-.77,11.07-3.66,14.74,0,0,8.15-3.83,13.29-.68,0,0-5.84,7.04-11.07,8.1,1.67.55,11.99,2.89,12.71,5.03,0,0-11.39,2.55-17.61,1.1,0,0,10.75,4.86,12.17,10.48,0,0-7.16-2.14-15.09-6.76,0,0,5.49,8.85,4.27,16.43,0,0-6.83-8.41-8.41-15.64,0,0-1.11,12.89-7.07,17.05,0,0,.04-12.37,2.28-18.29,0,0-10.53,14.25-15.84,11.76,0,0,1.01-6.47,7.29-12.71,0,0-8.49,3.16-13.37-.26,0,0,6.91-5.66,14.09-6.62.02,0-6.37-.65-6.39-.65-1.02-.15-5.52-1.96-8.85-6.3,0,0,10.31-3.75,16.91-.62,0,0-9.53-5.25-9.68-11.08,0,0,7,2.8,14.6,7.78Z", style: {
  fill: "#f9f9e1",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".95px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M117.58,105.25s.33-.96,2.59-3c0,0,3.32-1.07,3.6,3.26,0,0,2.73-2.13,4.51-2.17,0,0,2.42.97,1.06,4.27-.28.68,1.79-.71,4.65-.18,0,0,2.36,3.06.33,4.99,0,0,.3.5,2.93,2.91,0,0,.05,3.68-3.26,3.63.92.51,1.85,2.2,1.61,3.7,0,0-.19,2.94-3.88,2.1,0,0-.49.66-.48,3.99,0,0-1.55,3.99-5.63.41,0,0-.46-.43-2.33,3.68,0,0-4.91,1.48-5.36-2.21,0,0-.39-.91-4.18,1.08,0,0-4.36-.87-2.61-4.04,0,0-1.98-.38-4.81-2.44,0,0-2.77-1.62,1.96-4.35,0,0-1.02-.04-3.45-2.57,0,0-.28-4.5,4.19-4.15.01,0,1.56,1.02,1.55,1.01-.59-.21-3.03-1.79-4.39-4.64,0,0,2.03-4.72,5.55-2.14,0,0-.22-.24.57-3.54,0,0,1.46-3.36,5.29.38Z", style: {
  fill: "#f9cd5f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.08px"
} }), /* @__PURE__ */ React.createElement("ellipse", { cx: 120.38, cy: 117, rx: 10.72, ry: 9.21, transform: "translate(-18.26 22.35) rotate(-9.86)", style: {
  fill: "#b2b272",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".92px"
} }))));
const ForwardRef = forwardRef(SvgTas);
export default ForwardRef;
