import * as React from "react";
import { forwardRef } from "react";
const SvgHalal = (props, ref) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", "data-name": "Layer 1", xmlns: "http://www.w3.org/2000/svg", width: 298.07, height: 298.07, viewBox: "0 0 298.07 298.07", ref, ...props }, /* @__PURE__ */ React.createElement("rect", { width: 298.07, height: 298.07, style: {
  fill: "none",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { points: "185.21 46.71 213.1 74.71 249.95 74.71 249.95 111.19 278.63 139.97 250.55 168.15 250.55 204.83 214.34 204.83 185.68 233.6 157.17 204.98 120.39 204.98 120.39 169.3 91.94 140.74 120.45 112.12 120.45 75.22 156.88 75.22 185.21 46.71", style: {
  fill: "#849324",
  stroke: "#231f20",
  strokeLinejoin: "round",
  strokeWidth: "2.62px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M261.2,142.15c0,1.24-1,2.37-1.59,3.43-1.51,2.48-3.15,5-6.35,5.12-5.53.08-10.93.91-16.15,2.88-1.07.38-2.14.78-3.21,1.15-5.89,2.07-11.95,3.36-18.26,3.43-3.32.06-6.88-.52-9.68-2.34-5.16-3.17-5.96-8.66-6.68-14.24-.8-5.18-1.12-11.11-1.92-16.11-.04-.13-.14-.27-.24-.1-.21.31-.47,1.73-.74,2.99-.55,2.57-1.14,5.3-1.87,7.91-4.32,16.8-16.41,27.13-33.62,27.55-1.99.09-4.08.13-6.16.1-.93-.03-1.51-.13-1.46-.37-.05-.26,1.38-.93,2.03-1.21,3.33-1.49,6.11-2.7,9.17-4.16,18.03-8.02,29.08-23.96,30.86-43.61.51-3.36,1.12-6.67,1.84-9.98.44-2.2,1.66-4.12,3.19-1.4.89,1.69,1.18,3.6,1.35,5.49.99,9.94,1.1,20.03,2.95,29.86.6,2.62,1.08,5.34,2.27,7.76,1.19,2.45,3.78,3.77,6.42,3.93,7.19.76,14.33-.64,21.04-3.24,1.84-.7,2.4-1.67.52-2.96-5.87-4.39-13.93-3.62-21.51.33-.61.21-1,.2-1.11-.09-.19-.88.87-2.63,1.41-3.79,1.71-3.29,4-6.21,7.42-7.75,6.64-3.06,14.15.76,19.91,4.23,4.51,2.64,9.18,4.03,14.22,4.34,1.17.08,2.37.08,3.56.05.81-.03,2.32.01,2.39.81v.03Z", style: {
  fill: "#fff",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.31px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M121.66,148.43c.19-1.07.48-2.13.82-3.16.3-.97,1.84-5.17,2.27-3.92.2,1.09-.05,2.57-.08,3.75-.16,2.82.34,5.49,2.07,7.58,4.58,5.33,12.78,5.59,19.14,3.73,6.03-1.77,10.17-5.78,10.87-12.16,1.38-13.54-1.93-26.97-5.27-39.99-.54-2.16-.87-4.46-.23-6.59.7-2.25,2.42-5.06,4.69-3.5,1.4,1.12,2.89,3.7,3.49,5.68.99,3.5-1.01,6.97-.84,10.52,0,2.07.3,4.16.68,6.2,1.55,7.53,2.63,15.09,2.76,22.79.17,5.39-.23,11.34-3.13,16.03-4.86,7.99-14.69,11.29-23.6,9.56-7.99-1.31-15.17-7.88-13.66-16.48v-.06Z", style: {
  fill: "#fff",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.31px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M183.9,141.36c-1.47-2.89-2.55-5.34-3.91-7.63-2.34-3.96-5.25-7.17-9.79-8.95-7.78-3.05-9.55-6.74-8.12-15.26,14.78,4.46,23.92,17.53,21.83,31.84Z", style: {
  fill: "#fff",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.31px"
} }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("circle", { cx: 86.46, cy: 200.31, r: 55.23, style: {
  fill: "#fff",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "2.19px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M112.2,166.51v67.95h-15.63v-28.03h-21.21v28.03h-15.63v-67.95h15.63v26.48h21.21v-26.48h15.63Z", style: {
  fill: "#000",
  strokeWidth: 0
} }))));
const ForwardRef = forwardRef(SvgHalal);
export default ForwardRef;
