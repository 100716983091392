import * as React from "react";
import { forwardRef } from "react";
const SvgWa = (props, ref) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", "data-name": "Layer 1", xmlns: "http://www.w3.org/2000/svg", width: 298.07, height: 298.07, viewBox: "0 0 298.07 298.07", ref, ...props }, /* @__PURE__ */ React.createElement("rect", { width: 298.07, height: 298.07, style: {
  fill: "none",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { points: "263.95 142.39 281.59 164.85 272.77 148.16 287.57 158.03 266.94 138.23 263.95 142.39", style: {
  fill: "#f9cd5f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.25px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M267.1,122.95l20.48,16.38-10.24-5.19,8.19,15.12s-22.21-23.66-21.58-24.2,3.15-2.11,3.15-2.11Z", style: {
  fill: "#f9cd5f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.25px"
} })), /* @__PURE__ */ React.createElement("path", { d: "M38.99,139.18s-7.4,2.32-18.59,3.21c0,0,1.89-6.36,13.55-7.46,0,0-14.97.63-23-8.35,0,0,15.6-3.31,28.04,2.68,0,0-21.11-4.88-31.35-15.91,0,0,25.05,4.88,35.44,12.6,0,0-5.2-5.04-7.4-6.3s-21.42-7.25-25.99-23.79c0,0,.16-1.1,2.68,0,0,0,38.91,17.96,38.75,26.46,0,0-3.31-15.28-9.14-17.17s-23.16-1.89-25.99-17.17c0,0-.47-1.58,2.99,0s31.98,10.08,36.39,25.68c0,0,.71,4.12,2.56-.22s-.67-13.01-12.64-18.05c-11.97-5.04-18.9-15.6-19.53-23.94,0,0-.32-6.46,6.46,0,6.77,6.46,24.73,9.92,32.14,35.29s-1.42,25.52-1.42,25.52l9.61,3.47s5.2-33.55-4.88-49.31c-10.08-15.75-15.28-5.67-17.17-33.71,0,0-1.58-6.46,4.41-2.36,5.99,4.1,33.87,48.05,25.05,68.05l-6.3,18.27,9.29,5.73,4.1-24.79s.63-25.52-9.45-44.74c-10.08-19.22-16.07-32.45-10.4-41.9,0,0,8.35-12.13,6.46,0-1.89,12.13,9.92,12.76,8.66,34.18,0,0,.79,2.99,3.47,10.24,2.68,7.25,12.1,34.44,5.67,44.42-6.42,9.98-6.62,24.1-6.62,24.1l9.92,4.25,8.43-26.62s7.33-32.29,7.96-64.59c.63-32.29,6.14-44.11,13.7-49.78,0,0,8.66-5.51,3.94,3.78-4.73,9.29,5.99,21.27-7.09,37.33,0,0-2.05,2.52-2.05,7.4s-3.48,51.8-8.66,65.06l-9.29,11.97-4.88,16.7,11.03,7.88,11.18-32.61s3.62-2.21,3.31-1.73,11.18-89.48,58.44-106.17c0,0,28.36-7.56,9.92,6.62,0,0-25.36,16.38-29.62,22.37,0,0-19.66,20.07-28.04,69,0,0,.47,11.34-6.62,19.14-7.09,7.8-11.22,4.73-11.22,4.73l-6.62,18.67,10.16,15.6s10.28-7.8,9.92-7.56,21.38-21.62,21.38-21.62c0,0,25.05-44.54,63.09-48.09,38.04-3.54,70.89,37.33,70.18,37.33l-9.69-4.49,7.09,10.87-10.87-8.98,7.09,14.41s-57.26-80.1-105.62-7.33c-1.05,1.59-1.65,11.81-12.29,11.34s-13.54,1.42-13.54,1.42l-17.41,19.22,11.03,13.07s26.94-26.23,26.39-26.07,53.09-82.47,117.04-7.64c0,0-11.34-5.36-12.13-5.36s9.45,11.66,9.45,11.66l-14.34-9.45,7.72,12.39s-39.7-64.06-86.64-3.42c-.52.67.47,11.03-8.03,12.6-8.51,1.58-19.22-1.42-19.53-.79s-13.54,18.59-13.54,18.59c0,0,2.19,14.34,1.96,14.81s-27.17-25.99-27.17-25.99l-2.84.16-19.69-18.27-24.1-15.6s-23-6.62-24.89-5.51-9.77,4.57-9.77,4.57Z", style: {
  fill: "#417712",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.25px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M33.83,135.05s-4.41.63-5.75,2.52,5.59,2.44,6.62,1.5-.87-4.02-.87-4.02Z", style: {
  fill: "#897a13",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.25px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M38.87,130.8c-.24,0-3.88-2.84-7.42-2.36-3.53.47-3.06,3.47-3.06,3.47,0,0,1.89,3.62,8.19,3.15,6.3-.47,2.28-4.25,2.28-4.25Z", style: {
  fill: "#897a13",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.25px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M43.28,129.62c.24-.05,1.89-4.1-5.12-5.83s-6.3,1.02-6.3,1.02c0,0,1.89,6.69,11.42,4.8Z", style: {
  fill: "#897a13",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.25px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M48.16,124.03c.85-.43,3.23-4.73-5.28-8.74s-5.16,4.37-5.16,4.37c0,0,5.16,7.05,10.43,4.37Z", style: {
  fill: "#897a13",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.25px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M54.38,122.69s2.18-5.9.77-9.2c-1.4-3.3-8.18-8.26-5.58,0,2.6,8.26-1.97,10.54,4.8,9.2Z", style: {
  fill: "#897a13",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.25px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M58.16,127.49c-.32.24-.24,3.54,3.7,3.07,3.94-.47,3.63,2.18,4.69-2.18,1.06-4.36-3.04-20.27-4.77-23.97s-3.94-1.58-3.31,1.34c.63,2.91.47,8.82-3.31,11.81-3.78,2.99,2.99,9.92,2.99,9.92Z", style: {
  fill: "#897a13",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.25px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M75.57,119.41s5.57,2.15,5.04-9.49c-.53-11.64-5.36-18.82-6.22-9.77s-.71,16.29,1.18,19.25Z", style: {
  fill: "#897a13",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.25px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M88.25,120.4s4.25-2.17,4.88-9.39c.63-7.22-5.43-26.76-6.46-15.65-1.02,11.11,1.42,23.08,1.58,25.05Z", style: {
  fill: "#897a13",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.25px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M106.6,121.66s7.86-11.68,8.85-22.82-1.92-24.36-5.7-6.17c-3.78,18.19-7.17,27.41-5.91,28.59s2.76.39,2.76.39Z", style: {
  fill: "#897a13",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.25px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M125.03,129.46s5.51-4.41,8.66-20.87,4.5-34.56-.71-23.94c-5.21,10.61-11.03,37.49-10.55,41.51.47,4.02,2.6,3.31,2.6,3.31Z", style: {
  fill: "#897a13",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.25px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M152.92,150.26s5.12.16,11.5-3.54,7.4-16.62,15.44-23.47c8.03-6.85,20.48-22.05,7.72-15.28-12.76,6.77-35.66,30.56-37.87,36.1-2.21,5.54,3.21,6.2,3.21,6.2Z", style: {
  fill: "#897a13",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.25px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M168.4,165.33c.08.32,9.06,2.84,13.55-1.02s4.02-8.74,5.36-11.97c1.34-3.23,31.03-31.9,17.64-26.62s-29.66,18.74-39.6,30.91,3.06,8.7,3.06,8.7Z", style: {
  fill: "#897a13",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.25px"
} })), /* @__PURE__ */ React.createElement("path", { d: "M140.13,205.18s-26.46-47.26-86.33-66.16c0,0-13.23,13.86-19.22,16.07,0,0,1.89-10.28,4.41-12.7,0,0-10.4,6.72-15.44,9.55,0,0,1.61-6.69,8.19-12.92,0,0-2.95-1.02,2.05-4.53,0,0-4.02-4.61,3.54-5.67,0,0-3.58-4.88,4.61-4.25,0,0-2.01-9.69,7.21-3.78.78.5.44-3.93,4.61-4.73,1.14-.22,4.25,5.43,5.08,3.54,0,0,1.19-7.81,4.24-4.49,4.01,4.36,2.02,14.18,2.02,14.18l6.66,2.8.63-7.88s-6.93-7.88,2.84-13.23c0,0,1.91-.63,3,1.26s6.34,7.55,0,12.92l-1.74,10.08,5.36,1.89,1.26-6.62s1.58-16.38,5.67-18.27,4.1,2.21,4.1,2.21c0,0,4.1,9.45-5.04,17.64l-1.26,7.88s7.56,4.73,7.56,3.78,1.89-8.82,2.84-11.03c0,0-.32-14.81,7.88-17.64,8.19-2.84,7.56,5.36,7.56,5.36,0,0-.32,11.34-9.77,15.44l-3.78,11.03,8.19,5.04s2.21-11.97,4.73-15.12c0,0,1.58-14.49,12.6-16.38,11.03-1.89,7.56,8.19,7.56,8.19,0,0-3.15,9.14-14.81,11.03,0,0-4.41,9.77-5.36,16.38,0,0,4.1,1.24,14.49,8.81,0,0,11.66-14.48,16.07-15.43,4.41-.95.63-8.19,13.55-10.08s6.3,9.45,6.3,9.45c0,0-9.45,8.19-17.64,4.1,0,0-12.6,13.55-11.97,16.7l8.51,7.56s12.92-12.92,13.23-14.18c0,0,7.25-9.45,13.86-7.88,6.62,1.58,14.49,2.84,14.49,5.99s2.52,8.51-12.6,8.82l-7.88-1.26s-5.21-.32-14.74,13.23c0,0,8.43,10.4,12.85,20.79", style: {
  fill: "#cd2735",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.25px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M6.71,236.17c13.33-7.42,149.98-137.81,287.82,7.52l-43.73,1.47-2.53,46.54s-85.43-102.28-202.38,0l9.36-49.11-48.54-6.41Z", style: {
  fill: "#f9cd5f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "3.04px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M152.88,192.75c-4.3,1.34-4.92,2.39-5.74,7.7-.75,4.98-2.73,20.26-4.35,33.4l-3.42.61c-4.68-8.23-9.78-16.64-14.9-25.17-1.52,9.78-2.98,19.24-4.26,28.6l-3.54.63c-3.98-8.17-10.15-20.1-15.5-30.29-2.35-4.36-3.46-5.24-7.24-4.94l-.4-2.26,18.43-3.3.4,2.26c-4.5,1.25-4.46,2.18-3.04,5.2,3.27,6.66,6.68,13.42,9.89,19.71,1.57-9.16,2.85-18.9,3.97-28.86l3.24-.58c5.03,8.74,10.13,17.21,15.08,25.51,1.08-6.99,2.18-17.71,2.42-21.34.21-3.75-.6-4.74-5.92-4.23l-.41-2.26,14.89-2.67.4,2.26Z", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M177.45,236.54l.43-2.37c4.13.16,4.26-.55,3.87-3.46-.29-2.03-.79-5.1-1.29-7.83l-12.47-2.26c-1.06,1.85-2.4,4.12-3.4,5.99-2.07,3.79-.63,4.58,4.34,5.94l-.43,2.37-15.8-2.87.43-2.37c4.12.22,5.25-.17,8.4-5.15l19.3-30.65,3.17-.02c2.07,11.87,4.36,24.04,6.57,35.48,1.29,6.38,1.78,6.93,5.84,8.2l-.43,2.37-18.55-3.37ZM177.37,204.95c-2.69,4.27-5.26,8.23-7.7,12.61l10.17,1.84-2.46-14.45Z", style: {
  fill: "#000",
  strokeWidth: 0
} }))));
const ForwardRef = forwardRef(SvgWa);
export default ForwardRef;
