import * as React from "react";
import { forwardRef } from "react";
const SvgAus = (props, ref) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", "data-name": "Layer 1", xmlns: "http://www.w3.org/2000/svg", width: 299.44, height: 298.07, viewBox: "0 0 299.44 298.07", ref, ...props }, /* @__PURE__ */ React.createElement("rect", { width: 298.07, height: 298.07, style: {
  fill: "none",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M206.62,103.49s4.31-12.3.86-23.33l1.91-.46s1.9,16.48,0,21.82", style: {
  fill: "#b2b272",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".51px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 110.23, y1: 125, x2: 112.13, y2: 132.02, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".61px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 115.07, y1: 122.3, x2: 118.02, y2: 123.94, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".61px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 114.28, y1: 113.48, x2: 121.7, y2: 109.37, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".61px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 111.6, y1: 108.92, x2: 114.54, y2: 105.3, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".61px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 102.81, y1: 102.82, x2: 104.57, y2: 108.37, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".61px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 94.46, y1: 108.78, x2: 99.31, y2: 111.38, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".61px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 94.06, y1: 121.55, x2: 98.48, y2: 119.61, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".61px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 102.78, y1: 132.85, x2: 105.38, y2: 124.46, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".61px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 115.85, y1: 116.65, x2: 124.55, y2: 115.87, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".61px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M86.82,204.69c1.91-.9,51.01-37.75,55.77-43.25,4.76-5.5,22.49-52.51,22.49-52.51l1.37,3.19s-17.97,44.26-18.55,45.94c0,0,45.14-40.49,46.74-42.89,0,0,8.77-22.09,8.87-25.72,0,0-3.49,17.54-5.22,21.23,0,0,21.63-18.89,25.72-25.78,0,0-55.06,61.5-69.34,72.02,0,0,71.49-33.78,86.72-45.93l.28,3.16s-89.46,47.7-92.86,50.19c-3.4,2.48-74.07,55.59-73.75,59.18", style: {
  fill: "#7c6e5b",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".95px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M102.54,190.59s26.54-41.93,35.8-81.12l2.52-.09s-22.25,70.43-35.87,82.97", style: {
  fill: "#7c6e5b",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".51px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M164.96,156.63s9.7,1.71,31.83-.23c22.13-1.94,50.87-21.8,55.88-23.32,5.02-1.51,5.21-4.62.63-4.46-4.58.16-34.3,2.55-46.82,7.36-12.53,4.82-38.72,13.61-43.55,17.15s2.04,3.5,2.04,3.5Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".79px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M161.4,155.83s88.14-23.92,95.26-26.13", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".68px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M152.09,183.15s22.2-4.09,48.58-1.35c26.38,2.74,49.43,12.13,67.06,3.96,0,0,7.11-3.21.14-6.12,0,0-29.6-17.81-54.05-19.28-24.46-1.47-59.17,4.18-67.53,8.27-8.36,4.1-40.5,17.76,5.79,14.52Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".81px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M127.66,180.82s62.87-20.13,104.35-9.03c15.3,4.09,27.66,11.38,38.95,10.37", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".81px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M78.37,185.26s-7.28-18.28-21.53-36.47c-14.25-18.19-31.88-30.84-34.02-47.64,0,0-.93-6.73,4.45-2.94,0,0,26.87,13.58,39.29,30.94,12.43,17.36,24.36,45.59,25.21,53.66s5.68,38.12-13.4,2.46Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".7px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M91.42,202.21s-14.31-55.72-41.73-81.17c-10.12-9.39-21.22-15.12-25.71-23.93", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".7px"
} })), /* @__PURE__ */ React.createElement("path", { d: "M229.57,119.05s14.14-28.85,16.71-37l2.39.63s-12.68,28.87-14.79,33.42l-4.31,2.95Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".95px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M157.38,121.63s-1.59-12.62,2.78-41.06c4.38-28.44,33.86-64.37,36.32-70.76,2.47-6.39,6.74-6.46,6.17-.55s-6.15,44.11-13.73,60c-7.58,15.89-21.68,49.18-26.91,55.21-5.23,6.03-4.64-2.84-4.64-2.84Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "1.05px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M158.2,126.28S197.85,13.93,201.44,4.86", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".91px"
} })), /* @__PURE__ */ React.createElement("path", { d: "M93.67,202.26s13-37.03,15.14-69.78l1.68-.87s-4.1,54.83-12.7,68.11l-4.12,2.54Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".95px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M109.92,101.83s3.47-2.97,4.02,1.58c0,0,3.93-2.22,2.75,1.9,0,0,4.22-2.29,2.46,2.57,0,0,4.53-1.01,1.91,3.14,0,0,4.53-.03,1.29,3.29,0,0,2.9,1.09.35,3.9,0,0,4.08,1.71-.2,4,0,0,3.1,4.5-2.3,4.56,0,0,2.77,5.74-3.78,4.34,0,0-.28,6.3-5.18,2.86,0,0-3.5,7.43-7.33.96,0,0-7.09,5.34-6.62-2.42,0,0-7.28,5.07-5.09-4.3,0,0-8.1.45-2.88-7.31,0,0-8.13-5.25,1-8.57,0,0-7.23-8.09,3.66-6.08,0,0-.78-4.72,3.2-2.51,0,0,.14-4.98,3.71-1.66,0,0,1.34-3.6,3.12-.65,0,0,3.65-6.57,5.9.4Z", style: {
  fill: "#efe47b",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".92px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M98.64,111.67s5.4,2.9,7.89-3.8c0,0-1.64,6.8,3.5,4.48,0,0-5.24,4.42.64,3.98,0,0-4.08,1.77.12,3.43,0,0-4.85-.31-2.03,3.96,0,0-3.29-2.9-4.5,1.3,0,0-.63-4.32-4.28-1.96,0,0,2.78-3.77-1.04-3.94,0,0,2.72-1.82-3.69-3,0,0,8.35-.05,3.4-4.45Z", style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".59px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M212.67,60.23s2.42-2.06,2.79,1.1c0,0,2.74-1.55,1.92,1.32,0,0,2.94-1.59,1.71,1.79,0,0,3.15-.7,1.33,2.19,0,0,3.15-.02.9,2.29,0,0,2.02.76.24,2.71,0,0,2.84,1.19-.14,2.78,0,0,2.16,3.13-1.6,3.17,0,0,1.92,4-2.63,3.02,0,0-.2,4.38-3.6,1.99,0,0-2.43,5.17-5.1.67,0,0-4.93,3.72-4.61-1.69,0,0-5.06,3.53-3.54-2.99,0,0-5.64.32-2-5.09,0,0-5.65-3.65.7-5.96,0,0-5.03-5.63,2.55-4.23,0,0-.54-3.29,2.23-1.75,0,0,.1-3.47,2.58-1.15,0,0,.93-2.51,2.17-.45,0,0,2.54-4.57,4.1.28Z", style: {
  fill: "#efe47b",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".64px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M204.82,67.08s3.76,2.02,5.49-2.65c0,0-1.14,4.73,2.43,3.11,0,0-3.64,3.07.44,2.77,0,0-2.84,1.23.08,2.39,0,0-3.37-.22-1.41,2.75,0,0-2.29-2.02-3.13.9,0,0-.44-3-2.98-1.36,0,0,1.93-2.62-.72-2.74,0,0,1.9-1.27-2.57-2.08,0,0,5.81-.03,2.36-3.1Z", style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".41px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 210.64, y1: 76.84, x2: 211.91, y2: 81.55, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".41px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 213.89, y1: 75.02, x2: 215.87, y2: 76.12, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".41px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 213.36, y1: 69.1, x2: 218.35, y2: 66.33, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".41px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 211.56, y1: 66.03, x2: 213.53, y2: 63.59, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".41px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 205.64, y1: 61.92, x2: 206.83, y2: 65.66, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".41px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 200.03, y1: 65.93, x2: 203.29, y2: 67.68, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".41px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 199.76, y1: 74.52, x2: 202.73, y2: 73.22, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".41px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 205.62, y1: 82.12, x2: 207.37, y2: 76.48, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".41px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 214.41, y1: 71.23, x2: 220.26, y2: 70.7, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".41px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M255.55,56.37s2.94-2.51,3.4,1.34c0,0,3.33-1.88,2.33,1.61,0,0,3.58-1.94,2.08,2.18,0,0,3.84-.85,1.62,2.66,0,0,3.84-.02,1.09,2.79,0,0,2.46.92.3,3.3,0,0,3.46,1.45-.17,3.39,0,0,2.63,3.82-1.95,3.86,0,0,2.34,4.87-3.2,3.68,0,0-.24,5.34-4.38,2.42,0,0-2.96,6.3-6.21.81,0,0-6,4.53-5.61-2.05,0,0-6.17,4.3-4.31-3.64,0,0-6.87.39-2.44-6.2,0,0-6.88-4.45.85-7.26,0,0-6.13-6.85,3.1-5.16,0,0-.66-4,2.71-2.13,0,0,.12-4.22,3.14-1.41,0,0,1.14-3.05,2.65-.55,0,0,3.09-5.57,5,.34Z", style: {
  fill: "#efe47b",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".78px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M245.99,64.71s4.58,2.46,6.69-3.22c0,0-1.39,5.77,2.96,3.79,0,0-4.44,3.74.54,3.37,0,0-3.46,1.5.1,2.91,0,0-4.11-.26-1.72,3.35,0,0-2.79-2.46-3.81,1.1,0,0-.53-3.66-3.63-1.66,0,0,2.35-3.19-.88-3.33,0,0,2.31-1.54-3.13-2.54,0,0,7.07-.04,2.88-3.77Z", style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 253.07, y1: 76.59, x2: 254.63, y2: 82.34, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 257.03, y1: 74.38, x2: 259.45, y2: 75.73, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 256.38, y1: 67.17, x2: 262.47, y2: 63.8, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 254.19, y1: 63.43, x2: 256.6, y2: 60.47, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 246.99, y1: 58.43, x2: 248.44, y2: 62.98, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 240.15, y1: 63.31, x2: 244.13, y2: 65.44, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 239.83, y1: 73.77, x2: 243.45, y2: 72.19, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 246.97, y1: 83.03, x2: 249.1, y2: 76.16, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".5px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 257.67, y1: 69.76, x2: 264.8, y2: 69.12, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".5px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M218.39,118.87s2.66-2.27,3.08,1.21c0,0,3.02-1.7,2.11,1.46,0,0,3.24-1.75,1.88,1.97,0,0,3.47-.77,1.46,2.41,0,0,3.47-.02.99,2.52,0,0,2.23.83.27,2.99,0,0,3.13,1.31-.16,3.07,0,0,2.38,3.45-1.76,3.5,0,0,2.12,4.4-2.9,3.33,0,0-.22,4.83-3.97,2.19,0,0-2.68,5.7-5.62.73,0,0-5.43,4.1-5.08-1.86,0,0-5.58,3.89-3.9-3.29,0,0-6.21.35-2.2-5.61,0,0-6.23-4.03.77-6.57,0,0-5.54-6.2,2.81-4.66,0,0-.59-3.62,2.46-1.93,0,0,.11-3.82,2.84-1.27,0,0,1.03-2.76,2.39-.5,0,0,2.8-5.04,4.52.31Z", style: {
  fill: "#efe47b",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".71px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M209.75,126.42s4.14,2.23,6.05-2.91c0,0-1.26,5.22,2.68,3.43,0,0-4.02,3.39.49,3.05,0,0-3.13,1.36.09,2.63,0,0-3.72-.24-1.56,3.03,0,0-2.52-2.22-3.45,1,0,0-.48-3.31-3.28-1.5,0,0,2.13-2.89-.8-3.02,0,0,2.09-1.39-2.83-2.3,0,0,6.4-.04,2.6-3.41Z", style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".45px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 216.15, y1: 137.17, x2: 217.55, y2: 142.37, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".45px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 219.73, y1: 135.17, x2: 221.92, y2: 136.39, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".45px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 219.15, y1: 128.64, x2: 224.65, y2: 125.6, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".45px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 217.16, y1: 125.26, x2: 219.34, y2: 122.58, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".45px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 210.65, y1: 120.74, x2: 211.96, y2: 124.86, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".45px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 204.46, y1: 125.16, x2: 208.06, y2: 127.09, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".45px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 204.17, y1: 134.62, x2: 207.44, y2: 133.18, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".45px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 210.63, y1: 142.99, x2: 212.55, y2: 136.78, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".45px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 220.31, y1: 130.99, x2: 226.76, y2: 130.42, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".45px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M132.52,99.78s-1.52-14.3-7.97-29.91c-6.45-15.61-16.36-27.98-14.56-40.23,0,0,.67-4.92,3.71-1.19,0,0,16.23,14.85,21.56,29.51,5.32,14.66,8.16,36.88,7.17,42.73-.99,5.84-3.51,27.94-9.91-.91Z", style: {
  fill: "#849324",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".51px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M138.36,114.28s.9-42.03-13.38-65.34c-5.27-8.6-11.96-14.82-13.38-21.9", style: {
  fill: "none",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".51px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M151.91,122.59s3.92-.52,2.17,3.03c0,0,3.9.24,1.11,2.67,0,0,4.14.33.57,3.02,0,0,3.76,1.4-.09,3.18,0,0,3.3,2.11-.61,3,0,0,1.6,2.15-1.58,2.99,0,0,2.16,3.16-2.03,2.81,0,0,.13,4.73-3.81,2.23,0,0-.69,5.47-4.79,1.37,0,0-3.17,4.44-5.1-.36,0,0-6.03,3.75-5.77-2.75,0,0-7.65.55-3.67-4.87,0,0-7.67.26-1.67-5.51,0,0-6.1-3.48,1.35-6.66,0,0-3.43-7.63,4.76-5.75,0,0-1.45-9.27,5.52-2.69,0,0,1.66-3.79,3.51-.32,0,0,2.45-3.55,3.47.54,0,0,2.67-1.98,2.57,1,0,0,5.74-3.06,4.09,3.07Z", style: {
  fill: "#efe47b",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".8px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M139.1,124.43s2.55,4.65,7.52.95c0,0-4.39,4.17.43,4.89,0,0-5.88.74-1.4,3.19,0,0-3.8-.64-1.53,2.55,0,0-3.37-2.51-3.34,1.92,0,0-1.03-3.65-3.88-1.17,0,0,1.57-3.43-2.19-3.43,0,0,3.79-1.43,1.09-3.34,0,0,2.83-.04-1.27-3.91,0,0,6.08,3.89,4.56-1.63Z", style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".51px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M240.53,106.52s1.49,3.72-2.44,2.9c0,0,.74,3.9-2.35,1.76,0,0,.72,4.15-2.83,1.32,0,0-.43,4.05-3.15.71,0,0-1.25,3.77-3.1.15,0,0-1.72,2.11-3.34-.8,0,0-2.57,2.91-3.27-1.29,0,0-4.62,1.32-3.15-3.19,0,0-5.55.69-2.55-4.36,0,0-5.16-2-.92-5.1,0,0-5.2-4.99,1.26-6.36,0,0-2.46-7.39,3.87-4.83,0,0-2.18-7.48,5-3.03,0,0,1.9-6.87,6.89-.34,0,0,6.65-5.28,6.84,3.24,0,0,8.75-3.75,4.03,4.75,0,0,4.15.68,1.19,3.37,0,0,4.1,1.52.34,3.55,0,0,2.62,2.13-.34,2.78,0,0,4.44,4.88-1.99,4.79Z", style: {
  fill: "#efe47b",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".81px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M235.51,94.38s-3.93,3.68.95,7.63c0,0-5.2-3.27-4.7,1.65,0,0-2.2-5.6-3.48-.58,0,0-.32-3.89-2.89-.86,0,0,1.62-3.95-2.72-2.8,0,0,3.33-1.92.18-4.11,0,0,3.77.69,2.83-3.01,0,0,2.35,3.36,3.56.24,0,0,.75,2.78,3.53-2.23,0,0-2.3,6.95,2.75,4.07Z", style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".52px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 221.5, y1: 97.39, x2: 215.35, y2: 97.02, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".52px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 222.36, y1: 102.01, x2: 220.24, y2: 103.94, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".52px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 229.66, y1: 103.77, x2: 230.95, y2: 110.85, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".52px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 234.05, y1: 102.85, x2: 236.16, y2: 106.2, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".52px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 241.34, y1: 97.44, x2: 236.4, y2: 97.35, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".52px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 238.81, y1: 89.11, x2: 235.4, y2: 92.31, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".52px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 228.66, y1: 85.33, x2: 229.01, y2: 89.41, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".52px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 217.21, y1: 89.27, x2: 223.25, y2: 93.64, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".52px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 226.68, y1: 104.17, x2: 224.95, y2: 111.38, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".52px"
} })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M187.21,124.74s-.91,3.9-3.66.97c0,0-1.62,3.62-2.94.09,0,0-1.8,3.81-3.07-.54,0,0-2.68,3.07-2.98-1.23,0,0-3.18,2.37-2.62-1.66,0,0-2.62.74-2.27-2.57,0,0-3.77.91-1.94-2.93,0,0-4.53-1.57-.75-4.42,0,0-4.94-2.62.41-5.04,0,0-3.07-4.6,2.17-4.71,0,0-1.4-7.07,4.68-4.49,0,0,2.23-7.46,5.94-1.73,0,0,2.51-7.37,5.83.39,0,0,5.49-4.54,5.84,3.67,0,0,8.47-.51,3.75,6.58,0,0,9.32,1.95.58,6.21,0,0,3.01,2.94-.96,3.44,0,0,2.49,3.6-1.76,3.1,0,0,.92,3.25-1.87,2.08,0,0,.84,6.54-4.38,2.78Z", style: {
  fill: "#efe47b",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: ".81px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M190.06,111.91s-5.33.76-3.6,6.79c0,0-2.38-5.66-4.8-1.34,0,0,1.4-5.85-2.52-2.48,0,0,1.97-3.37-1.87-2.36,0,0,3.59-2.3-.62-3.85,0,0,3.83.34,2.51-3.26,0,0,2.69,2.72,4.05-.85,0,0,0,4.11,2.78,2.24,0,0-.98,2.7,4.17.2,0,0-5.88,4.37-.09,4.91Z", style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".52px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 176.86, y1: 106.34, x2: 172.04, y2: 102.5, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".52px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 174.92, y1: 110.62, x2: 172.07, y2: 110.98, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".52px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 179.89, y1: 116.24, x2: 176.88, y2: 122.78, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".52px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 184.01, y1: 118.01, x2: 183.82, y2: 121.96, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".52px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 193.08, y1: 117.76, x2: 189.08, y2: 114.85, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".52px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 195.79, y1: 109.49, x2: 191.16, y2: 110.16, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".52px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 189.64, y1: 100.57, x2: 187.6, y2: 104.11, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".52px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 178, y1: 97.23, x2: 180.44, y2: 104.27, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".52px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 177.22, y1: 114.86, x2: 171.67, y2: 119.77, style: {
  fill: "#d3c55f",
  stroke: "#a89629",
  strokeMiterlimit: 10,
  strokeWidth: ".52px"
} }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M8.19,237.91c13.33-7.42,149.98-137.81,287.82,7.52l-43.73,1.47-2.53,46.54s-85.43-102.28-202.38,0l9.36-49.11-48.54-6.41Z", style: {
  fill: "#f9cd5f",
  stroke: "#000",
  strokeMiterlimit: 10,
  strokeWidth: "3.04px"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M117,238.61l-.66-2.27c3.71-1.68,3.52-2.35,1.89-4.75-1.15-1.67-2.94-4.15-4.57-6.34l-11.99,3.48c-.12,2.1-.31,4.69-.37,6.77-.17,4.25,1.45,4.31,6.43,3.33l.66,2.27-15.18,4.41-.66-2.27c3.73-1.62,4.55-2.45,5.14-8.22l3.56-35.49,2.79-1.41c7.04,9.55,14.39,19.28,21.37,28.38,3.93,5.06,4.61,5.33,8.75,4.66l.66,2.27-17.82,5.17ZM103.06,210.81c-.5,4.94-1.02,9.56-1.26,14.49l9.77-2.84-8.51-11.66Z", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M175.24,196.44c-3.97.33-5.04,1.49-5.31,3.72-.21,1.86-.36,3.96-.41,8.3l-.09,8.12c-.06,5.33-.99,10.72-4.57,14.39-2.89,2.94-7.3,4.19-11.71,4.14-4.09-.05-8.17-.96-11.06-3.35-3.56-2.77-5.26-6.57-5.18-13.52l.18-15.68c.07-5.7-.49-6.14-5.19-6.63l.03-2.29,19.16.22-.03,2.29c-4.66.38-5.22.81-5.28,6.51l-.19,16.49c-.08,6.94,3.28,11.57,9.91,11.65,7.38.08,10.66-5.33,10.77-14.63l.09-7.75c.05-4.28-.11-6.51-.21-8.37-.21-2.85-1.94-3.49-6.28-3.79l.03-2.29,15.38.18-.03,2.29Z", style: {
  fill: "#000",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M206.4,212.33c-.11-4.29-.55-9.27-6.21-10.89-3.57-1.02-6.63.84-7.61,4.28-.97,3.38.73,6.07,5.37,10.32,5.65,5.14,8.92,9.54,7.19,15.63-2.07,7.26-9.61,10.83-18.41,8.32-3.94-1.12-7.15-3.17-9.79-4.86.03-1.52.81-8.22,1.21-11.23l2.56.13c.12,4.23,1.66,11.92,7.69,13.64,3.75,1.07,6.7-.62,7.82-4.56,1.02-3.57-.6-6.29-4.98-10.54-5.42-5.27-8.72-9.54-6.9-15.94,1.84-6.46,8.35-10.13,17.58-7.5,4.25,1.21,7.18,3.05,8.48,3.62-.4,2.35-.89,5.67-1.5,9.69l-2.5-.11Z", style: {
  fill: "#000",
  strokeWidth: 0
} }))));
const ForwardRef = forwardRef(SvgAus);
export default ForwardRef;
